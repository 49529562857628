// -----------------------------------------------------------------------------
// This file contains all application-wide Sass functions.
// -----------------------------------------------------------------------------

/// Native `url(..)` function wrapper
/// @param {String} $base - base URL for the asset
/// @param {String} $type - asset type folder (e.g. `fonts/`)
/// @param {String} $path - asset path
/// @return {Url}
@function asset($base, $type, $path) {
  @return url($base + $type + $path);
}

/// Returns URL to an image based on its path
/// @param {String} $path - image path
/// @param {String} $base [$base-url] - base URL
/// @return {Url}
/// @require $base-url
@function image($path, $base: $base-url) {
  @return asset($base, 'images/', $path);
}

/// Returns URL to a font based on its path
/// @param {String} $path - font path
/// @param {String} $base [$base-url] - base URL
/// @return {Url}
/// @require $base-url
@function font($path, $base: $base-url) {
  @return asset($base, 'fonts/', $path);
}




//margin utility class generate function

$spaceamounts: (0, 10, 20, 30, 50);
$sides: (left, right, top, bottom, all);

@each $space in $spaceamounts {
  @each $side in $sides {

    @if $side=='all' {
      .m-#{$space} {
        margin: #{$space}px;
      }

      .p-#{$space} {
        padding: #{$space}px;
      }
    }


    @else {
      .m-#{str-slice($side, 0, 1)}#{$space} {
        margin-#{$side}: #{$space}px;
      }

      .p-#{str-slice($side, 0, 1)}#{$space} {
        padding-#{$side}: #{$space}px;
      }
    }
  }
}
