input,
textarea {
    @extend %w-100;
    @include add-prefix(transition, $transition);
    border-radius: 8px;
    color: $text-color;
    padding: 1rem 2rem;
    z-index: 1;

    &.style1 {
        border-radius: 0;
        border: none;
        background-color: $brand-color;
        color: $wh-color;

        &::placeholder {
            font-weight: 400;
            color: $wh-color;
            color: $text-color;
        }

    }

    &::placeholder {
        font-weight: 400;
        color: rgba($text-color-light, $alpha: .3);

    }

    &:focus-within {
        outline: none;
        border-color: none;
        box-shadow: none;
    }
}


.form-control {
    background-color: $wh-color;
    border-color: $form-border;
    padding: 13px 20px;
    border-radius: 8px;
    color: $title-color;

    @include breakpoint(lg) {
        padding: 16px 20px;
    }

    &:focus {
        box-shadow: none;
        border-color: $brand-color;
        background-color: $wh-color;
        color: $title-color;
    }
}

.form-check-input {
    background-color: $wh-color;
}






.form-label {
    @extend %mb-5,
    %text-semibold;
    @include font-size(18px);
    font-family: $text-font;
    color: $title-color;
}

.input-group {
    @include add-prefix(transition, $transition);
    border: 1px solid rgba($text-color, $alpha: .3);
    border-radius: .5rem;

    &:focus-within {
        border-color: $text-color;
    }

    .valid-tooltip,
    .invalid-tooltip {
        background-color: transparent;
        top: 90%;
    }

}


.showhide-pass {
    position: relative;
}