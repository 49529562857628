.roadmap {
    @extend %of-hidden,
    %p-rel;

    &--style1 {
        .roadmap {
            &__wrapper {
                @extend %p-rel,
                %of-hidden;
                z-index: 2;

                &:after {
                    @include breakpoint(md) {
                        position: absolute;
                        content: "";
                        height: 50%;
                        width: 10px;
                        background-color: $brand-color;
                        border-radius: 8px;
                        top: -1px;
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        -o-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }
                }

                &:before {
                    @include breakpoint(md) {
                        position: absolute;
                        content: "";
                        height: 100%;
                        width: 10px;
                        background-color: $border-color-4;
                        border-radius: 8px;
                        top: 0;
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        -o-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }
                }
            }

            &__item {
                border-radius: 16px;
                background: linear-gradient(91deg, var(--bg-grad-color-3) 0%, var(--bg-grad-color-trans-3) 100%);
                max-inline-size: 500px;

                &--style2 {
                    background: linear-gradient(91deg, var(--bg-grad-color-trans-3) 0%, var(--bg-grad-color-3) 100%);
                }

                &.aos-animate {
                    @include add-prefix(transform, unset);
                }

                &-inner {
                    @extend %p-rel;
                }

                &-header {
                    @extend %d-flex,
                    %justify-between;

                    h3 {
                        @extend %text-capitalize;
                        flex: 1;
                    }

                    span {
                        @extend %text-semibold;
                        @include font-size(32px);
                        font-family: $title-font;
                        color: $brand-color;
                        margin-block-start: -5px;
                    }
                }


                &.text-end {
                    .roadmap__item {
                        &-inner {
                            &:after {
                                @include breakpoint(md) {
                                    top: 30px;
                                    right: -68px;
                                    left: auto;
                                }
                            }
                        }
                    }
                }

                &-content {
                    padding: 20px;

                    @include breakpoint(md) {
                        padding: 40px;
                    }


                    p {
                        @extend %mb-0;
                    }
                }
            }

            // shape
            &__shape {

                &-item {
                    @extend %p-abs;

                    &--1 {
                        content: "";
                        height: 50%;
                        width: 910px;
                        // border: 2px solid $bg-white;
                        border: 2px solid var(--bg-shape-color-2);
                        padding: 40px;
                        border-radius: 100px 213px 100px 100px;

                        border-left: transparent;
                        border-bottom: transparent;

                        top: 0%;
                        left: -3%;
                        transform: rotate(40deg);
                        display: none;

                        @include breakpoint(md) {
                            display: block;
                            width: 920px;
                            top: -16%;
                            left: -117%;
                        }

                        @include breakpoint(lg) {
                            top: -16%;
                            left: -80%;
                        }

                        @include breakpoint(xl) {
                            left: -58%;
                        }

                        @include breakpoint(xxl) {
                            left: -30%;
                        }


                        span {
                            position: absolute;
                            content: "";
                            height: 96%;
                            width: 880px;
                            background: linear-gradient(109deg, var(--bg-shape-color-trans-2) 0%, var(--bg-shape-color-2) 100%);
                            border-radius: 100px 213px 100px 100px;

                            @include breakpoint(md) {
                                top: 2%;
                                left: 2%;
                            }
                        }


                    }

                    &--2 {
                        width: 40px;
                        top: 1%;
                        left: 6%;
                        opacity: 0.25;
                        // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                        @include breakpoint(sm) {
                            top: 3%;
                            left: 7%;
                        }

                        @include breakpoint(md) {
                            width: auto;
                            top: 2%;
                            left: 1%;
                        }

                        @include breakpoint(lg) {
                            left: 4%;
                            top: 4%;
                        }

                        @include breakpoint(xl) {
                            top: 5%;
                            left: 5%;
                        }

                        @include breakpoint(xxl) {
                            top: 8%;
                            left: 5%;
                        }
                    }
                }

            }
        }
    }

    &--style2 {
        .roadmap {
            &__upper {
                &-inner {
                    @extend %p-rel;
                    @extend %d-flex,
                    %align-i-center;
                    gap: 30px;

                    &::after {
                        @extend %p-abs,
                        %d-none;
                        content: "";
                        width: 100%;
                        left: 0;
                        top: 50%;
                        border: 1px dashed $border-color-7;
                        ;

                        @include breakpoint(md) {
                            display: block;
                        }
                    }
                }

            }

            &__slider {
                counter-reset: roadmap__item;
            }

            &__item {
                @extend %p-rel;

                &-inner {
                    @extend %p-25,
                    %text-center,
                    %p-rel;
                    border-radius: 16px;
                    background: linear-gradient(152deg, var(--bg-color-trans) 0%, var(--bg-color) 100%);
                    // border: 2px solid $service-item-border-color;
                    //  box-shadow: 0 0 10px #0cd66e;

                    @include breakpoint(sm) {
                        padding: 30px;
                    }

                    @include breakpoint(md) {
                        padding: 24px;
                        max-width: 280px;

                        &::before {
                            position: absolute;
                            font-weight: 600;
                            @include add-prefix(transition, $transition);
                            counter-increment: roadmap__item;
                            content: counter(roadmap__item);
                            transform: translateX(-50%);
                            left: 50%;
                            width: 30px;
                            height: 30px;
                            line-height: 28px;
                            border-radius: 99px;
                            background-color: $extra-bg-color;
                            border: 1px solid $secondary-color;
                            bottom: -80px;
                            z-index: 1;
                            @include font-size(20px);
                            color: $secondary-color;
                        }

                        &::after {
                            position: absolute;
                            @include add-prefix(transition, $transition);
                            content: "";
                            transform: translateX(-50%);
                            left: 50%;
                            width: 0;
                            border: 1px dashed $border-color-7;
                            height: 50px;
                            bottom: -50px;
                        }


                        &--vertical-line-top {
                            margin-block-start: 310px;
                        }

                        &--vertical-line-bottom {

                            margin-block-end: 64px;
                        }
                    }



                }

                &-content {
                    h5 {
                        @include add-prefix(transition, $transition);
                        @extend %mb-0;
                    }
                }

                &-date {
                    @include font-size(14px);
                    padding: 4px 12px;
                    border: 1px solid $border-color;
                    border-radius: 99px;
                    color: $title-color;
                }

                &--reverse {
                    .roadmap__item {
                        &-inner {
                            &::after {
                                top: -50px;
                            }

                            &::before {
                                top: -80px;
                            }
                        }
                    }
                }

                &.active,
                &:hover {
                    .roadmap__item {
                        &-inner {
                            &::after {
                                border: 1px dashed $secondary-color;
                            }

                            &::before {
                                color: $wh-color;
                                background-color: $secondary-color;
                                border-color: $secondary-color;
                            }
                        }

                        &-content {
                            h5 {
                                color: $secondary-color;
                            }
                        }
                    }
                }
            }
        }

    }

}