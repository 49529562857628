.floating-content {
    &__item {
        @extend %py-15,
        %px-20;
        background-color: $floating-content-bg-color;
        border: 1px solid $floating-content-border-color;
        box-shadow: -40px 40px 60px 0px rgba(5, 30, 54, 0.05);
        border-radius: 16px;


        @include breakpoint(md) {
            padding: 24px 32px;
        }

        h3 {
            @extend %mb-5,
            %text-bold;
            font-family: $text-font;
            line-height: 32px;
            color: $secondary-color;

            &.style2 {
                color: $thm-brand-color;
            }
        }

        p {
            @extend %mb-0,
            %text-regular;
            @include font-size(20px);
            color: $title-color;

            &.style2 {
                @extend %text-bold;
                @include font-size(18px);
            }
        }


        &--style2 {
            @extend %d-flex,
            %align-i-center;
            gap: 15px;

            padding: 16px 0px 16px 36px;

            @include breakpoint(sm) {
                padding: 16px 0px 16px 36px;
            }

            @include breakpoint(md) {
                padding: 16px 0px 16px 36px;
            }

            @include breakpoint(xl) {
                padding: 16px 10px 16px 48px;
            }




            border-radius: 8px 40px 32px 80px;
            border: 2px solid $border-color-2;
            background-color: $floating-content-bg-color-2;

            backdrop-filter: blur(24px);

            border-radius: 90px;
            padding: 0px !important;

            img {
                width: 60px;

                @include breakpoint(sm) {
                    width: 70px;
                }

                @include breakpoint(md) {
                    width: auto;
                }
            }

            p {
                @extend %text-capitalize,
                %text-bold,
                %text-left;
                max-inline-size: 18ch;
            }

        }

        &--style3 {
            padding: 16px;


            @include breakpoint(md) {
                padding: 16px 20px;
            }

            @include breakpoint(lg) {
                padding: 25px 30px;
            }

            border-radius: 32px 60px 8px 40px;
            border: 2px solid $border-color-2;
            background-color: $floating-content-bg-color-2;
            backdrop-filter: blur(24px);

        }

        &--style4 {
            @extend %d-flex,
            %align-i-center,
            %text-left;
            gap: 15px;
            padding: 20px;
            border-radius: 100px 9.126px 9.126px 100px;
            background: $wh-color;
            box-shadow: -24px 24px 73.00640869140625px 0px rgba(0, 208, 148, 0.16);

            p {
                @extend %text-semibold;
                @include font-size(18px);
                color: $title-color;
            }

            span {
                color: $title-color;
            }
        }

        &--style5 {
            min-width: 260px;
            border-radius: 16px;
            // background-color: rgba(255, 255, 255, 0.95);
            background-color: $floating-content-bg-color;
            box-shadow: -16px 32px 80px 0px rgba(61, 51, 0, 0.12);

            h3 {
                color: $title-color;
            }

            p {
                color: $text-color;
            }

            @include breakpoint(md) {
                padding: 20px;
            }

            @include breakpoint(lg) {
                padding: 24px 32px;
            }

        }

        &--style6 {
            min-width: 260px;
            border-radius: 16px;
            text-align: left;
            // background-color: rgba(255, 255, 255, 0.95);
            background-color: $floating-content-bg-color;
            box-shadow: -16px 32px 80px 0px rgba(62, 108, 228, 0.12);


            h3 {
                color: $title-color;
                font-family: $title-font;
                margin-block-end: -5px;
            }

            p {
                color: $text-color;
                @include font-size(16px);
            }

            @include breakpoint(md) {
                padding: 20px;
            }

            @include breakpoint(lg) {
                padding: 24px 32px;
            }

        }
    }

    &__top {
        &-left {

            @extend %p-abs;
            left: 10px;
            top: 15px;


            @include breakpoint(md) {
                left: 30px;
                top: 90px;
            }

            @include breakpoint(lg) {
                left: 0px;
                top: 10px;
            }

            @include breakpoint(xl) {
                left: 5px;
                top: 60px;
            }

            &--style2 {
                @extend %text-left;
                left: 0px;
                top: -15px;

                @include breakpoint(sm) {
                    left: 0px;
                    top: 30px;
                }

                @include breakpoint(md) {
                    left: -6px;
                    top: 0px;
                }

                @include breakpoint(lg) {
                    left: 0px;
                    top: 10px;
                }

                @include breakpoint(xl) {
                    left: 5px;
                    top: 60px;
                }

            }


        }

        &-right {
            @extend %p-abs;
            right: 90px;
            top: -35px;
            z-index: 2;

            @include breakpoint(sm) {
                @include breakpoint(max-md) {
                    right: -20px;
                    top: 5px;
                }
            }

            @include breakpoint(md) {
                @include breakpoint(max-lg) {
                    right: 60px;
                    top: -50px;
                }
            }

            @include breakpoint(lg) {
                right: -10px;
                top: -80px;
            }

            &--style2 {

                @include breakpoint(md) {
                    right: 10px;
                    top: -40px;

                    img {
                        max-width: 30px;
                        max-height: 30px;
                    }
                }

                @include breakpoint(lg) {
                    right: 80px;
                    top: -55px;

                    img {
                        max-width: 30px;
                        max-height: 30px;
                    }
                }

                @include breakpoint(xl) {
                    right: 115px;
                    top: -55px;

                    img {
                        width: auto;
                    }
                }

                @include breakpoint(xxl) {
                    right: 120px;
                    top: -55px;
                }

                color: transparent;
                margin: 5px 20px 5px 20px;
            }

            &--style3 {

                right: 10px;
                top: 20px;

                @include breakpoint(sm) {
                    @include breakpoint(max-md) {
                        right: 20px;
                        top: 20px;
                    }
                }

                @include breakpoint(md) {
                    @include breakpoint(max-lg) {
                        right: 15px;
                        top: -60px;
                    }
                }

                @include breakpoint(lg) {
                    right: 10px;
                    top: 20px;
                }

                @include breakpoint(xl) {
                    right: 70px;
                    top: 20px;
                }
            }
        }
    }

    &__left {
        &-top {
            @extend %p-abs;
            left: -10px;
            top: -47px;

            &--style2 {
                left: -10px;
                top: -40px;
            }

            @include breakpoint(sm) {
                left: -27px;
                top: -44px;
            }

            @include breakpoint(md) {
                left: -27px;
                top: -125px;
            }

            @include breakpoint(lg) {
                left: -45px;
                top: -80px;
            }
        }
    }

    &__bottom {
        &-left {
            @extend %p-abs;
            left: 0px;
            bottom: 0px;


            &--style2 {
                @include breakpoint(sm) {
                    left: -10px;
                    bottom: 10px;
                }

                @include breakpoint(md) {
                    left: -14px;
                    bottom: -27px;
                }

                @include breakpoint(lg) {
                    left: -33px;
                    bottom: 0px;
                }

                @include breakpoint(xl) {
                    left: 30px;
                    bottom: -5px;
                }
            }

            margin-bottom: -80px;
            height: 120px;

            &--style3 {
                left: 0px;
                bottom: 20px;

                @include breakpoint(sm) {
                    left: 0px;
                    bottom: 10px;
                }

                @include breakpoint(md) {
                    left: -23px;
                    bottom: 25px;
                }

                @include breakpoint(lg) {
                    left: -10px;
                    bottom: 31px;
                }

                @include breakpoint(xl) {
                    left: 10px;
                    bottom: 31px;
                }

                @include breakpoint(xxl) {
                    left: -10px;
                    bottom: 31px;
                }

                @include breakpoint(xxxl) {
                    left: -10px;
                    bottom: 31px;
                }
            }
        }

        &-right {
            @extend %p-abs;
            right: 10px;
            bottom: 15px;

            &--style2 {
                right: 70px;
                bottom: -30px;

                @include breakpoint(md) {
                    right: 70px;
                    bottom: -30px;
                }
            }

            @include breakpoint(sm) {
                right: 10px;
                bottom: 60px;
            }

            @include breakpoint(md) {
                right: 70px;
                bottom: 75px;
            }

            @include breakpoint(lg) {
                right: 45px;
                bottom: 2px;
            }

            @include breakpoint(xl) {
                right: 23px;
                bottom: 40px;
            }
        }
    }




}