.lightdark-switch {
    .switch-btn {
        @extend %p-fixed,
        %p-5,
        %of-hidden,
        %d-grid,
        %place-i-center;
        @include add-prefix(transition, $transition);
        @include box(40px);
        bottom: 5%;
        left: 5%;
        border-radius: 8px;
        z-index: 9999;
        cursor: pointer;
        background-color: $brand-color;
        // background-color: red;
        box-shadow: 0px 0 15px 0px rgb(104, 225, 166, 0.7);
        border: 1px solid $brand-color;

        &:hover {
            // transform: scale(1.05);
            border: 1px solid $brand-color;
            box-shadow: 0px 0 15px 2px rgb(104, 225, 166, 0.6);
        }



        @include breakpoint(sm) {
            @include box(40px);
            padding: 10px;
            bottom: 7%;
            left: 5%;
        }

        @include breakpoint(md) {
            bottom: 7%;
            left: 5%;
        }

        @include breakpoint(lg) {
            bottom: auto;
            left: auto;
            top: 130px;
            right: 60px;
        }

        @include breakpoint(xxl) {
            top: 80px;
            right: 90px;
        }


        img {
            max-height: 16px;

            @include breakpoint(sm) {
                max-height: 20px;
            }
        }


        &.dark-switcher {
            background-color: $bg-white;
            border-color: $bg-white;
        }



        &--style2 {
            
            background-color: $tertiary-color4;
            box-shadow: 0px 0 15px 0px rgb(96, 132, 224, 0.7);
            border: 1px solid $tertiary-color4;

            &:hover {
                // transform: scale(1.05);
                border: 1px solid $tertiary-color4;
                box-shadow: 0px 0 15px 2px rgb(96, 132, 224, 0.6);
            }

        }
    }


}