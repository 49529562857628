.faq {
    @extend %p-rel;

    &__thumb {
        &--style1 {
            @extend %p-rel;
            @extend %text-center;

            &::after {
                position: absolute;
                content: "";
                top: 50%;
                left: 50%;
                height: 200px;
                width: 200px;
                backdrop-filter: blur(10px);
                filter: blur(100px);
                background-color: $faq-img-bg-shadow;
                // background-color: $brand-color;
                transform: translate(-50%, -50%);
                z-index: -1;
            }
        }

        // &--style2{

        // }
    }

    &__shape {

        &-item {
            @extend %p-abs;

            &--1 {
                @extend %p-abs;
                width: 40px;
                top: 1%;
                left: 6%;
                opacity: 0.25;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 3%;
                    left: 7%;
                }

                @include breakpoint(md) {
                    width: 70px;
                    top: 6%;
                    left: 2%;
                }

                @include breakpoint(lg) {
                    width: 70px;
                    left: 5%;
                    top: 5%;
                }

                @include breakpoint(xl) {
                    top: 6%;
                    left: 6%;
                }

                @include breakpoint(xxl) {
                    top: 11%;
                    left: 17%;
                }
            }

            // home 3
            &--2 {
                @extend %p-abs;
                width: 40px;
                top: 1%;
                left: 6%;
                opacity: 0.25;
                // @include animation(rotate 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 3%;
                    left: 7%;
                }

                @include breakpoint(md) {
                    width: 70px;
                    top: 6%;
                    left: 2%;
                }

                @include breakpoint(lg) {
                    width: auto;
                    left: 5%;
                    top: 5%;
                }

                @include breakpoint(xl) {
                    top: 6%;
                    left: 6%;
                }

                @include breakpoint(xxl) {
                    top: 11%;
                    left: 17%;
                }
            }

            &--3 {
                @extend %p-abs;
                width: 40px;
                top: 1%;
                right: 10%;
                opacity: 0.25;
                // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 3%;
                    right: 7%;
                }

                @include breakpoint(md) {
                    width: 70px;
                    top: 6%;
                    right: 4%;
                }

                @include breakpoint(lg) {
                    width: auto;
                    right: 5%;
                    top: 5%;
                }

                @include breakpoint(xl) {
                    top: 6%;
                    right: 6%;
                }

                @include breakpoint(xxl) {
                    top: 11%;
                    right: 11%;
                }
                @include breakpoint(xxxl) {
                    top: 11%;
                    right: 17%;
                }
            }
        }
    }

}