.story {
    @extend %of-hidden, %p-rel;

    &__wrapper{
        @extend %p-rel;
        z-index: 2;
    }

    &__thumb {
        &-inner {
            @extend %p-rel;
            object-fit: cover;
            transition: $transition;

            img {
                border-radius: 24px;
            }
        }

        &-playbtn {
            @extend %p-abs,
            %pos-center;

            a {

                i,
                svg {
                    color: $bg-white;
                    @include font-size(56px);

                    @include breakpoint(md) {
                        @include font-size(60px);
                    }

                    @include breakpoint(lg) {
                        @include font-size(62px);
                    }

                    @include add-prefix(transition, $transition);
                }
            }

            &:hover {
                a {

                    i,
                    svg {
                        color: $brand-color;
                    }

                }
            }

        }

    }

        // shape
    &__shape {

        &-item {
            @extend %p-abs;

            // home 2
            &--1 {
                content: "";
                height: 100%;
                border: 2px solid var(--bg-shape-color-2);
                padding: 40px;
                border-radius: 100px 213px 100px 100px;

                opacity: 0.4;

                transform: rotate(132deg);
                // transform: rotate(-68.867deg);
                display: none;

                @include breakpoint(md) {
                    display: block;
                    width: 1050px;
                    top: -10%;
                    right: -45%;
                }

                @include breakpoint(lg) {
                    width: 1200px;
                    top: -30%;
                    right: -45%;
                }

                @include breakpoint(xl) {
                    width: 1200px;
                    top: 0%;
                    right: -22%;
                }

                @include breakpoint(xxl) {
                    top: 0%;
                   right: -13%;
                }
                @include breakpoint(xxxl) {
                    top: -8%;
                    right: 0%;
                }


                span {
                    position: absolute;
                    content: "";
                    height: 96%;
                    width: 1000px;
                    background: linear-gradient(4deg, var(--bg-shape-color-trans-2) 0%, var(--bg-shape-color-2) 100%);
                    border-radius: 100px 213px 100px 100px;


                    @include breakpoint(md) {
                        top: 2%;
                        right: 2%;
                    }

                    @include breakpoint(xl) {
                        width: 1000px;
                        top: 2%;
                        right: 2%;
                    }
                    @include breakpoint(xxl) {
                        width: 1200px;
                        top: 2%;
                        right: 2%;
                    }
                }


            }
        }


    }
}