.service-details {
    @extend  %of-hidden;
    &__item {
        &-inner {
            @extend %p-30;
            background-color: $wh-color;
            // trk-rgba 
            box-shadow: 0px -16px 48px rgba(6, 35, 63, 0.03), 0px 16px 48px rgba(6, 35, 63, 0.03);
            border-radius: 4px;

            @include breakpoint(md){
                padding: 50px
            }
        }
    }
    &__thumb{
        &-inner{
            img{
                border-radius: 8px;
            }
        }
    }

    &__faq{
        @extend %py-20, %px-20;
        border-radius: 16px;
        background-color: $tertiary-color3;
        // background-color: #F5F7FC;

        @include breakpoint (md){
            padding-inline: 40px;
        }

        button{
            background-color: $tertiary-color;
        }
    }

    &__team{
        @extend %p-rel;
        h5{
            @extend %text-capitalize, %text-medium;
        }
    }


}