.cta {
  @extend %of-hidden;

  &__wrapper {
    @extend %p-rel;
  }

  &__newsletter {
    &-inner {
      @extend %px-30,
      %py-45,
      %p-rel;
      gap: 30px;
      background-color: #121A27;
      box-shadow: 0 0 10px #0cd66e;
      border-radius: 24px;
      width: 98%;
      margin: 0 auto;
      z-index: 2;


      &--style2 {
        background-color: $brand-color;
      }

      @include breakpoint(max-md) {
        text-align: center;
      }

      @include breakpoint(sm) {
        width: 85%;
        // padding: 30px;
      }

      @include breakpoint(md) {
        padding-inline: 30px;
        padding-block: 40px;
        width: 80%;
        display: flex;
        align-items: center;
        gap: 30px;
        padding-inline: 30px;
        padding-block: 40px;
      }

      @include breakpoint(lg) {
        padding: 55px;
      }

      @include breakpoint(xl) {
        padding-inline: 90px;
        padding-block: 55px;
      }

    }
  }

  &__thumb {
    width: 32%;
    @extend %p-rel;

    @include breakpoint(max-md) {
      margin-inline: auto;
      margin-block-end: 20px;
    }

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      height: 50px;
      width: 50px;
      backdrop-filter: blur(10px);
      filter: blur(100px);
      background-color: $cta-img-bg-shadow;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    @include breakpoint(md) {
      &::after {
        height: 100px;
        width: 100px;
      }
    }

    @include breakpoint(lg) {
      &::after {
        height: 150px;
        width: 150px;
      }
    }

    @include breakpoint(xl) {
      &::after {
        height: 200px;
        width: 200px;
      }
    }


    &--style2 {
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        height: 50px;
        width: 50px;
        backdrop-filter: blur(10px);
        filter: blur(100px);
        background-color: $cta-img-bg-shadow;
        transform: translate(-50%, -50%);
        z-index: -1;
        opacity: 0.5;
      }

      @include breakpoint(md) {
        &::after {
          height: 100px;
          width: 100px;
        }
      }

      @include breakpoint(lg) {
        &::after {
          height: 150px;
          width: 150px;
        }
      }

      @include breakpoint(xl) {
        &::after {
          height: 200px;
          width: 200px;
        }
      }
    }


  }

  &__subscribe {
    flex: 1;

    h2 {
      @extend %text-capitalize;
      color: $secondary-color;

      span {
        color: white;
      }

      @include breakpoint(max-md) {
        margin-block-end: 5px;
      }

      @include breakpoint(md) {
        @include breakpoint(max-lg) {
          @include font-size(32px);
        }
      }

    }

    p {
      @extend %mb-30;
      color:  $text-color;

      @include breakpoint(md) {
        margin-block-end: 15px;
      }

      @include breakpoint(lg) {
        margin-bottom: 40px;
      }
    }

  }

  &__shape {

    &-item {
      @extend %p-abs;

      &--1 {
        top: -13%;
        left: -1%;
        width: 40px;
        opacity: 0.25;
        // @include animation(rotate 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

        @include breakpoint(sm) {
          width: 60px;
          top: -15%;
          left: -3%;
        }

        @include breakpoint(md) {
          width: 70px;
          top: -21%;
          left: 0%;
        }

        @include breakpoint(lg) {
          top: -15%;
          left: 3%;
        }

        @include breakpoint(xl) {
          top: -15%;
          left: 3%;
        }

        @include breakpoint(xxl) {
          top: -15%;
          left: 3%;
        }
      }

      &--2 {
        @extend %d-none;
        opacity: 0.25;
        // @include animation(light 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

        @include breakpoint(md) {
          display: block;
          top: -35%;
          left: 10%;
          width: 70%;
        }

        @include breakpoint(lg) {
          width: 70px;
          top: -32%;
          left: 20%;
        }

        @include breakpoint(xl) {
          top: -32%;
          left: 25%;
        }

        @include breakpoint(xxl) {
          top: -32%;
          left: 25%;
        }
      }

      &--3 {
        bottom: -11%;
        right: -3%;
        width: 50px;
        opacity: 0.25;
        // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

        @include breakpoint(sm) {
          width: 70px;
          bottom: -11%;
          right: -3%;
        }

        @include breakpoint(md) {
          width: 70px;
          bottom: -20%;
          right: -3%;
        }

        @include breakpoint(lg) {
          bottom: -20%;
          right: 0%;
        }

        @include breakpoint(xl) {
          bottom: -20%;
          right: 0%;
        }

        @include breakpoint(xxl) {
          bottom: -20%;
          right: 0%;
        }
      }

    }

  }


  &--style2 {
    @extend %p-rel;
    // width: 95%;
    margin: 0 auto;
    margin-bottom: -140px;
    z-index: 3;
    overflow: visible;

    @include breakpoint(sm) {
      // width: 90%;
      margin-bottom: -180px;
    }
  }

}