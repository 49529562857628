.about {
    @extend %p-rel,
    %of-hidden;

    &--style1 {
        padding-block-start: 65px;
        padding-block-end: 80px;

        @include breakpoint(md) {
            padding-block-start: 0px;
            padding-block-end: 80px;
        }

        @include breakpoint(lg) {
            padding-block-start: 75px;
            padding-block-end: 80px;
        }

        @include breakpoint(xl) {
            padding-block-start: 51px;
            padding-block-end: 80px;
        }

        @include breakpoint(xxl) {
            padding-block-start: 51px;
            padding-block-end: 60px;
        }
    }

    &__thumb {
        &-image {
            @extend %p-rel;

            img {
                @extend %p-rel;
                border-radius: 20px;
            }

            &::after {
                position: absolute;
                content: "";
                top: 60%;
                left: 50%;
                height: 200px; 
                width: 200px;
                backdrop-filter: blur(10px);
                filter: blur(70px);
                background-color: $about-img-bg-shadow;
                transform: translate(-50%, -50%);
                z-index: -1;
            }
        }

        &--style2 {
            @extend %p-rel;
            @extend %text-center;

            &::after {
                @extend %p-abs;
                content: "";
                top: 50%;
                left: 50%;
                height: 400px;
                width: 250px;
                backdrop-filter: blur(10px);
                filter: blur(100px);
                background-color: $faq-img-bg-shadow;
                transform: translate(-50%, -50%);
                z-index: -1;
                opacity: 0.5;
            }
        }
    }

    &__content {
        @extend %mt-20;

        @include breakpoint(lg) {
            margin-block-start: 0;
        }

        &:not(:last-child) {
            @extend %mb-30;

            @include breakpoint(xl) {
                margin-bottom: 32px;
            }
        }

        &-inner {
            h2 {
                @extend %text-capitalize;

                span {
                    color: $secondary-color;
                }
            }

            p {
                color: $text-color;
            }

            a {
                @extend %mt-30;

                @include breakpoint(md) {
                    margin-block-start: 40px;
                }
            }
        }




        &--style2 {
            .about {
                &__content {

                    &-details {
                        flex: 1;

                        h5 {
                            @extend %text-capitalize,
                            %mb-0;
                        }
                    }

                    &-inner {
                        @extend %d-flex;
                        gap: 15px;

                        h2 {
                            @extend %text-capitalize;
                            margin-block-end: -10px;

                            span {
                                color: $secondary-color;
                            }
                        }
                    }
                }
            }

        }
    }

    &__icon {
        width: 70px;

        &-inner {
            @extend %text-center,
            %d-grid,
            %place-i-center;
            @include box(70px);
            background-color: $bg-color-3;
            border-radius: 50%;
        }
    }



    &__shape {
        &--style1 {
            .about__shape {
                &-item {
                    @extend %p-abs;
                    width: 40px;
                    top: 5%;
                    right: 6%;
                    opacity: 0.25;
                    // @include animation(rotate 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                    @include breakpoint(sm) {
                        top: 5%;
                        right: 7%;
                    }

                    @include breakpoint(md) {
                        width: 70px;
                        top: 6%;
                        right: 6%;
                    }

                    @include breakpoint(lg) {
                        right: 5%;
                        top: 16%;
                    }

                    @include breakpoint(xl) {
                        top: 16%;
                        right: 6%;
                    }

                    @include breakpoint(xxl) {
                        top: 16%;
                        right: 9%;
                    }
                }
            }
        }

        &--style2 {
            .about__shape {
                &-item {
                    @extend %p-abs;

                    &--1 {
                        width: 80px;
                        top: 52%;
                        right: 71%;
                        opacity: 0.25;
                        // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                        @include breakpoint(sm) {
                            width: 70px;
                            top: 45%;
                            right: 71%;
                        }

                        @include breakpoint(md) {
                            top: 45%;
                            right: 77%;
                        }

                        @include breakpoint(lg) {
                            right: 43%;
                            top: 8%;
                        }

                        @include breakpoint(xl) {
                            top: 3%;
                            right: 40%;
                        }

                        @include breakpoint(xxl) {
                            top: -1%;
                            right: 3%;
                        }

                        @include breakpoint(xxxl) {
                            top: 0%;
                            right: 9%;
                        }
                    }
                }
            }
        }
    }


    &--style3 {
        .about {
            &__content {
                &-icon {
                    @extend %mb-30;

                    img {
                        width: 70px;
                        opacity: 0.25;
                        // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                        @include breakpoint (sm) {
                            width: 73px;
                        }

                        @include breakpoint (md) {
                            width: 70px;
                        }

                        @include breakpoint (lg) {
                            width: 90px;
                        }

                        @include breakpoint (xl) {
                            width: auto;
                        }
                    }
                }

                h2 {
                    @extend %text-capitalize;

                    span{
                        color: $secondary-color;
                    }
                }

                p {
                    color: $text-color;
                }

                a {
                    @extend %mt-30;

                    @include breakpoint(md) {
                        margin-block-start: 40px;
                    }
                }

                ul {
                    @extend %mt-25,
                    %mb-5;

                    li {
                        @extend %text-semibold,
                        %d-block,
                        %align-i-center;
                        @include font-size(18px);
                        color: $title-color-style2;
                        font-family: $text-font;

                        span {

                            img {
                                @extend %me-5;
                                max-width: 25px;


                                @include breakpoint(sm) {
                                    max-width: 26px;
                                }

                                @include breakpoint(md) {
                                    margin-inline-end: 5px;
                                }

                                @include breakpoint(lg) {
                                    max-width: 100%;
                                    margin-inline-end: 10px;
                                }
                            }
                        }

                        @include breakpoint(lg) {
                            @include font-size(20px);
                        }

                        &:not(:last-child) {
                            @extend %mb-15;

                            @include breakpoint(lg) {
                                margin-block-end: 20px;
                            }
                        }
                    }
                }

            }
        }
    }
}