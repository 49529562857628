.nav {
    &--feature {

        @media (max-width: 768px) {
            margin-right: inherit !important;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: start;
        }

        a {
            min-width: 51%;

            @media (max-width: 768px) {
                min-width: 47%;
                max-width: 48%;
                padding: 5px !important;
                margin-right: 5px;
                margin-bottom: 5px;
                margin-block-end: 5px;
                min-height: 39.5px;
                align-items: center;
                display: flex;
                justify-content: center;
            }

            h6 {
                @media (max-width: 768px) {
                    font-size: 13px !important;
                }

            }

        }

        .nav {
            &-link {
                @extend %p-25;
                border-radius: 8px !important;
                border: 1px solid $border-color !important;
                cursor: pointer;

                &:not(:last-child) {
                    @extend %mb-15;
                }

                &.active {
                    border-color: $wh-color !important;
                    background-color: $wh-color !important;
                    box-shadow: 0px 12px 30px rgba(33, 85, 186, 0.08);

                    h6 {
                        color: $title-color;
                    }
                }
            }
        }

        // Add custom styles for nav--feature

        margin-right: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        text-align: center;
    }

    &--feature2 {

        margin-left: auto !important;
        margin-right: auto !important;


        @media (max-width: 768px) {
            margin-right: inherit !important;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: start;
        }



        a {
            min-width: 51%;

            @media (max-width: 768px) {
                min-width: 47%;
                max-width: 48%;
                padding: 5px !important;
                margin-right: 5px;
                margin-bottom: 5px;
                margin-block-end: 5px;
                min-height: 39.5px;
                align-items: center;
                display: flex;
                justify-content: center;
            }

        }

        .nav {
            &-link {
                @extend %p-25;
                border-radius: 8px !important;
                border: 1px solid $border-color !important;
                cursor: pointer;

                &:not(:last-child) {
                    @extend %mb-15;
                }

                &.active {
                    border-color: $wh-color !important;
                    background-color: $wh-color !important;
                    box-shadow: 0px 12px 30px rgba(33, 85, 186, 0.08);

                    h6 {
                        color: $title-color;
                    }
                }
            }
        }

        // Add custom styles for nav--feature
        // margin: 0px 0px 0px auto !important;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        text-align: center;
    }

    &--feature3 {

        @media (max-width: 768px) {
            margin-right: inherit !important;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: start;
        }


        a {
            @media (max-width: 768px) {
                min-width: 47%;
                max-width: 48%;
                padding: 5px !important;
                margin-right: 5px;
                margin-bottom: 5px;
                margin-block-end: 5px;
                min-height: 39.5px;
                align-items: center;
                display: flex;
                justify-content: center;
            }
        }

        h6 {
            @media (max-width: 768px) {
                font-size: 13px !important;
            }

        }

        .nav {
            &-link {
                @extend %p-25;
                border-radius: 8px !important;
                border: 1px solid $border-color !important;
                cursor: pointer;

                &:not(:last-child) {
                    @extend %mb-15;
                }

                &.active {
                    border-color: $wh-color !important;
                    background-color: #0da25a !important;
                    box-shadow: 0px 12px 30px #0da25a14;

                    h6 {
                        color: $title-color;
                    }
                }
            }
        }

        // Add custom styles for nav--feature

        @media (max-width: 991px) {
            margin-right: inherit;
        }

        margin-right: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        text-align: center;
    }
}

// Add custom styles for feature__nav

.feature__nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.feature__image {
    margin-bottom: 40px;
    justify-content: center;
    display: flex;
}

.tab-content p {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
}

.toggle-view {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
}

.toggle-container {
    display: flex;
    flex-direction: column; // changed to vertical layout
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-left: 1px solid #0da25a !important;

    .toggle-btn {
        writing-mode: vertical-rl; // force text to be vertical
        transform: rotate(180deg); // rotate individual letter by 90deg

        @media (max-width: 768px) {
            padding: 5px;
            font-size: 15px;

        }

        font-size: 20px;
        padding: 25px;

        // Removed overall transform rotation
        // .toggle-letter {
        //     display: inline-block;
        // }
    }
}

.toggle-btn.active {
    background-color: var(--wh-color) !important;
    // color: $wh-color;
    // background-color: $primary-color;
}

.feature__thumb-inner .tab-content>.active {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
}

.dis-pd {
    @media (max-width: 768px) {
        margin-top: 0px;
    }

    margin-top: 48px;

}