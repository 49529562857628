.error {
    &__inner {
        @extend %p-20;
        background-color: $account-bg-color;
        border-radius: 4px;

        @include breakpoint(sm) {
            padding: 50px;
        }
        @include breakpoint(lg) {
            padding: 100px;
        }
    }
    &__thumb{
        img{
            border-radius: 16px;
            @extend %mb-30;
        }
    }

    &__content {
        h2 {
            @extend %text-capitalize, %mb-10;

            span {
                color: $secondary-color;
            }
        }

        p {
            max-inline-size: 53ch;
            margin: 0 auto;
        }
        a{
            @extend %mt-35;
        }
    }
}