.blog-details {
    @extend %of-hidden, %p-rel;


    &__item {
        &-inner {
            @extend %p-30;
            background-color: $wh-color;
            // trk-rgba 
            box-shadow: 0px -16px 48px rgba(6, 35, 63, 0.03), 0px 16px 48px rgba(6, 35, 63, 0.03);
            border-radius: 4px;

            @include breakpoint(md) {
                padding: 50px
            }
        }
    }

    &__thumb {
        @extend %of-hidden,
        %mb-35;
        border-radius: 16px;

        img {
            @extend %w-100;
            @include add-prefix(transition, $transition);
            object-fit: cover;
        }

        &:hover {
            img {
                @include add-prefix(transform, scale(1.01));
            }
        }
    }

    &__meta {
        @extend %pb-25;
        color: $text-color-light;

        ul {
            @extend %d-grid;
            // gap: 20px;

            @include breakpoint(sm) {
                display: flex;
                flex-wrap: wrap;
                gap: 18px;
            }
            @include breakpoint(md) {
                gap: 30px;
            }

            li {
                @extend %d-inline-flex,
                %align-i-center;
                gap: 10px;
                text-transform: capitalize;

                @include breakpoint(max-md) {
                    margin-block: 5px;
                }

                a {
                    color: $text-color-light;
                }

                i,
                svg {
                    margin-inline-end: 8px;

                    @include breakpoint(max-md) {
                        margin-inline-end: 5px;
                    }
                }
            }
        }
    }

    &__content {
        @extend %mb-35;

        h3 {
            @extend %text-capitalize;
            @extend %mb-10;

        }

        p {
            color: $text-color;
        }
    }

    &__segment {
        &-item {
            @extend %mb-35;
        }

        &-content {
            h5 {
                @extend %text-medium,
                %text-capitalize;
            }
        }
        &-thumb{
            img{
                border-radius: 8px;
            }
        }
    }

    &__action {
        &-inner {
            @extend %d-flex,
            %align-i-center,
            %justify-between,
            %py-25;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            gap: 20px;

            @include breakpoint(lg) {
                padding-block: 28px;
            }
        }
    }

    &__commentform {
        @extend %p-30,
        %mt-40;
        background-color: $tertiary-color3;
        border-radius: 16px;

        @include breakpoint(lg) {
            padding: 40px;
        }
    }



}