// background image use 
.bg-image {
    background-repeat: no-repeat;
}

.of-hidden {
    overflow: hidden;
}



// scrollTop
.scrollToTop {
    @include add-prefix(transition, $transition);
    position: fixed;
    // bottom: -20%;
    bottom: 5% !important;
    right: 5% !important;
    z-index: 9 !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 40px;
    text-align: center;
    border-radius: 5px !important;
    color: $thm-title-color;
    background-color: $brand-color !important;

    &--style1 {
        background-color: $brand-color !important;
    }
    &--style2 {
        background-color: $tertiary-color4 !important;
        i,svg{
            color: $bg-white;
        }
    }

    &:hover {
        @include add-prefix (transform, translateY(-5px));
        color: $thm-title-color;

        // i,svg{
        //     color: $title-color;
        // }
    }

}



// cta form
.cta-form {
    max-width: 500px;

    @include breakpoint(sm) {
        @include breakpoint (max-sm) {
            max-width: 600px;
        }
    }

    &--style2 {
        .cta-form {
            &__inner {
                @include breakpoint(max-md) {
                    justify-content: center;
                }
            }
        }
    }

    .form-control {
        @include add-prefix(transition, $transition);
        background-color: $tertiary-color;
        border: 1px solid $form-border;
        z-index: 1;

        ::placeholder {
            // color: $wh-color;
            opacity: 999;
        }

        &:focus {
            box-shadow: none;
            // t
            // border-color: rgba($brand-color, $alpha: 0.50);
            // border-color: $brand-color;
            border-color: $brand-color;
        }

        &--style1 {
            @extend %py-10,
            %px-20;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;

            @include breakpoint(sm) {
                padding: 16px 20px;
            }
        }

        &--style2 {
            @extend %py-10,
            %px-20,
            %me-10;
            background-color: $bg-white;
            border-color: $bg-white;
            color: $thm-title-color;

            &::placeholder {
                color: $thm-text-color;
            }




            @include breakpoint(sm) {
                max-width: 60%;
                padding: 11px 20px;
            }

            @include breakpoint(lg) {
                max-width: 60%;
                padding: 16px 20px;
            }

            @include breakpoint(lg) {
                max-width: 70%;
            }
        }

        &--style3 {
            @extend %py-10,
            %px-20,
            %me-10;
            background-color: $bg-white;
            border-color: $bg-white;
            color: $thm-title-color;

            &::placeholder {
                color: $thm-text-color;
            }

            &:focus {
                box-shadow: none;
                border-color: $bg-color-11;
            }

            @include breakpoint(sm) {
                max-width: 60%;
                padding: 11px 20px;
            }

            @include breakpoint(lg) {
                max-width: 60%;
                padding: 16px 20px;
            }

            @include breakpoint(lg) {
                max-width: 70%;
            }
        }
    }

    .cta-btn {
        @extend %py-15,
        %px-25,
        %text-bold;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        background: $brand-color;
        border-color: $brand-color;
        @include font-size(16px);

        @include breakpoint(sm) {
            padding: 16px 32px;
        }
    }

}


// password show hide toggle
.form-pass {
    @extend %p-rel;

    &__ctoggle,
    &__toggle {
        @extend %p-abs;
        background: none;
        border: none;
        color: $text-color;
        font-weight: 600;
        right: .75em;
        top: 53px;
        z-index: 9;
    }
}


// swiper nav
.swiper-nav {
    @extend %d-flex,
    %align-i-center;
    gap: 15px;

    @include breakpoint(max-md) {
        margin-block-start: 15px;
    }


    &__btn {
        @extend %d-grid,
        %place-i-center;
        @include box(40px);
        @include add-prefix(transition, $transition);
        border: 1px solid $border-color-3;
        border-radius: 99px;
        background-color: $wh-color;

        @include breakpoint(sm) {
            @include box(46px);
        }

        i,
        svg {
            @extend %text-bold;
            @include font-size(18px);
            @include add-prefix(transition, $transition);
            color: $title-color;
        }

        &.active,
        &:hover {
            background-color: $brand-color;
            border-color: $brand-color;

            i,
            svg {
                color: $title-color;
            }
        }


        &--style3 {
            border: 1px solid $tertiary-color4;

            &.active,
            &:hover {
                background-color: $tertiary-color4;
                border-color: $tertiary-color4;

                i,
                svg {
                    color: $thm-title-color-dark;
                }
            }
        }
    }


    &--style2 {
        .swiper-nav {

            &__btn {
                @extend %d-none;
                // trk-rgba 
                background-color: $arro-color1;
                border-color: $arro-color1;

                @include breakpoint(md) {
                    display: block;
                }


                i,
                svg {
                    @include add-prefix(transition, $transition);
                    color: $title-color;
                }

                &-prev {
                    @extend %p-abs;
                    content: "";
                    top: 36%;
                    left: -23px;
                    z-index: 1;

                    i,
                    svg {
                        color: $title-color;
                    }

                }

                &-next {
                    @extend %p-abs;
                    content: "";
                    top: 36%;
                    right: -23px;
                    z-index: 1;

                    i,
                    svg {
                        color: $bg-white;
                    }
                }

                &.active,
                &:hover {
                    background-color: $secondary-color;
                    border-color: $secondary-color;

                    i,
                    svg {
                        color: $bg-white;
                    }
                }
            }
        }
    }

}

// progress bar

.progress {
    @extend %mt-15;

    &-bar {
        background-color: #5370FF !important;

        &--style2 {
            background-color: #6084E0 !important;

        }
    }
}




// pagination start here
.paginations {
    background-color: $wh-color;
    box-shadow: 0px -16px 48px rgba(6, 35, 63, 0.03), 0px 16px 48px rgba(6, 35, 63, 0.03);
    border-radius: 8px;
    padding: 12px 16px;
    max-width: 450px;
    margin: 0 auto;
    margin-top: 40px;


    ul {
        li {
            @extend %px-5;

            a {
                @extend %p-rel,
                %d-flex,
                %text-regular,
                %align-i-center,
                %justify-center;
                @include add-prefix(transition, $transition);
                @include font-size(16px);
                width: 70px;
                height: 36px;
                // trk-rgba
                color: $text-color;
                opacity: 0.5;

                &.active,
                &:hover {
                    opacity: 1;
                    color: $brand-color;

                    i,
                    svg {
                        color: $brand-color;
                    }
                }
            }

            &:not(:first-child, :last-child) {
                a {
                    @extend %p-rel,
                    %d-flex,
                    %text-bold,
                    %align-i-center,
                    %justify-center;
                    @include add-prefix(transition, $transition);
                    width: 36px;
                    height: 36px;
                    background: $wh-color;
                    color: $title-color;
                    z-index: 1;
                    border-radius: 4px;
                    opacity: 1;

                    &.active,
                    &:hover {
                        color: $brand-color;
                        background-color: $pagination-bg-color;
                    }

                    &.dot {
                        color: $brand-color;
                        font-weight: 700;

                        &:hover {
                            color: $brand-color;
                        }
                    }
                }
            }



        }
    }
}



// playbtn
.playbtn {
    @extend %d-inline-flex,
    %align-i-center,
    %text-bold;
    @include add-prefix(transition, $transition);
    font-family: $text-font;
    color: $title-color;
    gap: 10px;

    &--style2 {
        color: $bg-white;
    }

    &__thumb {
        @include box(46px);
        @include add-prefix(transition, $transition);
        border-radius: 99px;
        // border: 2px solid currentColor;
        background-color: $thm-secondary-color;
        display: grid;
        place-items: center;

        @include breakpoint(sm){
            @include box(50px);
        }

        i,
        svg {
            @extend %text-regular;
            @include font-size(20px);
            @include add-prefix(transition, $transition);
            margin-inline-start: 3px;
            color: $bg-white;
        }

    }

    &__content {
        @extend %d-none,
        %text-semibold;
        @include font-size(16px);
        color: $bg-white;

        @include breakpoint(lg) {
            display: block;
        }
    }

    &:hover {
        .playbtn {
            color: $brand-color;

            &__thumb {
                // border: 2px solid $brand-color;

                i,
                svg {
                    color: $brand-color;
                }
            }

            &__content {
                color: $brand-color;
            }
        }

    }




}