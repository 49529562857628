@media (max-width: 768px) {
    .contact__form {
        width: 100% !important;
        /* Use !important to override inline styles */
    }
}

.footer {
    @extend %of-hidden,
    %p-rel;
    background-color: $footer-bg-color;
    color: $wh-color;
    font-weight: 400;


    &--style2 {
        background-color: $bg-color-12;
    }

    &__wrapper {
        @extend %p-rel;
        z-index: 2;
    }

    &__top {

        // @extend %mb-30;
        @include breakpoint(sm) {
            margin-block-end: 0;
        }

        &--style1 {
            padding-block: 80px;

            @include breakpoint(sm) {
                padding-block: 90px;
            }

            @include breakpoint(lg) {
                padding-block: 100px;
            }
        }

        &--style2 {
            padding-top: 200px;
            padding-block-end: 80px;

            @include breakpoint(sm) {
                padding-block-start: 250px;
                padding-block-end: 90px;
            }

            @include breakpoint(lg) {
                padding-block-start: 332px;
                padding-block-end: 100px;
            }
        }
    }

    &__app {
        @extend %d-flex,
        %align-i-center;
        gap: 20px;

        &-inner {
            @extend %p-rel,
            %d-flex,
            %align-i-center,
            %justify-center,
            %px-10,
            %pt-5,
            %pb-10;
            gap: 10px;
        }

        &-item {
            &--apple {
                background-color: $brand-color;
                color: $thm-title-color;
                border-radius: 8px;
            }

            &--apple2 {
                background-color: $bg-color-10;
                color: $thm-title-color;
                border-radius: 8px;
            }

            &--playstore {
                background-color: #7289DA;
                color: #000;
                border-radius: 8px;
                width: 100%;
            }

            &--playstore2 {
                background-color: $tertiary-color4;
                color: #000;
                border-radius: 8px;
            }

            &--playstore3 {
                width: 100%;
            }
        }

        &-content {
            line-height: 1.1;

            span {
                @extend %mb-0;
                @include font-size(12px);
            }

            p {
                @extend %text-medium;
                @include font-size(14px);
            }
        }
    }

    &__about {
        &-logo {
            img {
                @extend %mb-20;
            }

        }

        &-moto {
            @extend %mb-30;
            color: $text-color-2;
            padding-right: 50px;

            @include breakpoint(md) {
                max-inline-size: 40ch;
                margin-block-end: 32px;
            }
        }
    }

    &__links {
        &-tittle {
            h6 {
                color: $bg-white;
                @extend %mb-20;

                @include breakpoint(sm) {
                    margin-block-end: 20px
                }
            }
        }

    }

    &__linklist {
        &-item {
            &:not(:last-child) {
                @extend %mb-10;

            }

            p {
                color: $text-color;
                // color: $text-color-2
            }

            a {
                @include add-prefix(transition, $transition);
                color: $text-color-2;
                @extend %p-rel,
                %d-inline-block;

                &::after {
                    @extend %p-abs;
                    @include add-prefix(transform, translateY(-50%));
                    content: "";
                    transition: $transition;
                    top: 50%;
                    left: 0;
                    width: 10px;
                    height: 2px;
                    background-color: currentColor;
                    opacity: 0;
                }

                &:hover {
                    @include add-prefix(transform, translateX(10px));
                    color: $brand-color;

                    &::after {
                        opacity: 1;
                        left: -12px;
                    }
                }


                &.style2 {
                    &:hover {
                        @include add-prefix(transform, translateX(10px));
                        color: $bg-color-10;

                        &::after {
                            opacity: 1;
                            left: -12px;
                        }
                    }
                }
            }

        }
    }


    &__end {
        @extend %py-25;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        color: $text-color-2;
        @include font-size(16px);

        @include breakpoint(sm) {
            display: flex;
            justify-content: space-between;
        }

        &-copyright {
            @extend %mb-20,
            %d-inline-flex,
            %align-i-center;

            a {
                @extend %text-semibold;
                color: $brand-color;

                &.style2 {
                    color: $bg-color-10;
                }
            }

            @include breakpoint(sm) {
                margin-block-end: 0;
            }
        }

        &-links {
            @extend %d-flex,
            %align-i-center,
            %mt-25,
            %mb-20;

            @include breakpoint (max-sm) {
                justify-content: space-between;
            }

            @include breakpoint(sm) {
                gap: 10px;
            }

            @include breakpoint(md) {
                gap: 20px;
            }

        }

        &-item {
            a {
                color: $text-color;
            }
        }
    }

    // footer shpae

    &__shape {

        &-item {
            @extend %p-abs;

            &--1 {
                max-width: 60px;
                top: 8%;
                left: 50%;
                // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                opacity: 0.25;

                @include breakpoint(sm) {
                    width: auto;
                    top: 12%;
                    left: 40%;
                }

                @include breakpoint(md) {
                    top: 70%;
                    left: 5%;
                }

                @include breakpoint(lg) {
                    left: 27%;
                    top: 17%;
                }

                @include breakpoint(xl) {
                    top: 17%;
                    left: 23%;
                }

                @include breakpoint(xxl) {
                    top: 17%;
                    left: 33%;
                }
            }

            &--2 {
                content: "";
                height: 95%;
                width: 920px;
                border: 1px solid $bg-white;
                opacity: 0.06;
                padding: 40px;
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
                top: 0%;
                left: -3%;
                transform: rotate(13deg);
                display: none;

                @include breakpoint(md) {
                    display: block;
                    width: 530px;
                    top: 0%;
                    left: -19%;
                }

                @include breakpoint(lg) {
                    width: 530px;
                    top: 4%;
                    left: -11%;
                }

                @include breakpoint(xl) {
                    height: 100%;
                    top: 9%;
                    left: -6%;
                }

                @include breakpoint(xxl) {
                    height: 101%;
                    width: 920px;
                    top: 0%;
                    left: -26%;
                }

                @include breakpoint(xxxl) {
                    height: 107%;
                    width: 920px;
                    top: 0%;
                    left: -3%;
                }


                span {
                    position: absolute;
                    content: "";
                    height: 90%;
                    width: 900px;
                    background: linear-gradient(150.39deg, rgba(237, 242, 248, 0.22) 15.02%, #EDF2F8 99.59%);
                    border-top-right-radius: 40px;
                    border-bottom-right-radius: 40px;
                    top: 5%;
                    left: -1%;


                    @include breakpoint(md) {
                        top: 5%;
                        left: -77%;
                    }

                    @include breakpoint(lg) {
                        top: 5%;
                        left: -77%;
                    }

                    @include breakpoint(xl) {
                        top: 5%;
                        left: -77%;
                    }

                    @include breakpoint(xxl) {
                        top: 5%;
                        left: -1%;
                    }

                    @include breakpoint(xxxl) {
                        top: 5%;
                        left: -1%;
                    }
                }


            }


            // home 2
            &--3 {
                max-width: 50px;
                top: 21%;
                left: 57%;
                // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                opacity: 0.25;

                @include breakpoint(sm) {
                    width: auto;
                    top: 29%;
                    left: 40%;
                }

                @include breakpoint(md) {
                    top: 80%;
                    left: 30%;
                }

                @include breakpoint(lg) {
                    top: 45%;
                    left: 25%;
                }

                @include breakpoint(xl) {
                    top: 45%;
                    left: 22%;
                }

                @include breakpoint(xxl) {
                    top: 45%;
                    left: 26%;
                }

                @include breakpoint(xxxl) {
                    top: 45%;
                    left: 32%;
                }
            }


            // home 3
            &--4 {
                display: none;
                max-width: 50px;
                top: 21%;
                left: 57%;
                // @include animation(rotate 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                opacity: 0.25;

                @include breakpoint(sm) {
                    width: auto;
                    top: 29%;
                    left: 40%;
                }

                @include breakpoint(md) {
                    display: block;
                    top: 15%;
                    left: 30%;
                }

                @include breakpoint(lg) {
                    top: 66%;
                    left: 3%;
                }

                @include breakpoint(xl) {
                    top: 66%;
                    left: 3%;
                }

                @include breakpoint(xxl) {
                    top: 58%;
                    left: 3%;
                }

                @include breakpoint(xxxl) {
                    top: 63%;
                    left: 13%;
                }
            }

            &--5 {
                width: 70px;
                top: 60%;
                left: 43%;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                opacity: 0.25;

                @include breakpoint(sm) {
                    top: 29%;
                    left: 65%;
                }

                @include breakpoint(md) {
                    width: auto;
                    top: 60%;
                    left: 43%;
                }

                @include breakpoint(lg) {
                    top: 56%;
                    left: 43%;
                }

                @include breakpoint(xl) {
                    top: 50%;
                    left: 40%;
                }

                @include breakpoint(xxl) {
                    top: 50%;
                    left: 36%;
                }

                @include breakpoint(xxxl) {
                    top: 54%;
                    left: 46%;
                }
            }

        }

    }

}

.bckImgRight {
    width: 100%;
    background-position-x: 0%;
    background-position-y: 30%;
    background-size: contain;
    background-repeat: no-repeat;
    height: 400px;
    position: absolute;
    bottom: -130px;
    right: 0px;
    opacity: 0.8;
    transform: scaleX(-1);
}

.bckImgLeft {
    width: 100%;
    background-position-x: 0%;
    background-size: contain;
    background-repeat: no-repeat;
    height: 200px;
    position: absolute;
    bottom: -30px;
    left: 0px;
    opacity: 0.8;
}

@media (max-width: 768px) {
    .footer__app-inner {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .footer__app {
        align-items: flex-start !important;
        flex-direction: column !important;
        margin-top: 40px;
    }

    .footer__end-copyright {
        display: flex;
        flex-direction: column;
    }

    .social {
        text-align: center;
        justify-content: center;
    }

    .bckImg {
        background-position-x: 50% !important;
        background-size: cover !important;
        bottom: 0px;
    }

    .bckImgRight {
        width: 500px;
        height: 400px;
        position: absolute;
        bottom: -110px;
        right: -140px;
        opacity: 0.8;
        transform: scaleX(-1);
    }

    .bckImgLeft {
        width: 100%;
        height: 200px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        opacity: 0.8;
    }

}