.banner {

    &__content {
        @extend %mt-10;

        &-coin {
            @extend %mb-20;

            img {
                width: 70px;
                opacity: 0.25;
                opacity: 0.25;
                // @include animation(rotate 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint (sm) {
                    width: 73px;
                }

                @include breakpoint (md) {
                    width: 70px;
                }

                @include breakpoint (lg) {
                    width: 90px;
                }

                @include breakpoint (xl) {
                    width: auto;
                }
            }

            &--style2 {
                @extend %mb-30;

                img {
                    width: 70px;
                    opacity: 0.25;
                    // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                    @include breakpoint (sm) {
                        width: 73px;
                    }

                    @include breakpoint (md) {
                        width: 70px;
                    }

                    @include breakpoint (lg) {
                        width: 90px;
                    }

                    @include breakpoint (xl) {
                        width: auto;
                    }
                }
            }
        }

        &-heading {
            @extend %text-capitalize;

            span {
                color: $secondary-color;
            }

            &--style2 {


                text-align: center;


                @include breakpoint(sm) {
                    text-align: left;
                }

            }

        }

        &-moto {

            text-align: center;

            @extend %mb-20;
            color: $text-color;

            @include breakpoint(sm) {
                margin-block-end: 30px;
                text-align: left;
            }

            @include breakpoint(lg) {
                margin-block-end: 40px;
            }
        }

        &-social {
            p {
                @extend %mb-15,
                %text-bold;
                @include font-size(18px);
                color: $title-color;

                @include breakpoint(lg) {
                    margin-block-end: 25px;
                }
            }
        }

        &--style2 {
            h1 {
                max-block-size: 15ch;
            }
        }
    }

    &__btn-group {
        @extend %mb-40;

        @include breakpoint(sm) {
            margin-block-end: 50px;
        }

        @include breakpoint(lg) {
            gap: 30px;
            margin-block-end: 60px;
        }

        @include breakpoint(xl) {
            margin-block-end: 60px;
        }
    }


    &__shape {

        &-item {
            @extend %p-abs;

            &--1 {
                right: 12%;
                z-index: 1;
                top: 51%;
                opacity: 0.25;
                // @include animation(rotate 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 46%;
                    right: 12%;
                }

                @include breakpoint(md) {
                    top: 80%;
                    right: 45%;
                }

                @include breakpoint(lg) {
                    top: 71%;
                    right: 52%;
                }


                @include breakpoint(xl) {
                    top: auto;
                    bottom: 20%;
                    left: 52%;
                }

                @include breakpoint(xxl) {
                    right: 46%;
                    left: auto;
                }
            }

            // home 2
            &--2 {
                width: 60px;
                z-index: 2;
                top: 50%;
                bottom: 20%;
                left: 8%;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    width: 80px;
                    top: 50%;
                    bottom: 20%;
                    left: 8%;
                }

                @include breakpoint(md) {
                    width: 70px;
                    top: 65%;
                    bottom: 20%;
                    left: 34%;
                }

                @include breakpoint(lg) {
                    width: auto;
                    top: 67%;
                    bottom: 20%;
                    left: 38%;
                }


                @include breakpoint(xl) {
                    top: 67%;
                    bottom: 20%;
                    left: 52%;
                }

                @include breakpoint(xxl) {
                    top: 47%;
                    bottom: 20%;
                    left: auto;
                    right: 10%;
                }

            }


            // home 3 
            &--3 {
                width: 70px;
                top: 51%;
                right: 9%;
                z-index: 1;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {

                    top: 24%;
                    right: 9%;
                }

                @include breakpoint(md) {
                    top: 85%;
                    right: 10%;
                }

                @include breakpoint(lg) {
                    width: auto;
                    top: auto;
                    bottom: 4%;
                    right: 3%;
                }


                @include breakpoint(xl) {
                    top: auto;
                    bottom: 4%;
                    right: 3%;
                }

                @include breakpoint(xxl) {
                    bottom: 7%;
                    right: 3%;
                }

                @include breakpoint(xxxl) {
                    right: 15%;
                }
            }

            &--4 {
                top: 31%;
                left: 15%;
                z-index: 1;
                // @include animation(rotate 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 31%;
                    left: 15%;
                }

                @include breakpoint(md) {
                    top: 22%;
                    left: 50%;
                }

                @include breakpoint(lg) {
                    top: 22%;
                    left: 38%;
                }


                @include breakpoint(xl) {
                    top: 22%;
                    left: 34%;
                }

                @include breakpoint(xxl) {
                    top: 22%;
                    left: 38%;
                }

                @include breakpoint(xxxl) {
                    top: 22%;
                    left: 41%;
                }
            }

            // home 4
            &--5 {
                width: 65px;
                top: 9%;
                left: 0%;
                z-index: 1;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                    width: 70px;
                    opacity: 0.25;
                    top: 11%;
                    left: 3%;
                }

                @include breakpoint(md) {
                    width: 80px;
                    top: 28%;
                    left: 3%;
                }

                @include breakpoint(lg) {
                    width: auto;
                    top: 28%;
                    left: 3%;
                }


                @include breakpoint(xl) {
                    top: 17%;
                    left: 7%;
                }

                @include breakpoint(xxl) {
                    top: 17%;
                    left: 9%;
                }

                @include breakpoint(xxxl) {
                    top: 17%;
                    left: 20%;
                }
            }


            // home 5
            &--6 {
                width: 70px;
                top: 51%;
                right: 9%;
                z-index: 1;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 26%;
                    right: 9%;
                    width: 120px;
                }

                @include breakpoint(md) {
                    width: auto;
                    top: 40%;
                    right: 8%;
                }

                @include breakpoint(lg) {
                    top: 15%;
                    right: 7%;
                }


                @include breakpoint(xl) {
                    top: 18%;
                    right: 7%;
                }

                @include breakpoint(xxl) {
                    top: 17%;
                    right: 9%;
                }

                @include breakpoint(xxxl) {
                    top: 17%;
                    right: 19%;
                }
            }

            &--7 {
                width: 100px;
                top: 18%;
                left: 50%;
                z-index: 1;
                // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 26%;
                    left: 32%;
                    width: 140px;
                }

                @include breakpoint(md) {
                    width: auto;
                    top: 26%;
                    left: 30%;
                }

                @include breakpoint(lg) {
                    top: 49%;
                    left: 20%;
                    width: 120px;
                }


                @include breakpoint(xl) {
                    width: auto;
                    top: 42%;
                    left: 20%;
                }

                @include breakpoint(xxl) {
                    top: 44%;
                    left: 20%;
                }

                @include breakpoint(xxxl) {
                    top: 45%;
                    left: 29%;
                }
            }

        }

    }

    &--style1 {
        @extend %p-rel,
        %of-hidden;
        @include background-cover;
        padding-block-start: 80px;
        padding-block-end: 40px;

        @include breakpoint(sm) {
            padding-block-end: 10px;
        }

        @include breakpoint(md) {
            padding-block-start: 132px;
            padding-block-end: 100px;
        }

        @include breakpoint(lg) {
            padding-block-start: 148px;
            padding-block-end: 135px;
        }

        @include breakpoint(xl) {
            padding-block-start: 148px;
            padding-block-end: 132px;
        }

        @include breakpoint(xxl) {
            padding-block-start: 148px;
            padding-block-end: 148px;
        }

        @include breakpoint(xxxl) {
            padding-block-start: 148px;
            padding-block-end: 288px;
        }

        .banner {

            &__bg {
                &-element {
                    img {
                        @extend %w-100,
                        %p-abs;
                        left: 0;
                        top: 0;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        opacity: 0.8;
                    }

                    span {
                        @extend %w-100,
                        %p-abs;
                        left: 0;
                        top: 0;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        z-index: -2;
                    }
                }
            }

            &__thumb {


                @include breakpoint(md) {
                    position: absolute;
                    // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                    right: -20px;
                    top: 213px;
                    max-inline-size: 56%;
                }

                @include breakpoint(lg) {
                    // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                    right: -20px;
                    top: 213px;
                    max-inline-size: 56%;
                }

                @include breakpoint(xl) {
                    right: 53px;
                    top: 160px;
                    max-inline-size: 56%;
                }

                @include breakpoint(xxl) {
                    right: 60px;
                    top: 118px;
                    max-inline-size: 51%;
                }

                @include breakpoint(xxxl) {
                    right: 280px;
                    top: 205px;
                    max-inline-size: 52%;
                }
            }

        }

    }

    // banner style 2
    &--style2 {
        @extend %p-rel,
        %of-hidden;
        //background: linear-gradient(to bottom right, #0f2f25 60%, #0c261e 100%);
        padding-block-start: 110px;
        padding-block-end: 50px;

        @media (max-width: 1201px) {
            background-image: none !important;
        }


        /** ::before pseudo-element to ensure the background stays at #0CD66E **/
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(152deg, var(--bg-color-trans) 0%, var(--bg-color) 100%);
            z-index: -1;
        }

        .bg-shape-svg {
            width: 0;
            height: 0;
            display: none;

            @include breakpoint(xl) {
                display: block;
            }
        }

        @include breakpoint(sm) {
            padding-block-end: 40px;
            height: 90vh;

        }

        @include breakpoint(md) {
            clip-path: url(#shape-clippath);
            // background-color: $thm-bg-color-2-dark;
            padding-block-start: 132px;
            padding-block-end: 80px;
            height: 90vh;

        }

        @include breakpoint(lg) {
            padding-block-start: 148px;
            padding-block-end: 120px;
            height: 90vh;

        }

        @include breakpoint(xl) {
            background-color: none;
            padding-block-start: 160px;
            height: 90vh;
            padding-block-end: 230px;
        }


        .banner {
            &__thumb {

                display: flex;
                justify-content: center;

                img {
                    max-width: 300px;
                    margin-top: 0rem;
                }

                @include breakpoint(sm) {
                    img {
                        max-width: 100%;
                    }
                }

                @include breakpoint(md) {
                    @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                    position: absolute;
                    left: 0%;
                    bottom: 150px;
                    max-inline-size: 46%;
                    z-index: 1;
                    margin-top: initial;


                }

                @include breakpoint(lg) {
                    left: 7%;
                    bottom: 270px;
                    max-inline-size: 40%;
                }

                @include breakpoint(xl) {
                    left: 90px;
                    bottom: 300px;
                    max-inline-size: 40%;
                }

                @include breakpoint(xxl) {
                    left: 90px;
                    bottom: 270px;
                    max-inline-size: 52%;
                }

                @include breakpoint(xxxl) {
                    left: 217px;
                    bottom: 340px;
                    max-inline-size: 30%;
                }

                @media screen and (min-width: 1900px) {
                    left: 360px;
                    bottom: 350px;
                    max-inline-size: 52%;
                }

            }

            &__content {
                @extend %p-rel;
                z-index: 2;

                h1 {
                    color: $bg-white;
                }

                p {
                    color: $text-color-3;
                }

                &-playbtn {
                    color: $bg-white;

                    i,
                    svg {
                        color: $bg-white;
                    }
                }

            }

            &__wrapper {
                &--style2 {
                    &::after {
                        @extend %p-abs,
                        %d-none;
                        bottom: 0px;
                        left: 12%;
                        content: "";
                        height: 870px;
                        width: 370px;
                        // trk-rgba 
                        // background-color: rgba(255, 255, 255, 0.04);
                        border: 45px solid rgba(255, 255, 255, 0.9);
                        border-bottom-left-radius: 999px;
                        border-bottom-right-radius: 999px;
                        // border-radius: 186.5px;
                        opacity: 0.07999999821186066;
                        background: linear-gradient(152deg, rgba(229, 237, 248, 0.47) 0%, #EDF2F8 100%);


                        @include breakpoint(md) {
                            // display: block;
                            transform: rotate(18deg);
                            left: 14%;
                            bottom: 2%;
                        }

                        @include breakpoint(lg) {
                            display: block;
                            bottom: 1%;
                            left: 14%;
                        }

                        @include breakpoint(xl) {
                            transform: rotate(22deg);
                            bottom: 10%;
                            left: 14%;
                        }

                        @include breakpoint(xxl) {
                            bottom: 10%;
                            transform: rotate(23deg);
                            left: 13%;
                        }

                        @include breakpoint(xxxl) {
                            bottom: 10%;
                            transform: rotate(23deg);
                            left: 18%;
                        }

                        @media screen and (min-width: 1900px) {
                            bottom: 10%;
                            transform: rotate(23deg);
                            left: 23%;
                        }
                    }
                }
            }
        }
    }


    // banner style 3
    &--style3 {
        @extend %p-rel,
        %of-hidden;
        @include background-cover;
        padding-block-start: 80px;
        padding-block-end: 80px;

        @include breakpoint(sm) {
            padding-block-start: 80px;
            padding-block-end: 80px;
        }

        @include breakpoint(md) {
            padding-block-start: 90px;
            padding-block-end: 100px;
        }

        @include breakpoint(lg) {
            padding-block-start: 130px;
            padding-block-end: 138px;
        }

        @include breakpoint(xl) {
            padding-block-start: 140px;
            padding-block-end: 150px;
        }

        @include breakpoint(xxxl) {
            padding-block-start: 160px;
            padding-block-end: 165px;
        }


        .banner {
            @extend %p-rel;

            &__bg {
                &-element {
                    img {
                        @extend %w-100,
                        %p-abs;
                        left: 0;
                        top: 0;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        opacity: 0.8;
                    }

                    span {
                        @extend %w-100,
                        %p-abs;
                        left: 0;
                        top: 0;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        z-index: -2;
                    }
                }
            }

            &__thumb {
                img {
                    // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                }

                @include breakpoint(md) {
                    &::before {
                        position: absolute;
                        content: "";
                        width: 162px;
                        height: 100%;
                        background: linear-gradient(160deg, var(--bg-grad-color-trans-4) 0%, var(--bg-grad-color-4) 100%);
                        left: 46%;
                        bottom: 0;

                        @include breakpoint(lg) {
                            width: 195px;
                            left: 48%;
                        }

                        @include breakpoint(xl) {
                            width: 230px;
                            left: 48.5%;
                        }

                        @include breakpoint(xxl) {
                            width: 230px;
                            left: 49%;
                        }
                    }
                }

            }

            &__btn-group {
                @extend %mb-0;
            }

            &__counter {
                &-inner {
                    @extend %d-block,
                    %align-i-center;
                }

                &-item {
                    @extend %p-25;
                    // max-width: 260px;
                    border-radius: 8px;
                    background-color: $wh-color;
                    border: 2px solid rgba(31, 35, 61, 0.00);
                    box-shadow: 0px 16px 64px 0px rgba(0, 208, 148, 0.12);

                    @include breakpoint(md) {
                        padding: 22px;
                    }

                    @include breakpoint(lg) {
                        padding: 22px;
                    }

                    @include breakpoint(xl) {
                        padding: 24px;
                    }

                    &:not(:last-child) {
                        margin-block-end: 32px;
                    }

                    h4 {
                        @extend %text-bold;
                        font-family: $text-font;
                        margin-bottom: 0px;

                        span {
                            @extend %text-bold;
                            font-size: inherit;
                            color: inherit;
                            font-family: inherit;
                        }

                    }

                    p {
                        @extend %text-capitalize,
                        %mb-0,
                        %mt-10;
                        @include font-size(18px);
                        line-height: 1.3;
                        font-family: $text-font;

                    }
                }
            }

            &__bg {
                @extend %p-abs;
                z-index: -1;
            }

        }

    }


    // banner style 4
    &--style4 {
        @extend %p-rel,
        %of-hidden;
        @include background-cover;
        padding-block-start: 110px;
        padding-block-end: 240px;

        @include breakpoint(sm) {
            padding-block-start: 140px;
            padding-block-end: 250px;
        }

        @include breakpoint(md) {
            padding-block-start: 170px;
            padding-block-end: 260px;
        }

        @include breakpoint(lg) {
            padding-block-start: 180px;
            padding-block-end: 270px;
        }

        @include breakpoint(xl) {
            padding-block-start: 208px;
            padding-block-end: 280px;
        }

        @include breakpoint(xxl) {
            padding-block-start: 208px;
            padding-block-end: 290px;
        }

        @include breakpoint(xxxl) {
            padding-block-start: 208px;
            padding-block-end: 317px;
        }

        .banner {

            &__content {
                h1 {
                    @extend %text-center,
                    %text-capitalize,
                    %text-semibold;
                    @include font-size(80px);
                    letter-spacing: -0.5px;
                    color: $bg-white;

                    @include breakpoint(lg) {
                        line-height: 92px;
                    }
                }

                p {
                    @extend %text-semibold,
                    %text-center;
                    @include font-size(18px);
                    max-inline-size: 60ch;
                    margin-inline: auto;
                    color: $bg-white;
                    opacity: 0.7;

                    @include breakpoint(xl) {
                        @include font-size(20px);
                    }
                }

                &-btn {
                    @extend %justify-center,
                    %mt-40;

                    @include breakpoint(xl) {
                        margin-top: 48px;
                    }
                }
            }
        }

    }


    // banner style 5 for home 5
    &--style5 {
        @extend %p-rel,
        %of-hidden;
        @include background-cover;
        padding-block-start: 100px;
        padding-block-end: 80px;


        @include breakpoint(sm) {
            padding-block-start: 120px;
            padding-block-end: 80px;
        }

        @include breakpoint(md) {
            padding-block-start: 160px;
            padding-block-end: 100px;
        }

        @include breakpoint(lg) {
            padding-block-start: 180px;
            padding-block-end: 100px;
        }

        @include breakpoint(xl) {
            padding-block-start: 180px;
            padding-block-end: 100px;
        }

        @include breakpoint(xxxl) {
            padding-block-start: 200px;
            padding-block-end: 100px;
        }

        &::after {
            @extend %p-abs;
            content: "";
            width: 100%;
            height: 100%;
            background-image: url(/images/banner/home5/bg.svg);
            background-position: center;
            background-repeat: no-repeat;
        }


        .banner {
            @extend %p-rel;


            &__content {
                @extend %mt-10,
                %p-rel;
                z-index: 1;

                p {
                    @extend %mb-15;
                    @include font-size(18px);
                    color: $tertiary-color4;
                    text-transform: uppercase;
                    letter-spacing: 2px;

                    @include breakpoint(sm) {
                        @include font-size(24px);
                        margin-bottom: 30px;
                    }

                    @include breakpoint(xl) {
                        margin-bottom: 36px;
                    }
                }

                &-heading {
                    @extend %text-capitalize,
                    %text-semibold;
                    @include font-size(58px);
                    max-inline-size: 18ch;
                    // letter-spacing: 2px;

                    // @include breakpoint(sm){
                    //     letter-spacing: 4px;
                    // }

                }

                &-moto {
                    @extend %mb-20;
                    color: $text-color;

                    @include breakpoint(sm) {
                        margin-block-end: 30px;
                    }

                    @include breakpoint(lg) {
                        margin-block-end: 40px;
                    }
                }
            }

            &__thumb {
                &-inner {
                    img {
                        // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                        @include breakpoint(lg) {
                            position: absolute;
                            left: 37%;
                            top: 27%;
                            width: 330px;
                        }

                        @include breakpoint(xl) {
                            position: absolute;
                            left: 46%;
                            top: 29%;
                            width: 350px;
                        }

                        @include breakpoint(xxl) {
                            position: absolute;
                            width: auto;
                            left: 45.5%;
                            top: 26%;
                        }

                        @include breakpoint(xxxl) {
                            position: absolute;
                            left: 46%;
                            top: 25%;
                        }
                    }

                    @include breakpoint(lg) {
                        &::before {
                            position: absolute;
                            content: "";
                            width: 388px;
                            height: 600px;
                            // background-color: $bg-color-10;
                            background-image: linear-gradient(180deg, $body-color 0%, $bg-color-10 70%);
                            border-bottom-right-radius: 4px;
                            border-bottom-left-radius: 4px;
                            left: 46%;
                            top: 0;

                            // @include breakpoint(lg) {
                            //     width: 195px;
                            //     left: 48%;
                            // }

                            // @include breakpoint(xl) {
                            //     width: 230px;
                            //     left: 48.5%;
                            // }

                            @include breakpoint(lg) {
                                width: 340px;
                                left: 35%;
                            }

                            @include breakpoint(xl) {
                                height: 633px;
                                width: 340px;
                                left: 45%;
                            }

                            @include breakpoint(xxl) {
                                width: 360px;
                                left: 45%;
                            }

                            @include breakpoint(xxxl) {
                                width: 388px;
                                left: 45%;
                            }
                        }
                    }
                }
            }

            &__testimonial {
                &-inner {
                    @extend %mt-70;

                    @include breakpoint(sm) {
                        margin-block-start: 80px;
                    }

                    @include breakpoint(lg) {
                        margin-block-start: 190px;
                    }

                    @include breakpoint(xl) {
                        margin-block-start: 149px;
                    }
                }
            }

            &__thumb2 {
                &-inner {
                    @extend %p-30;
                    border-radius: 4px;
                    background-color: $tertiary-color4;
                    max-width: 332px;

                    // @include breakpoint(lg) {
                    //     padding: 20px;
                    // }
                    @include breakpoint(xl) {
                        padding: 32px;
                    }

                    // img {
                    //     @include animation(light 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);
                    // }

                    p {
                        @extend %mb-0,
                        %text-center;
                        @include font-size(20px);
                        color: $thm-title-color-dark;
                        line-height: 1.4;

                        @include breakpoint(sm) {
                            @include font-size(18px);
                        }

                        @include breakpoint(md) {
                            @include font-size(22px);
                        }
                    }

                }
            }

            &__shorttext1 {

                p {
                    color: $title-color;
                }

                @include breakpoint(lg) {
                    position: absolute;
                    left: 35%;
                    max-width: 34ch;
                    top: 58.5%;
                }

                @include breakpoint(xl) {

                    position: absolute;
                    left: 45%;
                    max-width: 34ch;
                    top: 63.5%;
                }

            }

        }

    }


    &--style6 {

        @include breakpoint(md) {
            height: 81vh;
        }

        @include breakpoint(lg) {
            height: 81vh;
        }

        @include breakpoint(xl) {
            height: 81vh;
        }
    }

}