.feature {
    @extend %p-rel,
    %of-hidden;

    &--style1 {

        @media (max-width: 768px) {
            padding-bottom: 0px !important;
        }

        .feature {
            @extend %p-rel,
            %of-hidden;

            &__wrapper {
                @extend %p-rel;
                z-index: 2;
            }

            &__item {
                &-content {

                    h6 {
                        @extend %mb-0,
                        %text-capitalize,
                        %text-semibold;
                        @include font-size(18px);
                        color: $text-color;
                        font-family: $text-font;
                    }
                }
            }

            &__image {
                img {
                    border-radius: 20px;
                }
            }

            &__thumb {
                position: relative;
                z-index: 2;

                @media (max-width: 768px) {
                    padding-top: 0px !important;
                }



            }

            &__shape {
                &-item {
                    @extend %p-abs;

                    &--1 {
                        width: 50px;
                        top: 5%;
                        left: 71%;
                        z-index: 2;
                        opacity: 0.25;
                        // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                        @include breakpoint(sm) {
                            width: 70px;
                            top: 6%;
                            left: 65%;
                        }

                        @include breakpoint(md) {
                            max-width: 75px;
                            top: 13%;
                            left: 50%;
                        }

                        @include breakpoint(lg) {
                            max-width: 100%;
                            top: 19%;
                            left: 46%;
                        }

                        @include breakpoint(xl) {
                            top: 19%;
                            left: 50%;
                        }

                        @include breakpoint(xxl) {
                            top: 19%;
                            left: 50%;
                        }
                    }

                    &--2 {
                        content: "";
                        height: 92%;
                        width: 920px;

                        border: 2px solid var(--bg-grad-color);
                        padding: 40px;
                        border-bottom-left-radius: 25%;
                        top: 0%;
                        right: -3%;
                        transform: rotate(-10deg);
                        display: none;

                        @include breakpoint(md) {
                            display: block;
                            width: 570px;
                            top: -8%;
                            right: -24%;
                        }

                        @include breakpoint(lg) {
                            width: 570px;
                            top: -10%;
                            right: -4%;
                        }

                        @include breakpoint(xl) {
                            height: 100%;
                            width: 725px;
                            top: -12%;
                            right: -4%;
                        }

                        @include breakpoint(xxl) {
                            width: 1000px;
                            top: -12%;
                            right: -15%;
                        }

                        @include breakpoint(xxxl) {
                            width: 1000px;
                            top: -12%;
                            right: -4%;
                        }


                        span {
                            position: absolute;
                            content: "";
                            height: 96%;
                            width: 700px;
                            background: linear-gradient(219deg, var(--bg-grad-color-trans) 0%, var(--bg-grad-color) 100%);
                            border-bottom-left-radius: 25%;
                            top: 5%;
                            right: -1%;


                            @include breakpoint(md) {
                                width: 550px;
                                top: 0%;
                                right: -1%;
                            }

                            @include breakpoint(lg) {
                                width: 550px;
                                top: 0%;
                                right: -1%;
                            }

                            @include breakpoint(xl) {
                                height: 97%;
                                width: 700px;
                                top: 0%;
                                right: -1%;
                            }

                            @include breakpoint(xxl) {
                                width: 980px;
                                top: 0%;
                                right: -1%;
                            }
                        }
                    }
                }
            }
        }
    }

    &--style2 {
        .feature {
            &__item {
                &-inner {
                    @include add-prefix(transition, $transition);
                    background-color: $body-color;
                    padding: 16px;
                    border-radius: 8px;
                }

                &-thumb {
                    margin-block-end: 24px;
                }

                &-content {
                    h5 {
                        @extend %mb-10,
                        %text-clamp-1;
                    }

                    p {
                        @extend %mb-10;
                    }
                }

                &:hover {
                    transform: translateY(-10px);
                    cursor: pointer;
                }

            }


            &__shape {

                &-item {
                    @extend %p-abs;

                    &--1 {
                        width: 40px;
                        top: 1%;
                        left: 6%;
                        opacity: 0.25;
                        // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                        @include breakpoint(sm) {
                            width: 80px;
                            top: 3%;
                            left: 7%;
                        }

                        @include breakpoint(md) {
                            top: 8%;
                            left: 3%;
                        }

                        @include breakpoint(lg) {
                            width: auto;
                            top: 15%;
                            left: 2%;
                        }

                        @include breakpoint(xl) {
                            top: 15%;
                            left: 2%;
                        }

                        @include breakpoint(xxl) {
                            top: 15%;
                            left: 8%;
                        }

                        @include breakpoint(xxxl) {
                            top: 15%;
                            left: 19%;
                        }
                    }
                }

            }
        }

    }

}

.feature-comparison {
    padding-top: 2rem;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.4),
        0 6px 12px rgba(0, 0, 0, 0.2);

    .section-header {
        margin-bottom: 1.5rem;

        h2 {
            color: #0cd66e; // bright green
        }

        p {
            color: #f1fff9; // near-white text
        }
    }



    .feature-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        background: linear-gradient(152deg, var(--bg-color-trans) 0%, var(--bg-color) 100%);
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0 0 10px #0cd66e;
    }

    .feature-table thead {
        background-color: #121A27;
    }

    .feature-table thead th {
        color: #fff;
        padding: 1rem;
        text-align: left;
        font-weight: 600;
        border-bottom: 1px solid #0c2e24;
        text-align: center;
    }

    .feature-table thead th.highlight {
        color: #0cd66e;
    }

    /* Table rows & cells */
    .feature-table tbody tr {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
        text-align: center;
    }

    .feature-table tbody td {
        padding: 1rem;
        color: #f1fff9;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
        text-align: center;
    }

    .feature-table tbody td.highlight {
        color: #0cd66e;
        font-weight: 500;
        text-align: center;
    }

    .feature-table tbody tr:hover {
        background-color: #121A27;
    }

    .table-responsive {
        box-shadow: 0 0 10px #0cd66e;
        border-radius: 14px;
    }

    /* 
        Mobile: Stack rows 
        Remove separate "cards" by removing margin/box-shadow from each row 
        and keeping the box-shadow on the .feature-table instead.
      */
    @media (max-width: 768px) {
        .feature-table tbody tr:nth-child(even) {
            background-color: #181e25;
        }

        .feature-table tbody tr:nth-child(odd) {
            background-color: #212935;
        }

        .table-responsive {
            box-shadow: 0 0 10px #0cd66e;
            border-radius: 14px;
        }

        /* Hide the table header row visually, but keep it for screen readers */
        .feature-table thead {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        .feature-table,
        .feature-table tbody,
        .feature-table tr,
        .feature-table td {
            display: block;
            width: 100%;
        }

        /* Let the table keep its single border & box-shadow */
        .feature-table {
            border-radius: 16px;
            overflow: hidden;
            box-shadow: 0 0 10px #0cd66e;
            /* single shadow for entire table */
        }

        /* Each row is separated by a bottom border only */
        .feature-table tbody tr {
            border: none;
        }

        .feature-table tbody tr:last-child td {
            border-bottom: none;
        }

        /* Stack each cell, label on top, content below */
        .feature-table tbody td {
            border: none;
            position: relative;
            text-align: left;
            padding: 1rem 1rem 0.5rem;
            /* adjust spacing if you like */
            text-align: center;
        }

        /* Show the label as a block above the content, more visible color */
        .feature-table tbody td:before {
            content: attr(data-label);
            display: block;
            margin-bottom: 0.25rem;
            font-weight: 700;
            /* bold label */
            color: #0cd66e;
            /* highlight color */
            text-align: center;
        }
    }
}

.desktop-label {
    display: inline;
}

.mobile-label {
    display: none;
}

@media (max-width: 768px) {
    .desktop-label {
        display: none;
    }

    .mobile-label {
        display: inline;
    }

    .feature-table tbody td:first-child {
        font-size: 1.1rem;
        font-weight: 800;
        color: #0cd66e;
    }

    .feature-comparison .feature-table tbody td:before {
        color: lightgrey;
    }
}