        .team {

            &__item {
                &-inner {
                    @extend %p-rel,
                    %of-hidden;
                    background-color: $team-bg-color;
                    border-radius: 12px;
                    @include add-prefix(transition, $transition);
                }

                &-thumb {
                    @extend %of-hidden,
                    %p-rel;
                    border-radius: 8px;

                    img {
                        @extend %w-100;
                        // @include add-prefix(transition, $transition);

                        transition: all 0.3s ease-in-out;
                        object-fit: cover;
                    }

                    &--style1 {
                        img {
                            transform: translateY(15px);
                        }
                    }
                }

                &-content {
                    @extend %p-abs;
                    @include add-prefix(transition, $transition);
                    bottom: 0;
                    left: 0;
                    width: 100%;

                    &--style1 {
                        padding: 8px;
                    }

                }

                &-author {
                    @extend %text-center;
                    @include add-prefix(transition, $transition);
                    // trk-rgba 


                    &--style1 {
                        // background-color: $team-bg-color;
                        // background-color: rgba($wh-color, $alpha 0.8);
                        background-color: rgba(255, 255, 255, 0.8);
                        // border: 1px solid rgba($border-color, $alpha 0.1);
                        backdrop-filter: blur(5px);
                        padding: 12px 8px;
                        border-radius: 8px;
                    }

                    &--style2 {
                        background-color: $wh-color;
                        border-top-left-radius: 16px;
                        border-top-right-radius: 16px;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        border: 2px solid $social-border-color;

                        .team__item{
                            &-authorinfo {
                                @extend %p-15;
    
                                h6 {
                                    @extend %text-bold;
                                    @include add-prefix(transition, $transition);
                                    font-family: $title-font;
                                    color: $title-color;
    
                                    &:hover {
                                        color: $brand-color;
                                    }
                                }
    
                            }
    
                            &-authorsocial {
                                @extend %px-20,
                                %pb-25;
                            }
                        }

                    }


                }

                &-authorinfo {
                    h6 {
                        @extend %text-semibold;
                        @include add-prefix(transition, $transition);
                        font-family: $text-font;
                        color: $title-color;
                        z-index: 2;

                        &:hover {
                            color: $brand-color;
                        }

                    }

                    p {
                        @include font-size(14px);
                    }

                }

                // shape 
                &-shape1 {
                    @extend %p-abs;
                    @include add-prefix(transition, $transition);
                    opacity: 0.5;
                    top: 45px;
                    left: 18px;

                    @include breakpoint(md) {
                        top: 45px;
                        left: 18px;
                    }

                    @include breakpoint(lg) {
                        width: 35px;
                        top: 25px;
                        left: 20px;
                    }

                    @include breakpoint(xl) {
                        width: auto;
                        top: 45px;
                        left: 18px;
                    }
                }

                &-shape2 {
                    @extend %p-abs;
                    @include add-prefix(transition, $transition);
                    opacity: 0.1;
                    top: 70px;
                    right: 25px;

                    @include breakpoint(md) {
                        top: 70px;
                        right: 25px;
                    }

                    @include breakpoint(lg) {
                        width: 35px;
                        top: 60px;
                        right: 18px;
                    }

                    @include breakpoint(xl) {
                        width: auto;
                        top: 70px;
                        right: 25px;
                    }

                }

                &-shape3 {
                    @extend %p-abs;
                    @include add-prefix(transition, $transition);
                    opacity: 0.1;
                    bottom: 200px;
                    left: 25px;

                    @include breakpoint(sm) {
                        bottom: 136px;
                        left: 25px;
                    }

                    @include breakpoint(md) {
                        bottom: 200px;
                        left: 25px;
                    }

                    @include breakpoint(lg) {
                        width: 35px;
                        bottom: 130px;
                        left: 17px;
                    }

                    @include breakpoint(xl) {
                        width: auto;
                        bottom: 136px;
                        left: 25px;
                    }

                }


                &--bgcolor2 {
                    .team {
                        &__item {
                            &-inner {
                                background-color: $tertiary-color;
                            }
                        }
                    }
                }

                &:hover {
                    .team__item {

                        &-author {
                            h6 {
                                color: $brand-color;
                            }
                        }

                        &-thumb {
                            &--style1 {
                                img {
                                    transform: translateY(0);
                                }
                            }

                            &--style2 {
                                img {

                                    @include add-prefix(transform, scale(1.06));
                                }
                            }
                        }

                        &-shape1,
                        &-shape2,
                        &-shape3 {
                            opacity: 1;
                            transform: rotate(45deg);
                        }
                    }

                }

            }



            &--details {
                .team {
                    &__thumb {
                        @extend %of-hidden;
                        border-radius: 20px;

                        img {
                            @extend %w-100;
                            @include add-prefix(transition, $transition);
                            object-fit: cover;

                            &:hover {
                                @include add-prefix(transform, scale(1.05));
                            }
                        }
                    }

                    &__content {
                        h4 {
                            @extend %text-semibold;
                        }

                        .designation {
                            @extend %mb-10;
                            // color: $text-color-light;
                        }

                        >span {
                            @extend %text-semibold;
                            color: $warning;

                            i,
                            svg {
                                color: $warning;
                            }
                        }

                        .info {
                            @extend %mt-25;
                        }
                    }
                }
            }

        }