.account {
  @extend %of-hidden,
  %p-rel;

  &__wrapper {
    @extend %p-rel;
    z-index: 2;
  }


  &__content {
    &--style1 {
      @extend %p-20;
      max-inline-size: 800px;
      margin-inline: auto;
      background-color: $account-bg-color;
      border-radius: 4px;

      @include breakpoint(sm) {
        padding: 50px;
      }

      @include breakpoint(lg) {
        padding: 100px;
      }
    }

    &--style2 {
      @extend %p-30;
      max-inline-size: 800px;
      margin-inline: auto;
      background-color: $account-bg-color2;
      border-radius: 4px;

      @include breakpoint(sm) {
        padding: 50px;
      }
      @include breakpoint(md) {
        padding: 60px;
      }

      @include breakpoint(lg) {
        padding: 25px 30px;
      }
    }
  }

  &__thumb{
    img{
      @extend %d-none;

      @include breakpoint(lg){
        display: block;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  &__inner {
    background-color: $account-bg-color2;
    border-radius: 4px;
    box-shadow: 0px -16px 48px rgba(6, 35, 63, 0.03), 0px 16px 48px rgba(6, 35, 63, 0.03);
  }

  &__header {
    @extend %mb-40;

    @include breakpoint(md) {
      margin-block-end: 50px;
    }

    h2 {
      @extend %mb-10;
    }

    p {
      @include font-size(16px);
    }
  }

  &__check {
    @extend %d-flex,
    %align-i-center,
    %justify-between,
    %mb-35,
    %mt-15;

    &-remember {

      input {
        width: 1.2em;
        height: 1.2em;
        margin-inline-end: 3px;

        &:focus {
          box-shadow: none;
        }

        &:checked {
          background-color: $brand-color;
          border-color: $brand-color;
        }
      }

      label {
        @extend %text-regular;
        @include font-size(16px);
        color: $title-color;
      }
    }

    &-forgot {
      a {
        @extend %text-semibold,
        %text-capitalize;
        @include font-size(16px);
        color: $secondary-color;
        text-decoration: underline;
      }
    }
  }


  &__social {

    &-btn {
      @include add-prefix(transition, $transition);
      @extend %w-100,
      %justify-center;
      @extend %text-regular;
      @include font-size(16px);
      display: inline-flex;
      background-color: $wh-color;
      color: $title-color;
      padding: 16px 24px;
      border: 1px solid $form-border;
      border-radius: .5rem;

      @include breakpoint(xl) {
        padding: 0.8rem 2.25rem;
      }

      span {
        @extend %me-10,
        %d-inline-block;
      }

      &:hover {
        border-color: $brand-color;
      }
    }
  }

  &__divider {

    &--style1 {
      @extend %p-rel,
      %mt-35,
      %pb-35;

      span {
        @extend %p-abs,
        %text-semibold;
        @include font-size(18px);
        color: $title-color;
        padding: 20px;
        top: -35px;
        left: 45%;
        background: $account-bg-color;


      }

      &:before {
        @extend %p-abs;
        content: "";
        height: 1px;
        width: 100%;
        left: 0;
        top: 0;
        background-color: $form-border;
      }
    }

    &--style2 {
      @extend %my-30;

      span {
        color: $text-color;

      }
    }
  }

  //toggle between signin and signup page
  &__switch {
    @extend %text-center,
    %mt-30;

    p {
      @extend %mb-0,
      %text-regular;
      @include font-size(16px);

      i,
      svg {
        @extend %me-5;
        @include add-prefix(transition, $transition);
      }
      a{
        @extend %text-regular;
        color: $secondary-color;
        text-decoration: underline;

        &.style2{
          @extend %text-regular;
          @include font-size(16px);
          color: $text-color;
        }
      }

    }

    a {
      @extend %ms-5,
      %d-inline-block,
      %text-bold;
      @include font-size(18px);

      span {
        color: $secondary-color;
        text-decoration: underline;
      }
    }

    &:hover {
      p {

        i,
        svg {
          @extend %me-10;
        }
      }
    }
  }


  //  shape
  &__shape {
    &-item {
      @extend %p-abs;

      &--1 {
        width: 30px;
        top: 9%;
        left: 77%;
        z-index: 2;
        opacity: 0.25;
        // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

        @include breakpoint(sm) {
          width: 55px;
          top: 11%;
          left: 66%;
        }

        @include breakpoint(md) {
          max-width: 75px;
          top: 11%;
          left: 56%;
        }

        @include breakpoint(lg) {
          max-width: 100%;
          top: 13%;
          left: 20%;
        }

        @include breakpoint(xl) {
          top: 9%;
          left: 8%;
        }

        @include breakpoint(xxl) {
          top: 9%;
          left: 12%;
        }

        @include breakpoint(xxxl) {
          //  top: 16%;
          left: 22%;
        }
      }
    }
  }
}