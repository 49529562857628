.banner--style1.seoPairs {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; // place it at the bottom
    height: 100px; // how tall you want the fade
    pointer-events: none;
    z-index: 0 !important; // ensure it sits on top of the images
    background: linear-gradient(transparent, var(--tertiary-color));
  }
}


.banner--style1 {
  position: relative;
  overflow: hidden;
  min-height: 80vh; // so background spans at least full viewport height
  max-height: 94vh;

  &.seoPairs {
    max-height: 80vh;
    min-height: 80vh;

    .banner--style3 {
      padding-top: 0px;
    }

    @media (max-width: 768px) {
      max-height: 100vh;
      min-height: 100vh;

      .banner--style3 {
        padding-top: 10px;
      }
    }
  }

  .hero-row-mg {
    margin-top: 6rem;
  }

  .skeleton-wrapper-cs {
    max-height: 75px;
  }


  /* When the viewport height is at least 600px, increase the min-height */






  /* When the viewport height is at least 900px, set the min-height to 122vh */
  @media (max-height: 900px) {
    min-height: 100vh;
  }

  /* When the viewport height is at least 800px, increase further */
  @media (max-height: 800px) {
    min-height: 110vh;
  }

  /* When the viewport height is at least 700px, increase the min-height further */
  @media (max-height: 700px) {
    min-height: 115vh;
  }

  @media (max-height: 600px) {
    min-height: 130vh;
  }

  @media (min-width: 300px) {



    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9999; // ensure it sits on top of the images
      background: linear-gradient(transparent, var(--tertiary-color));
    }
  }


  // -------------------------------
  // BACKGROUND LAYER
  // -------------------------------
  .layer-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    img {
      // e.g. "bg-img"
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  // -------------------------------
  // MOUNTAIN (MID) LAYER
  // -------------------------------
  .layer-mid {
    position: absolute;
    bottom: 4px;
    left: -39vw;
    z-index: 2;

    img {
      // e.g. "mountain-img"
      width: 120vw; // default
      height: auto;
      display: block;
    }
  }

  // -------------------------------
  // BULL (FORE) LAYER
  // -------------------------------
  .layer-fore {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;

    img {
      // e.g. "bull-img"
      width: 55vw; // default
      height: auto;
      display: block;
    }
  }

  // -------------------------------
  // CONTENT (ON TOP)
  // -------------------------------
  .content {
    position: relative; // ensure on top
    z-index: 5;
    color: #fff; // example text color
    padding: 2rem; // example padding
  }

  // =============================
  // MEDIA QUERIES
  // =============================
  @media (max-width: 1200px) {
    .layer-mid {
      img {
        width: 146vw;
      }
    }

    .layer-fore {
      img {
        width: 68vw;
      }
    }
  }

  @media (max-width: 992px) {
    .layer-mid {
      img {
        width: 150vw;
      }
    }

    .layer-fore {
      img {
        width: 80vw;
      }
    }
  }

  @media (max-width: 768px) {
    .layer-mid {
      img {
        width: 150vw;
      }
    }

    .layer-fore {
      img {
        width: 80vw;
      }
    }


    .banner__content h1 {
      text-align: center;
    }

    .hero-container {
      .extraM {
        margin-top: 4rem !important;
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(4px);
        padding: 15px;
      }

      .p-3 {
        background-color: #121a27a1 !important;
      }

      transition: top 0.2s ease-in-out;
      top: 0px;
      height: 79vh;
      transition: top 0.2s ease-in-out;
      min-height: 79vh;

      &.active {
        top: -45vh;

        h4.mb-3 {
          display: none;
        }
      }

    }

    .banner__content {
      -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(4px);
    }


  }

  @media (max-width:768px) {
    .banner__wrapper {
      .hero-row-mg {
        margin-top: 0rem;
      }
    }
  }

  @media (max-width: 300px) {
    background-color: #121a27;

    .hero-container {
      background-color: #121a27;
    }

    .layer-mid {
      img {
        width: 170vw;
      }
    }

    .layer-fore {
      img {
        width: 80vw;
      }
    }

    .hero-row-mg {
      margin-top: 1rem;
    }

    .mob-layers {
      min-height: 38vh;
      max-height: 38vh;
      top: -80px;
    }
  }

  .mob-layers {
    height: 90vh;
    position: absolute;
    top: 0px;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 98; // ensure it sits on top of the images
      background: linear-gradient(transparent, #121a27);
    }
  }

  .banner__wrapper {
    margin-top: 2rem;

    @media (max-width:768px) {
      margin-top: 3rem;
    }
  }

  .layer-bg-mob {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    img {
      // e.g. "bg-img"
      width: 100%;
      height: 90vh;
      object-fit: cover;
      display: block;
    }
  }

  // -------------------------------
  // MOUNTAIN (MID) LAYER
  // -------------------------------
  .layer-mid-mob {
    position: absolute;
    bottom: 4px;
    left: 0;
    z-index: 2;

    img {
      // e.g. "mountain-img"
      width: 120vw; // default
      height: auto;
      display: block;
    }
  }

  // -------------------------------
  // BULL (FORE) LAYER
  // -------------------------------
  .layer-fore-mob {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;

    img {
      // e.g. "bull-img"
      width: 100vw; // default
      height: auto;
      display: block;
    }
  }
}


.custom-modal {
  width: 700px;
  height: 700px;
  background-color: #121a27;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  .assetContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 50px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: transparent;

    &:hover {
      background-color: #ffffff1c;
    }
  }
}

.extra-tau {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  box-shadow: rgba(0, 0, 0, 0.8) 0px -1px 60px -8px;
  max-width: calc(638px);
  position: absolute;
  top: 5rem;
  left: 1rem;
  right: 1rem;
  bottom: 5rem;
  background-image: url('/images/logo/lowOp-small-logo-white.png');
  background-repeat: no-repeat;
  background-size: 1000px;
  background-position: 120px -360px;
  background-color: rgb(22, 28, 36);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(145, 158, 171, 0.24);
  border-image: initial;
  overflow: visible !important;
  margin: auto !important;
}

.input-group-text {
  cursor: pointer;
  position: relative;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  border: 0px solid #295247;
  border-left: 0px;
  min-width: 160px;
  align-items: center;
  justify-content: center;
  height: auto;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #121a27 !important;


  &:hover {
    background-color: rgba(0, 171, 85, 0.08) !important;
  }
}

.banner--style44 {


  @media (min-width: 300px) {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9; // ensure it sits on top of the images
      background: linear-gradient(var(--tertiary-color), transparent);
    }
  }
}

.banner--style45 {


  @media (min-width: 300px) {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9; // ensure it sits on top of the images
      background: linear-gradient(#121a27, transparent);
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9; // ensure it sits on top of the images
      background: linear-gradient(transparent, var(--tertiary-color));
    }
  }
}

.banner--style46 {


  @media (min-width: 300px) {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9; // ensure it sits on top of the images
      background: linear-gradient(var(--tertiary-color), transparent);
    }
  }
}

.banner--style47 {


  @media (min-width: 300px) {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9; // ensure it sits on top of the images
      background: linear-gradient(#121a27, transparent);
    }
  }
}

.banner--style48 {


  @media (min-width: 300px) {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9; // ensure it sits on top of the images
      background: linear-gradient(#121a27, transparent);
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9; // ensure it sits on top of the images
      background: linear-gradient(transparent, #121a27);
    }
  }
}



.banner--style49 {


  @media (min-width: 300px) {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9; // ensure it sits on top of the images
      background: linear-gradient(var(--tertiary-color), transparent);
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; // place it at the bottom
      height: 100px; // how tall you want the fade
      pointer-events: none;
      z-index: 9; // ensure it sits on top of the images
      background: linear-gradient(transparent, #121a27);
    }
  }
}