 .contact {
     @extend %p-rel,
     %of-hidden;

     &__wrapper {
         @extend %p-rel;
         z-index: 2;
     }

     &__social {
         @extend %mb-60;

         h3 {
             font-size: 34px;

             text-align: center;

             @include breakpoint(sm) {
                 text-align: left;
             }

             @extend %text-capitalize,
             %mb-40;

             span {
                 color: $secondary-color;
             }
         }
     }

     &__item {
         &:not(:last-child) {
             @extend %mb-30;
         }

         &-inner {
             @extend %d-flex,
             %align-i-center;
             gap: 24px;

         }

         &-thumb {
             span {
                 @extend %p-15,
                 %d-grid,
                 %place-i-center;
                 background-color: $tertiary-color2;
                 border-radius: 50%;
             }
         }

         &-content {
             p {
                 @extend %mb-0,
                 %text-semibold;
                 //  @include font-size(18px);
                 //  color: $text-color;

             }
         }
     }

     &__form {
         textarea {
             min-height: 250px;
         }

         // @include breakpoint(md){
         //     &::before {
         //         // @extend %p-abs;
         //         position: absolute;
         //         content: "";
         //         top: -6%;
         //         left: 38%;
         //         height: 100%;
         //         width: 100%;
         //         background: linear-gradient(188.07deg, rgba(237, 242, 248, 0) 31.91%, #EDF2F8 98.96%);
         //         border-radius: 100px;
         //         //  transform: matrix(0.99, -0.14, 0.15, 0.99, 0, 0);
         //         transform: rotate(-5deg);


         //     }
         // }

     }

     &__shape {
         &-item {
             @extend %p-abs;

             &--1 {
                 width: 50px;
                 top: 5%;
                 left: 71%;
                 z-index: 2;
                 //  @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                 @include breakpoint(sm) {
                     width: 70px;
                     top: 6%;
                     left: 65%;
                 }

                 @include breakpoint(md) {
                     max-width: 40px;
                     top: 4%;
                     left: 73%;
                 }

                 @include breakpoint(lg) {
                     max-width: 100%;
                     top: 80%;
                     left: 13%;
                 }

                 @include breakpoint(xl) {
                     top: 16%;
                     left: 32%;
                 }

                 @include breakpoint(xxl) {
                     top: 16%;
                     left: 36%;
                 }

                 @include breakpoint(xxxl) {
                     top: 16%;
                     left: 38%;
                 }
             }

             &--2 {
                 content: "";
                 height: 92%;
                 width: 920px;
                 border: 2px solid var(--bg-grad-color-2);
                 padding: 40px;
                 border-bottom-left-radius: 25%;
                 top: 0%;
                 right: -3%;
                 transform: rotate(-10deg);
                 opacity: 0.4;
                 display: none;

                 @include breakpoint(md) {
                     display: block;
                     height: 109%;
                     width: 570px;
                     top: -9%;
                     right: -5%;
                 }

                 @include breakpoint(lg) {
                     height: 109%;
                     width: 800px;
                     top: -9%;
                     right: -18%;
                 }

                 @include breakpoint(xl) {
                     width: 900px;
                     top: -15%;
                     right: -6%;
                 }

                 @include breakpoint(xxl) {
                     width: 1000px;
                     top: -15%;
                     right: -6%;
                 }

                 @include breakpoint(xxxl) {
                     width: 1200px;
                     top: -19%;
                     right: -2%;
                 }


                 span {
                     position: absolute;
                     content: "";
                     height: 96%;
                     width: 700px;
                     background: linear-gradient(219deg, var(--bg-grad-color-trans-2) 0%, var(--bg-grad-color-2) 100%);
                     //background: linear-gradient(219deg, var(--bg-grad-color-trans) 0%, var(--bg-grad-color) 100%);

                     border-bottom-left-radius: 25%;
                     top: 5%;
                     right: -1%;


                     @include breakpoint(md) {
                         height: 97%;
                         width: 550px;
                         top: 0%;
                         right: -1%;
                     }

                     @include breakpoint(lg) {
                         height: 97%;
                         width: 780px;
                         top: 0%;
                         right: -1%;
                     }

                     @include breakpoint(xl) {
                         width: 880px;
                         top: 0%;
                         right: -1%;
                     }

                     @include breakpoint(xxl) {
                         width: 980px;
                         top: 0%;
                         right: -1%;
                     }

                     @include breakpoint(xxxl) {
                         width: 1180px;
                         top: 0%;
                         right: -1%;
                     }
                 }
             }
         }
     }


 }