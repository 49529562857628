/* @@@@@@@@@@@@@  Extend Property CSS start here  @@@@@@@@@@@@@@ */


/*=========<<Flex property extends start>>=========*/
%d-flex {
  display: flex;
  flex-wrap: wrap;
}

%justify-center {
  justify-content: center;
}

%justify-between {
  justify-content: space-between;
}

%justify-start {
  justify-content: flex-start;
}

%justify-end {
  justify-content: flex-end;
}

%justify-around {
  justify-content: space-around;
}

%justify-evenly {
  justify-content: space-evenly;
}

%align-i-center {
  align-items: center;
}

%align-i-start {
  align-items: flex-start;
}

%align-i-end {
  align-items: flex-end;
}

%flex-col {
  flex-direction: column;
}

%flex-col-reverse {
  flex-direction: column-reverse;
}

%flex-row {
  flex-direction: row;
}

%flex-row-reverse {
  flex-direction: row-reverse;
}

//grid's properties
%place-i-center {
  place-items: center;
}
%text-capitalize{
  text-transform: capitalize;
}
%border-none{
  border: none;
}

/*=========<<Flex property extends end>>=========*/




/*=========<<Display property extends start>>=========*/

%d-none {
  display: none;
}

%d-inherit {
  display: inherit;
}

%d-initial {
  display: initial;
}

%d-block {
  display: block;
}

%d-inline {
  display: inline;
}

%d-flex {
  display: flex;
}

%d-grid {
  display: grid;
}

%d-table {
  display: table;
}

%d-inline-block {
  display: inline-block;
}

%d-inline-flex {
  display: inline-flex;
}

%d-inline-grid {
  display: inline-grid;
}

%d-inline-table {
  display: inline-table;
}

/*=========<<Display property extends end>>=========*/





/*=========<<Position property extends start>>=========*/
%p-rel {
  position: relative;
}

%p-abs {
  position: absolute;
}

%p-static {
  position: static;
}

%p-fixed {
  position: fixed;
}

%p-inherit {
  position: inherit;
}

%p-initial {
  position: initial;
}

%p-sticky {
  position: sticky;
}

%p-unset {
  position: unset;
}

%pos-center {
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
}

/*=========<<Position property extends end>>=========*/



/*=========<<Text property extends start>>=========*/

%text-start {
  text-align: start;
}

%text-center {
  text-align: center;
}

%text-end {
  text-align: end;
}

%text-left {
  text-align: left;
}

%text-right {
  text-align: right;
}

%text-justify {
  text-align: justify;
}

%text-initial {
  text-align: initial;
}

%text-inherit {
  text-align: inherit;
}

%text-thin {
  font-weight: 100;
}

%text-xlight {
  font-weight: 200;
}

%text-light {
  font-weight: 300;
}

%text-regular {
  font-weight: 400;
}

%text-medium {
  font-weight: 500;
}

%text-semibold {
  font-weight: 600;
}

%text-bold {
  font-weight: 700;
}

%text-bolder {
  font-weight: 800;
}

%text-heavy {
  font-weight: 900;
}

/*=========<<Text property extends end>>=========*/




/*=========<<Margin & padding property extends start>>=========*/

$space-stops: ('0': 0,
  '5': 5px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '25': 25px,
  '30': 30px,
  '35': 35px,
  '40': 40px,
  '45': 45px,
  '50': 50px,
  '55': 55px,
  '60': 60px,
  '65': 65px,
  '70': 70px,
  '75': 75px,
  '80': 80px,
  '85': 85px,
  '90': 90px,
  '95': 95px,
  '100': 100px,
);

@each $key,
$val in $space-stops {

  /*-------<< margin properties >>-------*/
  %m-#{$key} {
    margin: #{$val};
  }

  %mt-#{$key} {
    margin-block-start: #{$val};
  }

  %mb-#{$key} {
    margin-block-end: #{$val};
  }

  %ms-#{$key} {
    margin-inline-start: #{$val};
  }

  %me-#{$key} {
    margin-inline-end: #{$val};
  }

  %my-#{$key} {
    margin-block: #{$val};
  }

  %mx-#{$key} {
    margin-inline: #{$val};
  }
}


/*------<< Paddings >>---------*/
@each $key,
$val in $space-stops {

  %p-#{$key} {
    padding: #{$val};
  }

  %pt-#{$key} {
    padding-block-start: #{$val};
  }

  %pb-#{$key} {
    padding-block-end: #{$val};
  }

  %ps-#{$key} {
    padding-inline-start: #{$val};
  }

  %pe-#{$key} {
    padding-inline-end: #{$val};
  }

  %py-#{$key} {
    padding-block: #{$val};
  }

  %px-#{$key} {
    padding-inline: #{$val};
  }
}

/*=========<<Margin property extends end>>=========*/




/*=========<<overflow property extends start>>=========*/
%of-hidden {
  overflow: hidden;
}

%of-auto {
  overflow: auto;
}

%of-scroll {
  overflow: scroll;
}

%of-hidden {
  overflow: hidden;
}

%of-visible {
  overflow: visible;
}

%of-unset {
  overflow: unset;
}

/*=========<<overflow property extends end>>=========*/





/*=========<<Cursor property extends start>>=========*/
%c-pointer {
  cursor: pointer;
}

%c-move {
  cursor: move;
}

%c-auto {
  cursor: auto;
}

%c-disabled {
  cursor: not-allowed;
}

%c-grab {
  cursor: grab;
}

%c-grabbing {
  cursor: grabbing;
}

%c-custom {
  cursor: url(../images/custom-cursor.png),
    url(../images/custom-cursor.svg),
    auto;
}

/*=========<<Cursor property extends end>>=========*/



/*=========<< Height-Width property extends start>>=========*/
%w-0 {
  width: 0;
}

%w-25 {
  width: 25%;
}

%w-50 {
  width: 50%;
}

%w-75 {
  width: 75%;
}

%w-100 {
  width: 100%;
}


%h-0 {
  height: 0;
}

%h-25 {
  height: 25%;
}

%h-50 {
  height: 50%;
}

%h-75 {
  height: 75%;
}

%h-100 {
  height: 100%;
}

/*=========<< Height-Width property extends end>>=========*/






/*=========<< List Style property extends start>>=========*/
%list-none {
  list-style: none;
}

/*=========<< List Style property extends end>>=========*/


/* @@@@@@@@@@@@@  Extend Property CSS Writing Now  @@@@@@@@@@@@@@ */

%text-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  @extend %of-hidden;
      
}
%text-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  @extend %of-hidden;
      
}

%text-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @extend %of-hidden;
  text-overflow: ellipsis;
}

%text-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @extend %of-hidden;
  text-overflow: ellipsis;
}


/* @@@@@@@@@@@@@  aditional Property CSS Writing Now  @@@@@@@@@@@@@@ */
// just flex
%flex {
  display: flex;
}