.service {
    @extend %p-rel,
    %of-hidden;



    &__item {
        &-inner {
            @extend %p-rel,
            %py-35,
            %px-15;
            @include add-prefix(transition, $transition);
            border-radius: 16px;

            @include breakpoint(md) {
                padding-block: 52px;
                padding-inline: 32px;
            }
        }

        &-thumb {
            @extend %p-25,
            %d-inline-flex;
            border-radius: 99px;
            // background-color: $bg-color-2;
            background-color: $wh-color;
            box-shadow: 0px 8px 24px rgba(12, 52, 91, 0.04);
        }

        &-content {
            h5 {
                @extend %text-medium;
                @include add-prefix(transition, $transition);
            }
        }


        &--style1 {
            .service__item {
                &-inner {
                    background: linear-gradient(152deg, var(--bg-color-trans) 0%, var(--bg-color) 100%);
                    border: 2px solid $service-item-border-color;
                    box-shadow: 0 0 10px #0cd66e;

                    @media (max-width: 768px) {
                        margin: auto;
                        max-width: 277px;
                    }

                }
            }

            &:hover {
                .service__item {
                    &-inner {
                        transform: translateY(-5px);
                        border: 2px solid $brand-color!important;
                    }

                    &-thumb {
                        background-color: $wh-color;
                    }

                    &-content {
                        h5 {
                            a {
                                color: $title-color;
                            }
                        }
                    }
                }
            }
        }

        &--style2 {
            .service__item {
                &-inner {
                    background: $service-item-bg-color;
                    border: 2px solid transparent;
                }

                &-content {
                    h5 {
                        @extend %text-semibold;
                        @include add-prefix(transition, $transition);
                    }
                }
            }

            &:hover {
                .service__item {
                    &-inner {
                        transform: translateY(-5px);
                        border: 2px solid $brand-color;
                    }

                    &-thumb {
                        background-color: $wh-color;
                    }

                    &-content {
                        h5 {
                            a {
                                color: $title-color;
                            }
                        }
                    }
                }
            }
        }

        &--style3 {
            .service__item {

                &-inner {
                    @include add-prefix(transition, $transition);
                    background-color: $service-item-bg-color;
                }

                &-thumb {
                    @include add-prefix(transition, $transition);
                    background-color: $bg-color-10;
                }

                &-content {
                    h5 {
                        @extend %text-semibold;
                    }
                }
            }

            &:hover {
                .service__item {
                    &-inner {
                        transform: translateY(-5px);
                        background-color: $bg-color-10;
                    }

                    &-thumb {
                        background-color: $thm-wh-color;
                    }

                    &-content {
                        h5 {
                            a {
                                color: $title-color;
                            }
                        }
                    }
                }
            }

        }



    }




    // shape
    &__shape {

        &-item {
            @extend %p-abs;

            &--1 {
                width: 40px;
                top: 1%;
                left: 6%;
                opacity: 0.25;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 3%;
                    left: 7%;
                }

                @include breakpoint(md) {
                    width: auto;
                    top: 2%;
                    left: 1%;
                }

                @include breakpoint(lg) {
                    left: 4%;
                    top: 4%;
                }

                @include breakpoint(xl) {
                    top: 5%;
                    left: 5%;
                }

                @include breakpoint(xxl) {
                    top: 8%;
                    left: 5%;
                }
            }


            // home 3
            &--2 {
                @extend %p-abs;
                width: 40px;
                top: 1%;
                left: 6%;
                opacity: 0.25;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 3%;
                    left: 7%;
                }

                @include breakpoint(md) {
                    width: 70px;
                    top: 6%;
                    left: 4%;
                }

                @include breakpoint(lg) {
                    width: auto;
                    left: 5%;
                    top: 10%;
                }

                @include breakpoint(xl) {
                    top: 10%;
                    left: 9%;
                }

                @include breakpoint(xxl) {
                    top: 11%;
                    left: 17%;
                }
            }

            &--3 {

                top: 0.5%;
                right: 2%;
                width: 50px;
                opacity: 0.25;
                // @include animation(rotate 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    width: 70px;
                    top: 2%;
                    right: 8%;
                }

                @include breakpoint(md) {
                    width: auto;
                    top: 2%;
                    right: 8%;
                }

                @include breakpoint(lg) {
                    top: auto;
                    bottom: 3%;
                    right: 4%;
                }

                @include breakpoint(xl) {
                    bottom: 4%;
                    right: 4%;
                }

                @include breakpoint(xxl) {
                    bottom: 4%;
                    right: 4%;
                }

                @include breakpoint(xxxl) {
                    bottom: 6%;
                    right: 13%;
                }
            }

        }

    }

}
