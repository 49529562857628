// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
//default button style
.trk-btn {
  @extend %p-rel,
  %d-inline-block,
  %of-hidden,
  %text-center,
  %text-semibold;
  @include font-size(16px);
  border: transparent;
  color: $thm-title-color;
  font-family: $text-font;
  padding: 0.75rem 1rem;
  text-decoration: none;
  @include add-prefix(transition, $transition);
  text-transform: capitalize;
  line-height: 1;
  border-radius: 8px;


  &:after {
    @extend %p-abs;
    background: $bg-white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }


  @include breakpoint(sm) {
    padding: 1rem 1.25rem;
  }

  @include breakpoint(lg) {
    padding: 1rem 1.75rem;
  }

  @include breakpoint(xl) {
    padding: 1rem 1.875rem;
  }

  &--small {
    padding: 0.75rem 1.5rem;
  }

  &--large {
    @include breakpoint(sm) {
      padding: 0.95rem 1.25rem;
    }

    @include breakpoint(lg) {
      padding: 1.25rem 1.875rem;
    }
  }

  svg,
  i {
    @extend %me-5;
    transition: $transition;
  }

  &--arrow {
    span {

      i,
      svg {
        @include font-size(16px);
        // @include add-prefix(transform,rotate(-45deg));
      }

      &.style2 {

        i,
        svg {
          @include font-size(18px);
        }
      }
    }
  }

  &--defult {
    @extend %text-center,
    %d-inline-flex,
    %align-i-center;
    gap: 5px;
    @include add-prefix(transition, $transition);
    color: $title-color;
    padding: 0.53rem 1rem;

    @include breakpoint(sm) {
      padding: 0.8rem 1rem;
    }

    @include breakpoint(lg) {
      padding: 0.81rem 1rem;
    }

    @include breakpoint(xl) {
      padding: 0.82rem 1rem;
    }

    span {
      &.style1 {

        i,
        svg {
          @include add-prefix(transition, $transition);

          rect {
            stroke: $title-color;
          }

          g {
            path {
              stroke: $title-color;
            }
          }
        }
      }
    }
  }

  &--arrowplay {
    @extend %d-inline-flex,
    %align-i-center;
    gap: 5px;

    padding: 0.53rem 1rem;

    @include breakpoint(sm) {
      padding: 0.8rem 1.25rem;
    }

    @include breakpoint(lg) {
      padding: 0.8rem 1.75rem;
    }

    @include breakpoint(xl) {
      padding: 0.78rem 1.875rem;
    }

    span {

      i,
      svg {
        @include font-size(20px);
      }


    }

  }

  &--left {
    @extend %d-inline-flex,
    %align-i-center;
    gap: 8px;

    span {
      @extend %d-inline-flex,
      %align-i-center;
      @include box(24px);
      background-color: $bg-white;
      border-radius: 99px;
      padding: 4px;

      i,
      svg {
        @include font-size(14px);
        color: $brand-color;
        line-height: 24px;
      }
    }
  }

  &--primary {
    background-color: $brand-color;
    border: 1px solid $brand-color;
  }

  &--tertiary {
    background-color: $tertiary-color4;
    border: 1px solid $tertiary-color4;
    color: $thm-title-color-dark;
  }

  &--secondary {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: $wh-color;
  }

  &--secondary2 {
    background-color: $thm-secondary-color;
    border: 1px solid $thm-secondary-color;
    color: $bg-white;
  }

  &--secondary3 {
    background-color: $bg-color-11;
    border: 1px solid $bg-color-11;
    color: $bg-white;
  }

  &--outline {
    @extend %text-center;
    @include add-prefix(transition, $transition);
    width: 100%;
    border: 1px solid $brand-color;
    color: $thm-title-color;
  }

  &--outline2 {
    @extend %text-center,
    %d-inline-flex,
    %align-i-center;
    @include add-prefix(transition, $transition);
    gap: 5px;
    // width: 100%;
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 0.53rem 1rem;

    @include breakpoint(sm) {
      padding: 0.8rem 1.25rem;
    }

    @include breakpoint(lg) {
      padding: 0.8rem 1.75rem;
    }

    @include breakpoint(xl) {
      padding: 0.78rem 1.875rem;
    }

    span {
      &.style1 {

        i,
        svg {
          @include add-prefix(transition, $transition);

          rect {
            stroke: $arrow-icon-color;
          }

          g {
            path {
              stroke: $arrow-icon-color;
            }
          }
        }
      }
    }


  }

  &--outline22 {
    @extend %text-center,
    %d-inline-flex,
    %align-i-center;
    @include add-prefix(transition, $transition);
    gap: 5px;
    // width: 100%;
    color: white;
    padding: 0.53rem 1rem;
    cursor: pointer;

    @include breakpoint(sm) {
      padding: 0.8rem 1.25rem;
    }

    @include breakpoint(lg) {
      padding: 0.8rem 1.75rem;
    }

    @include breakpoint(xl) {
      padding: 0.78rem 1.875rem;
    }

    &:hover {
      border-radius: 50px;
    }

    span {
      &.style1 {

        i,
        svg {
          @include add-prefix(transition, $transition);

          rect {
            // stroke: $arrow-icon-color;
            stroke: $secondary-color;
          }

          g {
            path {
              stroke: $secondary-color;
            }
          }
        }
      }
    }
  }

  &--outline3 {
    @extend %text-center,
    %d-inline-flex,
    %align-i-center;
    @include add-prefix(transition, $transition);
    gap: 5px;
    // width: 100%;
    // background-color: $thm-wh-color-dark;
    border-color: $thm-wh-color-dark;
    background: rgba(255, 255, 255, 0.08);
    color: $thm-wh-color;
    padding: 0.53rem 1rem;

    @include breakpoint(sm) {
      padding: 0.8rem 1.25rem;
    }

    @include breakpoint(lg) {
      padding: 0.8rem 1.75rem;
    }

    @include breakpoint(xl) {
      padding: 0.78rem 1.875rem;
    }

    span {
      &.style1 {

        i,
        svg {
          @include add-prefix(transition, $transition);

          // rect {
          //   stroke: $arrow-icon-color;
          // }

          // g {
          //   path {
          //     stroke: $arrow-icon-color;
          //   }
          // }
        }
      }
    }

  }

  &--outline4 {
    @extend %text-center;
    @include add-prefix(transition, $transition);
    width: 100%;
    border: 1px solid $secondary-color;
    color: $secondary-color;

    &.active,
    &:hover {
      background-color: $secondary-color;
      color: $bg-white;
    }
  }

  &--outline5 {
    @extend %text-center,
    %d-inline-flex,
    %align-i-center;
    @include add-prefix(transition, $transition);
    gap: 5px;
    // width: 100%;
    // border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 0.53rem 1rem;

    @include breakpoint(sm) {
      padding: 0.8rem 1.25rem;
    }

    @include breakpoint(lg) {
      padding: 0.8rem 1.75rem;
    }

    @include breakpoint(xl) {
      padding: 0.78rem 1.875rem;
    }

    span {
      &.style1 {

        i,
        svg {
          @include add-prefix(transition, $transition);

          rect {
            stroke: $arrow-icon-color;
          }

          g {
            path {
              stroke: $arrow-icon-color;
            }
          }
        }
      }
    }


  }

  &--outline6 {
    @extend %text-center;
    @include add-prefix(transition, $transition);
    width: 100%;
    border: 1.5px solid $title-color;
    color: $title-color;
  }



  &.search-btn {
    padding: 1.2rem 1.85rem;
    @include font-size(18px);
  }



  span {
    position: inherit;
    z-index: 1;

    i,
    svg,
    img {
      @extend %ms-5;
    }
  }


  // &--small {
  //   // line-height: 46px;
  //   padding: 1.125rem 2.5rem;
  // }



  &.active,
  &:hover {

    // Home One
    &.trk-btn--primary {
      color: $thm-title-color;
      transform: translateY(-3px);

      &:after {
        left: 120%;
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    &.trk-btn--secondary {
      color: $bg-white;
      transform: translateY(-3px);

      &:after {
        left: 120%;
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    &.trk-btn--secondary2 {
      color: $bg-white;
      transform: translateY(-3px);

      &:after {
        left: 120%;
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    &.trk-btn--secondary3 {
      color: $bg-white;
      transform: translateY(-3px);

      &:after {
        left: 120%;
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    &.trk-btn--tertiary {
      color: $thm-title-color-dark;
      transform: translateY(-3px);

      &:after {
        left: 120%;
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    &.trk-btn--arrow {
      transform: rotate(0deg);
    }

    &.trk-btn--outline {
      background-color: $brand-color;
      border-color: $brand-color;
      color: $thm-title-color;
    }

    &.trk-btn--outline2 {
      background-color: $brand-color;
      border-color: $brand-color;
      color: $thm-title-color;

      span {
        &.style1 {

          i,
          svg {
            rect {
              stroke: $thm-title-color;

            }

            g {
              path {
                stroke: $thm-title-color;
              }
            }
          }
        }
      }
    }

    &.trk-btn--outline22 {
      background-color: $thm-secondary-color;
      border-color: $thm-secondary-color;
      color: $bg-white;

      span {
        &.style1 {

          i,
          svg {
            rect {
              stroke: $bg-white;

            }

            g {
              path {
                stroke: $bg-white;
              }
            }
          }
        }
      }
    }

    &.trk-btn--defult {
      background-color: $brand-color;
      border-color: $brand-color;
      color: $thm-title-color;

      span {
        &.style1 {

          i,
          svg {
            rect {
              stroke: $thm-title-color;

            }

            g {
              path {
                stroke: $thm-title-color;
              }
            }
          }
        }
      }
    }

    &.trk-btn--outline3 {
      background-color: $brand-color;
      border-color: $brand-color;
      color: $thm-title-color;

      span {
        &.style1 {

          i,
          svg {
            rect {
              stroke: $thm-title-color;

            }

            g {
              path {
                stroke: $thm-title-color;
              }
            }
          }
        }
      }
    }

    &.trk-btn--outline6 {
      background-color: $title-color;
      border-color: $title-color;
      color: $thm-title-color-dark;
    }


    // Home Two
    &.trk-btn--primary2 {

      i,
      svg {
        @include add-prefix(transform, translateX(5px));
      }
    }


  }

  &-active {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }

}


.text-btn {
  @extend %align-i-center,
  %p-rel,
  %text-capitalize;
  @include add-prefix(transition, $transition);
  display: inline-flex;
  font-weight: 600;
  color: $brand-color;

  &::after {
    @extend %p-abs;
    @include add-prefix(transition, $transition);
    content: "";
    left: 0;
    bottom: -4px;
    height: 2px;
    width: 0%;
    background-color: $brand-color;

  }

  &--style2 {
    color: $secondary-color;

    &::after {
      @extend %p-abs;
      @include add-prefix(transition, $transition);
      content: "";
      left: 0;
      bottom: -4px;
      height: 2px;
      width: 0%;
      background-color: $secondary-color;
    }
  }


  i,
  svg {
    @include add-prefix(transition, $transition);
    @extend %ms-10;
  }


  &.text-btn--small {
    @extend %text-regular;
    @include font-size(16px);
    color: $title-color;
    font-family: $text-font;

    span {
      @extend %me-10;
      @include add-prefix(transition, $transition);
    }
  }

  span {
    @extend %ms-10;
    @include add-prefix(transition, $transition);
  }

  &:hover {
    color: $secondary-color;

    &::after {
      width: 100%;
      background-color: $secondary-color;
    }

    i,
    svg {
      @extend %ms-15;
    }

    span {
      @include add-prefix(transform, translateX(5px));
    }
  }
}


.btn-group {
  @extend %d-flex,
  %align-i-center;
  gap: 20px;

  &--gap-xs {
    gap: 20px;
  }

  &--gap-sm {
    gap: 20px;
  }

  &--gap-md {
    gap: 20px;
  }

  &--gap-lg {
    gap: 20px;
  }
}