// Coin Picker Widget Styles

// Main container
.coinpicker-container {
    color: rgb(255, 255, 255);
    background-image: none;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgb(0 0 0 / 39%) 0px 12px 24px -4px;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    max-width: 560px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    touch-action: manipulation; // Disable double tap zoom
    justify-content: center;
    max-height: 350px;
}

.coinpicker-container-extra {
    background-color: #121a27a1;
}

// Form labels and input wrappers
.coinpicker-form-label {
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    position: absolute;
    top: -12px;
    left: 12px;
    background-color: rgb(24 36 50);
    padding: 0px 4px;
    z-index: 9;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.coinpicker-input-wrapper {
    position: relative;

    .form-control:focus {
        background-color: transparent;
    }
}

// Input group
.coinpicker-input-group {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-width: 1px 0px 1px 1px;
    border-top-style: solid;
    border-right-style: initial;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #295247;
    border-right-color: initial;
    border-bottom-color: #295247;
    border-left-color: #295247;
    border-image: initial;
    appearance: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    touch-action: manipulation; // Disable double tap zoom
    min-height: 77px;
}

// Input container
.coinpicker-input-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 70%;
}

// Amount input
.coinpicker-amount-input {
    width: 100%;
    background-color: transparent;
    height: 50px;
    border: 0px;
    padding: 0px 0px 0px 13px;
    font-size: 30px;
    border-top-left-radius: 16px;
}

// USD value input
.coinpicker-usd-input {
    width: 100%;
    background-color: transparent;
    border: 0px;
    text-align: left;
    z-index: 9999;
    bottom: 0px;
    left: 23px;
    font-size: 13px;
    color: grey;
    padding: 0px 0px 0px 15px;
    margin-top: -5px;
}

// Skeleton wrapper
.coinpicker-skeleton-wrapper {
    width: 100%;
    height: 30px;
    margin-top: -5px;
}

.coinpicker-receive-skeleton {
    width: 100%;
    top: 0px;
    height: 75px;
    border-top-left-radius: 17px !important;
    border-bottom-left-radius: 17px !important;
    border-right: 0px;
    border: 0px;
}

// Coin icon container
.coinpicker-coin-icon-container {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 8px;
}

// Coin image style
.coinpicker-coin-image {
    object-fit: contain;
    border-radius: 100%;
}

// Network badge container
.coinpicker-network-badge-container {
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid rgb(18, 26, 39);
    background-color: #121a27;
    overflow: hidden;
}

// Network badge image
.coinpicker-network-badge {
    object-fit: contain;
    margin-top: -11px;
}

// Coin info text container
.coinpicker-coin-info {
    line-height: 1;
    text-align: left;
}

// Coin symbol text
.coinpicker-coin-symbol {
    color: white;
    font-size: 23px;
}

// Dropdown icon
.coinpicker-dropdown-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    padding-left: 0px;
    margin-right: -15px;
}

// Swap button
.coinpicker-swap-button {
    position: relative;
    background-color: #212b36;
    border: 0px;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    padding: 5px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    touch-action: manipulation; // Disable double tap zoom
    margin-bottom: 8px !important;
    margin-top: -10px;
}

// Modal overlay
.coinpicker-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    backdrop-filter: blur(6px);
    background-color: rgba(0, 0, 0, 0.25);
    touch-action: manipulation; // Disable double tap zoom
}

// Modal content
.coinpicker-modal-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    padding: 15px 0px 15px 0px;
    border-radius: 16px;
    width: 80%;
    max-height: 650px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgb(0 0 0 / 39%) 0px 12px 24px -4px;
    color: rgb(255, 255, 255);
    max-width: calc(638px);
    background-color: rgb(18 26 39 / 90%);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);

    overflow: visible !important;
    touch-action: manipulation; // Disable double tap zoom

    // Responsive styles for tablet and mobile
    @media (max-width: 768px) {
        flex-direction: column;
        width: 90%;
        margin-top: 70px;
        max-height: 80vh;
        background-position: -20% 107%;
        background-size: 800px;
        height: 80vh;
    }

    @media (max-width: 576px) {
        width: 100%;
        padding: 10px 0;
        margin-top: 5vh;
        max-height: 90vh;
        background-position: 17% 220%;
        background-size: 1000px;
        height: 90vh;
    }
}

// Modal close button container
.coinpicker-modal-close-container {
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0px;
    display: flex;
    justify-content: center;
    transition: top 300ms ease-in-out;

    @media (max-width: 768px) {
        top: -50px;
    }

    @media (max-width: 576px) {
        top: -45px;
    }
}

.closeVisible {
    top: -80px;
}

// Modal close button
.coinpicker-modal-close-button {
    border-width: 0;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    background-color: rgb(18 26 39 / 90%);
    padding: 24px;
    cursor: pointer;
    touch-action: manipulation; // Disable double tap zoom

    @media (max-width: 768px) {
        width: 60px;
        height: 60px;
        padding: 20px;
    }

    @media (max-width: 576px) {
        width: 50px;
        height: 50px;
        padding: 15px;
    }
}

// Modal close text
.coinpicker-modal-close-text {
    font-size: 22px;
    border-width: 0;
    border-radius: 100%;
    color: white;
}

// Network container
.coinpicker-network-container {
    overflow-y: auto;
    border-right: 1px solid #2d3947;
    width: 40%;
    max-height: 70vh;
    padding: 0 15px;

    @media (max-width: 768px) {
        width: 100%;
        max-height: 30vh;
        border-right: none;
        border-bottom: 1px solid #2d3947;
        padding: 0 10px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;
        touch-action: pan-x; // Allow horizontal scrolling but disable double tap zoom
    }

    @media (max-width: 576px) {
        max-height: 25vh;
        padding: 0 5px 10px;
    }
}

// Network items
.coinpicker-all-networks-selected {
    height: 50px;
    cursor: pointer;
    background: rgb(18, 23, 30);
    background-color: rgba(13, 17, 22, 0.41);
    color: rgb(255, 255, 255);
    border: 1px inset rgba(13, 17, 22, 0.41);
    border-radius: 8px;
    padding: 0 0 0 8.7px;
    display: flex;
    align-items: center;
    width: 95%;

    @media (max-width: 768px) {
        min-width: 100px;
        width: auto;
        margin-right: 8px;
        flex-shrink: 0;
        padding: 0px 0px;
        justify-content: center;
        height: 40px;
    }
}

.coinpicker-all-networks {
    display: flex;
    align-items: center;
    height: 50px;
    cursor: pointer;
    background: transparent;
    padding: 0 10px;
    border-radius: 8px;
    margin: 0px 0px 5px 0px;
    font-weight: normal;

    @media (max-width: 768px) {
        min-width: 100px;
        width: auto;
        margin: 0px 0px 0px 0px;
        flex-shrink: 0;
        padding: 0px 0px;
        justify-content: center;
        height: 40px;
    }
}

.coinpicker-network-item-selected {
    height: 40px;
    cursor: pointer;
    background: rgb(18, 23, 30);
    background-color: rgba(13, 17, 22, 0.41);
    color: rgb(255, 255, 255);
    border: 1px inset rgba(13, 17, 22, 0.41);
    border-radius: 8px;
    padding: 0 0 0 8.7px;
    display: flex;
    align-items: center;
    width: 95%;

    @media (max-width: 768px) {
        min-width: 100px;
        width: auto;
        margin-right: 8px;
        flex-shrink: 0;
        padding: 0 0px;
        justify-content: center;
        margin: 0px;
    }
}

.coinpicker-network-item {
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    background: transparent;
    padding: 0 10px;
    border-radius: 8px;
    margin: 5px 5px 5px 0px;
    font-weight: normal;

    &:hover {
        background-color: #1f2c3f;
    }

    @media (max-width: 768px) {
        min-width: 100px;
        width: auto;
        margin-right: 8px;
        flex-shrink: 0;
        padding: 0 0px;
        justify-content: center;
        margin: 0px;
    }
}

.coinpicker-network-logo {
    object-fit: contain;
    border-radius: 50%;
}

.coinpicker-network-text {
    margin-left: 10px;
    color: white;

    @media (max-width: 576px) {
        font-size: 14px;
    }
}

.coinpicker-network-subtext {
    margin-left: 10px;
    color: rgb(145, 158, 171);
    font-size: 11px;

    @media (max-width: 576px) {
        font-size: 10px;
    }
}

// Assets container
.coinpicker-assets-container {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    width: 60%;
    padding: 0 15px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 10px 10px 0;
    }

    @media (max-width: 576px) {
        padding: 5px 10px 0;
        height: 100%;
    }
}

// Search container
.coinpicker-search-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
        margin-bottom: 5px;
    }
}

// Search input wrapper
.coinpicker-search-input-wrapper {
    width: 100%;
    position: relative;
}

// Search input
.coinpicker-search-input {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: rgba(13, 17, 22, 0.41);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px inset, rgb(13, 17, 22) 0px 25px 15px -12px;
    color: rgb(255, 255, 255);
    z-index: 9999999;
    position: relative;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 5px 0px 0px 10px;
    border-radius: 8px;
    border-width: 1px;
    border-style: inset;
    border-color: rgba(13, 17, 22, 0.41);
    border-image: initial;
    touch-action: manipulation; // Disable double tap zoom
}

// Token count label
.coinpicker-token-count {
    position: absolute;
    right: 10px;
    top: 5px;
    border-left: 1px solid white;
    padding-left: 10px;
    height: 40px;
    padding-top: 7px;
    font-size: 15px;
    font-weight: 300;
    color: white;
    width: 100px;

    @media (max-width: 576px) {
        height: 35px;
        padding-top: 5px;
        font-size: 12px;
        width: 80px;
    }
}

// Asset list scrollable container
.coinpicker-scroll-container {
    flex: 1 1 60%;
    overflow-y: auto;
    height: 100%;
    padding-top: 10px;
    overflow-x: hidden;
    max-height: 65vh;
    touch-action: pan-y; // Allow vertical scrolling but disable double tap zoom

    @media (max-width: 768px) {
        max-height: 70vh;
        height: 70vh;
        min-height: 70vh;
    }

    @media (max-width: 576px) {
        max-height: 35vh;
        padding-top: 5px;
        max-height: 75vh;
        height: 75vh;
        min-height: 75vh;
    }
}

// Fade-in animation for assets
.coinpicker-fade-in {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.coinpicker-fade-out {
    opacity: 0;
}

// Asset item in dropdown
.coinpicker-asset-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 50px;
    touch-action: manipulation; // Disable double tap zoom

    &:hover {
        background-color: #1f2c3f;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    @media (max-width: 576px) {
        height: 55px;
    }
}

// Asset item icon container
.coinpicker-asset-icon-container {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
}

// Asset item coin image
.coinpicker-asset-image {
    margin: auto;
    object-fit: contain;
    border-radius: 100%;
}

// Asset item fallback icon
.coinpicker-asset-fallback-icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #121a27;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
}

// Asset item network badge
.coinpicker-asset-network-badge {
    position: absolute;
    bottom: 4px;
    right: -4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid rgb(18, 26, 39);
    background-color: #121a27;
    overflow: hidden;
}

// Asset item network badge image
.coinpicker-asset-network-badge-img {
    object-fit: contain;
    margin-top: -14px;
}

// Asset item text container
.coinpicker-asset-text {
    line-height: 1;
    text-align: left;
    margin-left: 10px;
    width: 80%;
    color: white;
}

// Asset item name container
.coinpicker-asset-name {
    line-height: 1;
    text-align: right;
    width: 150px;
    padding-right: 10px;

    @media (max-width: 576px) {
        width: 170px;
        font-size: 20px;
    }
}

// Custom skeleton styles
.coinpicker-skeleton-custom {
    width: 70%;
    height: 15px;
    left: 15px;
    border-radius: 4px;
    --base-color: #182432;
    --highlight-color: #344053;
}

// Javascript-generated fallback icon
.coinpicker-fallback-icon-js {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #344456;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
}

// Import coinpicker styles
.coinpicker-import {
    content: "This is a placeholder to ensure the coinpicker stylesheet is imported";
}

// Placeholder styles
.coinpicker-placeholder {
    // Add future styles here
}

// Mobile display helper classes
.desktop-only-text {
    display: inline;

    @media (max-width: 768px) {
        display: none;
    }
}

.mobile-only-text {
    display: none;

    @media (max-width: 768px) {
        display: inline;
    }
}

// Global touch-action for entire coinpicker component
// This ensures all child elements inherit the touch-action property
*[class^="coinpicker-"] {
    touch-action: manipulation;
}