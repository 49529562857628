.preloader {
    @extend %of-hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center; 
    background-color: $body-color;
    z-index: 99999;

    img {
        @include animation(floating 1s cubic-bezier(.4, -0.35, .01, 1.08) infinite alternate);
    }
}