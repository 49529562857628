.social {
    @extend %d-flex;
    @extend %align-i-center;
    @extend %list-none;
    @extend %ps-0;
    @extend %mb-0;
    gap: 10px;

    @include breakpoint(md) {
        gap: 15px;
    }

    &--style2 {
        gap: 25px;
    }

    &__item {
        h6 {
            @include font-size(18px);
            font-family: $text-font;
        }
    }

    &__link {
        // @extend %d-inline-block;
        @extend %text-center;
        @include add-prefix(transition, background-color .2s linear);
        background-color: transparent;
        // border: 2px solid rgba($wh-color, $alpha: .70);
        @include font-size(18px);


        &--style1 {
            @extend %d-grid,
            %place-i-center;
            @include add-prefix(transition, $transition);
            background-color: transparent;
            border: 1px solid $social-border-color-2;
            @include box(34px);
            border-radius: 99px;
            transition: $transition;

            svg,
            i {
                @include font-size(16px);
                color: $title-color;
            }


            @include breakpoint(lg) {
                height: 40px;
                width: 40px;

                i,
                svg {
                    @include font-size(18px)
                }
            }
        }

        &--style2 {
            @extend %d-grid,
            %place-i-center;
            @include add-prefix(transition, $transition);
            background-color: transparent;
            @include box(34px);
            border-radius: 99px;
            // border: 2px solid rgba(255, 255, 255, 0.1);
            border: 2px solid $social-border-color-3;

            @include breakpoint(md) {
                @include box(40px);
            }

            svg,
            i {
                color: $title-color;
                @include add-prefix(transition, $transition);
                @include font-size(16px);

                @include breakpoint(md) {
                    @include font-size(18px);
                }
            }
        }


        &--style22 {
            @extend %d-grid,
            %place-i-center;
            @include add-prefix(transition, $transition);
            background-color: transparent;
            @include box(34px);
            border-radius: 99px;
            border: 2px solid rgba(255, 255, 255, 0.1);
            // border: 2px solid $social-border-color-3;

            @include breakpoint(md) {
                @include box(40px);
            }

            svg,
            i {
                color: $bg-white;
                @include add-prefix(transition, $transition);
                @include font-size(16px);

                @include breakpoint(md) {
                    @include font-size(18px);
                }
            }
        }

        &--style222 {
            @extend %d-grid,
            %place-i-center;
            @include add-prefix(transition, $transition);
            background-color: transparent;
            @include box(34px);
            border-radius: 99px;
            border: 2px solid rgba(255, 255, 255, 0.1);
            // border: 2px solid $social-border-color-3;

            @include breakpoint(md) {
                @include box(40px);
            }

            svg,
            i {
                color: $bg-white;
                @include add-prefix(transition, $transition);
                @include font-size(16px);

                @include breakpoint(md) {
                    @include font-size(18px);
                }
            }
        }

        &--style3 {
            @extend %d-grid,
            %place-i-center,
            %p-rel;
            z-index: 3;
            @include add-prefix(transition, $transition);
            background-color: transparent;
            border: 1.3px solid $social-border-color;
            @include font-size(16px);
            @include box(32px);
            line-height: 32px;
            border-radius: 99px;
            transition: $transition;

            svg,
            i {
                color: $title-color;
                @include add-prefix(transition, $transition);
                font-size: inherit;
            }
        }

        &--style5 {
            @extend %d-grid,
            %place-i-center;
            @include add-prefix(transition, $transition);
            background-color: transparent;
            @include box(34px);
            border-radius: 99px;
            border: 2px solid rgba(255, 255, 255, 0.1);

            @include breakpoint(md) {
                @include box(40px);
            }

            svg,
            i {
                color: $bg-white;
                @include add-prefix(transition, $transition);
                @include font-size(16px);

                @include breakpoint(md) {
                    @include font-size(18px);
                }
            }
        }

        &--style6 {
            @extend %d-grid,
            %place-i-center;
            @include add-prefix(transition, $transition);
            background-color: transparent;
            @include box(34px);
            border-radius: 99px;
            border: 2px solid $tertiary-color;

            @include breakpoint(md) {
                @include box(40px);
            }

            svg,
            i {
                color: $title-color;
                @include add-prefix(transition, $transition);
                @include font-size(16px);

                @include breakpoint(md) {
                    @include font-size(18px);
                }
            }

            &.active,
            &:hover {
                // border-color: $brand-color;

                svg,
                i {
                    color: $brand-color;
                }
            }
        }

        &--style4 {
            @extend %d-grid,
            %place-i-center;
            @include add-prefix(transition, $transition);
            background-color: transparent;
            @include box(34px);
            border-radius: 99px;
            border: 2px solid $tertiary-color2;

            @include breakpoint(md) {
                @include box(40px);
            }

            svg,
            i {
                color: $title-color;
                @include add-prefix(transition, $transition);
                @include font-size(16px);

                @include breakpoint(md) {
                    @include font-size(18px);
                }
            }

            &.active,
            &:hover {
                border-color: $brand-color;

                svg,
                i {
                    color: $brand-color;
                }
            }
        }


        &.active,
        &:hover {

            &.social__link--style1 {
                background-color: $brand-color;
                border-color: $brand-color;

                svg,
                i {
                    color: $thm-wh-color-dark;
                }

            }


            &.social__link--style2 {
                border-color: $brand-color;

                svg,
                i {
                    color: $brand-color;
                }

            }

            &.social__link--style22 {
                border-color: $brand-color;

                svg,
                i {
                    color: $brand-color;
                }

            }

            &.social__link--style222 {
                border-color: $bg-color-10;

                svg,
                i {
                    color: $bg-color-10;
                }

            }

            &.social__link--style3 {
                background-color: $brand-color;
                border-color: $brand-color;

                svg,
                i {
                    color: $thm-title-color;
                }

            }

            &.social__link--style5 {
                // border-color: $brand-color;

                svg,
                i {
                    color: $brand-color;
                }

            }

            // &--style6 {
            //     border-color: $bg-white;

            //     svg,
            //     i {
            //         color: $brand-color;
            //     }

            // }

        }
    }
}