// partner
.partner {

    padding-top: 0px;
    padding-bottom: 0px;
    overflow-x: clip;
    z-index: 99;

    @extend %py-30;

    &__wrapper {
        @extend %p-rel;
        z-index: 333;

        position: relative;
        overflow: hidden;

    }

    //background overlay
    &--gradient {
        @extend %p-rel;

        &:before {
            @extend %p-abs;
            content: "";
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: $tertiary-color;
            // border-top: 8px solid $body-color;
            // border-bottom: 8px solid $body-color;
            z-index: 3;
        }
    }

    &--gradient2 {
        @extend %p-rel;

        &:before {
            @extend %p-abs;
            content: "";
            left: 0;
            top: -7%;
            height: 75%;
            width: 100%;
            //background-color: #121a27;
            // border-top: 8px solid $body-color;
            // border-bottom: 8px solid $body-color;
            z-index: 3;
        }

        &::after {
            @extend %p-abs;
            content: "";
            left: 0;
            top: -5px;
            height: 100px;
            width: 100%;
            background: radial-gradient(circle, rgba(36, 23, 0, 0) 0%, var(--bg-color-trans) 100%);
            // background: linear-gradient(152deg, var(--bg-color) 0%, var(--bg-color-trans) 100%);
            //background-color: "#0c261e";
            transform: rotate(2deg);
            z-index: 2;
        }
    }


    &--style2 {
        @extend %p-rel,
        %py-50;

        @include breakpoint(lg) {
            padding-block: 60px;
        }

        @include breakpoint(xl) {
            padding-block: 70px;
        }

        @include breakpoint(xxl) {
            padding-block: 80px;
        }

        @include breakpoint(xxl) {
            padding-block: 90px;
        }

        &:before {
            @extend %p-abs;
            content: "";
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: $bg-color-12;
            z-index: 3;
        }
    }
}

.partner__item-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
        max-width: 100%;
        height: 40px;
        width: auto;
        object-fit: contain;
        object-position: center;
    }
}

.partner__item-inner img {
    width: 60px;
    height: 60px;
    //border-radius: 50%;
    /* makes it circular */
    object-fit: cover;
    /* ensures the image fills the circle without distortion */
}