.toggle-view {
  text-align: center;
  margin: 1rem 0;
  z-index: 9;
  top: 26%;

    @media (max-width: 768px) {
     top: 40%;
    }
}

/* Container that holds both toggle buttons */
.toggle-container {
  display: inline-flex;
  background: #0da25a;
  /* A dark background; change to your desired color */
  border-radius: 8px;
  overflow: hidden;
}

/* Base toggle button styles */
.toggle-btn {
  color: #ccc;
  background: transparent;
  box-shadow: 0px 12px 30px rgba(14, 4, 0, 0.24);
  border: none;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

/* Hover effect */
.toggle-btn:hover {
  background: #0da25a75;
  /* Slightly lighter than container */
  color: #fff;
}

/* Active state styles */
.toggle-btn.active {
  background: #4ebc74;
  /* Example green accent color */
  color: #fff;
}