/**
 * Basic typography style for copy text
 */


body {
  background-color: $body-color;
  color: $text-color;
  font-size: 1rem;
  line-height: 1.625;
  font-family: $text-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {

  font-family: $title-font;
  color: $title-color;
  line-height: 1.25;
  font-weight: 600;
  a{
    color: inherit;
    font-family: inherit;
  }
}

h1{
  line-height: 1.067;
}


h1 {
  @include font-size($fs-h1);
}

h2 {
  @include font-size($fs-h2);
}

h3 {
  @include font-size($fs-h3);
}

h4 {
  @include font-size($fs-h4);
}

h5 {
  @include font-size($fs-h5);
}

h6 {
  @include font-size($fs-h6);
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: $text-color;
  text-decoration: none;
  @include add-prefix(transition, $transition);

  @include hover {
    color: $brand-color;
  }
}


// p{
//   color: $text-color;
// }

ul,
ol {
  @extend %list-none;
  @extend %ps-0;
  @extend %mb-0;
  padding-left: 0;
}
