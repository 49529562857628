// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
html[data-bs-theme='dark'] {

  .team {
    &__item {
      &-author {
        &--style1 {
          background-color: rgba(0, 21, 15, 0.8);
          backdrop-filter: blur(2px);
        }
      }

      &-inner {
        &--shape2 {
          background-color: #051a14;
        }
      }
    }
  }

  .banner {
    &--style3 {
      .banner {
        &__bg {
          img {
            display: none;
          }
        }

        &__counter {
          &-item {
            border: none;
            background: linear-gradient(152deg, rgba(237, 242, 248, 0.03) 0%, rgba(237, 242, 248, 0.12) 100%);
            box-shadow: 0px 16px 64px 0px rgba(0, 208, 148, 0.08);
          }
        }
      }
    }
  }

  .trk-btn {
    &--outline2 {
      background-color: $thm-wh-color-dark;
      border-color: $thm-wh-color-dark;
      color: $thm-wh-color;
    }

    &--outline4 {
      border: 1px solid $bg-white;
      color: $bg-white;

      &.active,
      &:hover {
        background-color: $brand-color;
        border-color: $brand-color;
        color: $thm-title-color;
      }
    }
  }

  .blog {
    &__item {
      &-inner {
        box-shadow: none;
      }
    }

    &__shape {
      &-item {
        &--1 {
          border-top: 2px solid var(--bg-shape-color);
        }
      }
    }
  }

  .service {
    &__item {
      &-inner {
        &:hover {
          box-shadow: none;
        }
      }

      &--style2 {
        .service {
          &__item {
            &-inner {
              // background-color: $thm-bg-color-2-dark;

              &:hover {
                box-shadow: 0px 12px 30px rgba(14, 4, 0, 0.64);
              }
            }
          }
        }
      }

      &--style3 {
        .service {
          &__item {
            &-inner {

              &:hover {
                // background-color: $body-color;

                h5 {
                  a {
                    color: $thm-title-color;
                  }
                }

                p {
                  color: $thm-title-color;
                  opacity: 0.8;
                }
              }
            }
          }
        }
      }
    }
  }

  .pricing {
    &__item {
      &-inner {
        @include add-prefix(transition, $transition);

        &.active,
        &:hover {
          border: 2px solid $brand-color;
          box-shadow: 0px 16px 40px 0px rgba(26, 64, 137, 0.06);
        }
      }

      &-top {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }

      &--style2 {
        .pricing__item {
          &-inner {
            @include add-prefix(transition, $transition);
            border: 1px solid rgba(229, 237, 248, 0.1);

            &.active,
            &:hover {
              border: 1px solid rgba(255, 255, 255, 0.3);
              box-shadow: none;
            }

            &.active {
              // background-color: $body-color;
              background-color: $bg-color-10;

              .pricing__item-top {
                h6 {
                  color: $thm-title-color;
                }

                h3 {
                  span {
                    color: $thm-title-color;
                  }
                }

                border-bottom: 1px solid #1c1b1b59;
              }

              .pricing__list {
                &-item {
                  color: $thm-title-color;
                }
              }
            }
          }
        }
      }
    }

    &__shape {

      &-item {

        &--1 {
          border-left: block;
          border-bottom: block;
        }
      }
    }
  }


  .blog {
    &__item {
      &-inner {
        // background-color: $thm-bg-color-6-dark;

        &:hover {
          box-shadow: 0px 24px 60px rgba(13, 14, 16, 0.5);
        }
      }
    }
  }

  .swiper-nav {

    &__btn {
      border-color: $thm-wh-color-dark;

      i,
      svg {
        color: $bg-white;
      }

      &.active,
      &:hover {
        background-color: $brand-color;
        border-color: $brand-color;

        i,
        svg {
          color: $thm-title-color;
        }
      }

      &--style3 {
        border: 1px solid $tertiary-color4;

        &.active,
        &:hover {
          background-color: $tertiary-color4;
          border-color: $tertiary-color4;

          i,
          svg {
            color: $title-color;
          }
        }
      }
    }


  }

  .testimonial {
    &__item {
      &-content {
        p {
          // trk-rgba 
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }

      &:hover {
        .testimonial {
          &__item {
            &-inner {
              box-shadow: none;
            }
          }

          &__quote {
            span {

              i,
              svg {
                // opacity: 0.8;
                color: $brand-color;
              }
            }

            &--style2 {
              span {

                i,
                svg {
                  color: $bg-color-11;
                }
              }
            }

          }
        }
      }
    }

    &__quote {
      span {

        i,
        svg {
          // trk-rgba 
          @include add-prefix(transition, $transition);
          color: rgba(255, 255, 255);
          opacity: 0.3;
        }
      }


    }

  }

    .accordion {
      &--style1 {
        [class^="col"] {
          &:not(:last-child) {
            .accordion__item {
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }

  .cta {
    &--style1 {
      .cta {
        &__content {
          &-inner {
            background-color: $thm-bg-color-6-dark;
            border-color: $thm-bg-color-6-dark;
          }
        }
      }
    }

    // &--style2 {
    //   .cta {
    //     &__subscribe {
    //       .form-control {
    //         background-color: $thm-tertiary-color-dark;
    //         border: 1px solid $thm-tertiary-color-dark;
    //       }
    //     }
    //   }
    // }
  }

  .form-control {
    background-color: $thm-bg-color-4-dark;
    border-color: $thm-bg-color-4-dark;

    &--style1 {
      border-color: #dcdfe80d;
      border-right: none;
    }
  }

  .sidebar {
    &__search {
      form {
        input {
          background-color: $thm-body-color-dark;
          border-color: $thm-body-color-dark;
          color: $bg-white;
        }
      }
    }
  }

  .account {
    &__social {
      &-btn {
        border-color: $thm-bg-color-4-dark;
      }
    }
  }


  .trk-btn {
    &--outline {
      color: $brand-color;
    }
    
    &.active,
    &:hover {
      &.trk-btn--outline6 {
        color: $thm-title-color;
      }
    }
  }

  .account {
    &__divider {
      &--style1 {
        &::before {
          background-color: $thm-bg-color-4-dark;
        }
      }
    }
  }

  .contact {
    &__form {

      @include breakpoint(md) {
        &::before {
          background: linear-gradient(188.07deg, rgba(157, 157, 157, 0) 31.91%, #97999B75 98.96%);
        }
      }

    }
  }

  .nav {
    &--feature {
      .nav {
        &-link {
          box-shadow: 0px 12px 30px rgba(14, 4, 0, 0.24);
        }
      }

    }
  }


  .social {
    &__link {
      &--style1 {
        border: 2px solid $social-border-color-2;

        &.active,
        &:hover {
          background-color: transparent;
          border: 2px solid $social-border-color-2;

          i,
          svg {
            color: $brand-color;
          }
        }
      }
    }
  }

  .partner {
    &--gradient {
      &::after {
        background: #003E2C;
      }
    }
  }

}