// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


//*-----------------------------------
//*** Custom variables for theme starthite
//*-----------------------------------

$thm-brand-color: #0CD66E;
$thm-brand-color-dark: #0CD66E;

$thm-secondary-color: var(--floating-content-bg-color-2);
$thm-secondary-color-dark: #0CD66E;

$thm-tertiary-color: #E0FAF2;
$thm-tertiary-color-dark: #172231;

$thm-tertiary-color2: #F5F7FC;
$thm-tertiary-color2-dark: #002A1E;

$thm-tertiary-color3: #F5F7FC;
$thm-tertiary-color3-dark: #0E221E;


$thm-tertiary-color4: #6084E0;
$thm-tertiary-color4-dark: #6084E0;


$thm-body-color-light: #FFFFFF;
$thm-body-color-dark: #121A27;

$thm-bg-color-light: #52eaf0;
$thm-bg-color-dark: #32dd79;

$thm-border-color-light: #E6EDFB;
$thm-border-color-dark: #E6EDFB1F;

// $thm-border-color-2-light: #E6EDFB; 
// $thm-border-color-2-dark: #1B2D29; 

// social border colors
$thm-social-border-color-light: #F0F4FA;
$thm-social-border-color-dark: #ffffff14;

$thm-social-border-color-2-light: #ECEFF2;
$thm-social-border-color-2-dark: #FFFFFF14;


$thm-form-border-light: #CFDEF1;
$thm-form-border-dark: #CFDEF1;



// text colors
$thm-title-color: #0C263A;
$thm-title-color-dark: #FFFFFF;

$thm-text-color: #6B777F;
$thm-text-color-dark: #908f8f;

$thm-text-color-light: #6B6F7F;
$thm-text-color-light-dark: #FFFFFF;


$thm-bg-color-2-light: #F5F8FC;
$thm-bg-color-2-dark: #2D2C2C;

$thm-bg-color-3-light: #FFFFFF;
$thm-bg-color-3-dark: #121A27;


// todo for rpba
$thm-bg-color-4-light: linear-gradient(150.39deg, rgba(237, 242, 248, 0.22) 15.02%, #{$thm-bg-color-light} 99.59%);
$thm-bg-color-4-dark: linear-gradient(150.39deg, rgba(237, 242, 248, 0.0264) 15.02%, rgba(237, 242, 248, 0.12) 99.59%);

$thm-bg-color-5-light: linear-gradient(218.82deg, rgba(255, 255, 255, 0) 14.16%, #FFFFFF 101.06%);
$thm-bg-color-5-dark: linear-gradient(150.39deg, rgba(237, 242, 248, 0.22) 15.02%, #EDF2F8 99.59%);


$thm-bg-color-6-light: #F5F7FC;
$thm-bg-color-6-dark: #2D2C2C;


$thm-bg-color-7-light: #F1F4FB;
$thm-bg-color-7-dark: #ffffff0a;


$thm-bg-color-8-light: #124D6D;
$thm-bg-color-8-dark: #124D6D;


$thm-bg-color-9-light: #FFFF;
$thm-bg-color-9-dark: #000;


$thm-bg-white-light: #ffffff;
$thm-bg-white-dark: #ffffff;


$thm-bg-team-light: #d6e5ff;
$thm-bg-team-dark: #1c2e2a;



// form colors
$thm-form-border-light: #DCDFE8;
$thm-form-border-dark: #DCDFE8;

$thm-form-focus-light: #366CD5;
$thm-form-focus-dark: #366CD5;



// other colors
$thm-wh-color: #fff;
$thm-wh-color-dark: #121A27;

$thm-black-color: #000;
$thm-black-color-dark: #fff;


// action colors
$thm-error-light: #F04438;
$thm-error-dark: #F04438;

$thm-warning-light: #F79009;
$thm-warning-dark: #F79009;

$thm-success-light: #12B76A;
$thm-success-dark: #12B76A;

$thm-danger-light: #FF0000;
$thm-danger-dark: #FF0000;



//===================== Theme varibale for  ** Light ** ===================== //
:root {
    --brand-color:#{$thm-brand-color};
    --secondary-color:#{$thm-secondary-color};
    --tertiary-color:#{$thm-tertiary-color};
    --tertiary-color2:#{$thm-tertiary-color2};
    --tertiary-color3:#{$thm-tertiary-color3};
    --tertiary-color4:#{$thm-tertiary-color4};
    --body-color:#{$thm-body-color-light};


    --header-bg-color: #0e352a;
    --header-bg-color2: #093d47;

    --border-color:#{$thm-border-color-light};
    --border-color-2: #E6EDFB;
    --border-color-3: #E6EDFB;
    --border-color-4: #E6EDFB;
    --border-color-5: #F1F4FB;
    --border-color-6: #E5F9F3;
    --border-color-7: #9DB9EE;
    --border-color-8: rgba(189, 189, 189, 0.2);
    --border-color-9: rgba(5, 0, 26, 0.12);
    --border-color-10: rgba(184, 182, 193, 0.12);

    --feature-bg-color: #00150F;



    --social-border-color:#{$thm-social-border-color-light};
    --social-border-color-2:#{$thm-social-border-color-2-light};
    --social-border-color-3:#{$thm-social-border-color-2-light};
    --form-border:#{$thm-form-border-light};

    // --test-color: blue;
    // --test-color-trans: rgba(0, 0, 255, 0.205);

    // text colors
    --title-color:#{$thm-title-color};
    --title-color-style2:#{$thm-title-color};
    --text-color:#{$thm-text-color};
    --text-color-light:#{$thm-text-color-light};
    --text-color-2: rgba(255, 255, 255, 0.8);
    --text-color-3: rgba(255, 255, 255, 0.70);

    // background colors
    --bg-color: #EDF2F8;
    --bg-color-trans: rgba(237, 242, 248, 0.22);

    --bg-grad-color: #FFF;
    --bg-grad-color-trans: rgba(255, 255, 255, 0.00);

    --bg-grad-color-2: #D5E5F9;
    --bg-grad-color-trans-2: rgba(255, 255, 255, 0.22);

    --bg-grad-color-3: #E9EFF7;
    --bg-grad-color-trans-3: rgba(240, 244, 250, 0.16);

    --bg-grad-color-4: rgba(0, 208, 148, 0.32);
    --bg-grad-color-trans-4: rgba(229, 237, 248, 0.00);



    --bg-color-2:#{$thm-bg-color-2-light};
    --bg-color-3:#{$thm-bg-color-3-light};
    --bg-color-4:#{$thm-bg-color-4-light};
    --bg-color-5:#{$thm-bg-color-4-light};
    --bg-color-6:#{$thm-bg-color-6-light};
    --bg-color-7:#{$thm-bg-color-7-light};
    --bg-color-8:#{$thm-bg-color-8-light};
    --bg-color-9:#{$thm-bg-color-9-light};
    --bg-color-10: #E5FFAD;
    --bg-color-11: #141821;
    --bg-color-12: #141821;
    --bg-color-13: #E5FFAD;
    --bg-color-14: #FFF6E0;
    --bg-color-15: rgba(43, 40, 40, 0.15);
    --bg-color-16: #E5FFAD;
    --bg-color-17: rgb(220, 53, 69);


    --bg-white:#{$thm-bg-white-light};
    --bg-team:#{$thm-bg-team-light};

    // form colors
    --form-focus-color:#{$thm-form-focus-light};

    // others colors
    --wh-color:#{$thm-wh-color};
    --black-color:#{$thm-black-color};

    --floating-content-border-color: rgba(0, 0, 0, 0.02);
    --floating-content-bg-color: rgba(255, 255, 255, 0.90);
    --floating-content-bg-color-2: rgba(255, 255, 255, 0.50);

    --service-item-border-color: #fff;
    --service-item-bg-color: #fff;
    --faq-item-btn-border-color: #03314b12;
    --faq-bg-color: #fff;
    --extra-bg-color: #fff;


    --tag-bg-color: #EDFFFA;






    // shape colors
    --bg-shape-color: #B8CFEB;
    --bg-shape-color-trans: rgba(255, 255, 255, 0.22);

    --bg-shape-color-2: #D5E5F9;
    --bg-shape-color-trans-2: rgba(255, 255, 255, 0.22);


    --footer-bg-color: #121A27;
    --faq-img-bg-shadow: #16d466;
    --pagination-bg-color: #E0FAF2;


    --section-bg-color: #f8f8f8;
    --sec-bg-color-2: #fff;

    --account-bg-color: #F5F7FC;
    --account-bg-color2: #FFF;

    // arrow color
    --arrow-color1: #B5CAF2;
    --arrow-icon-color: #0A4FD5;
    --header-border-color: #EBE9E1;
    --header-border-color2: rgba(255, 255, 255, 0.12);
    --banner2-bg-color: #0e241d;
    --about-img-bg-shadow: transparent;
    --cta-img-bg-shadow: #45554b;

    // action colors
    --error-color:#{$thm-error-light};
    --warning-color:#{$thm-warning-light};
    --success-color:#{$thm-success-light};
    --danger-color:#{$thm-danger-light};



    .accordion-button:after {
        --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%23000000}</style><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>');
        --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%2300D094}</style><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>');
    }

    .accordion-button--style2:after {
        --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%23000000}</style><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>');
        --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%23000}</style><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>');
    }

    .accordion-button--style3:after {
        --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%23000000}</style><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>');
        --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%230A4FD5}</style><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>');
    }
}


//===================== Theme varibale for  ** Dark ** ===================== //
[data-bs-theme="dark"] {

    .accordion-button:after {
        --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%23ffffff}</style><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>');
        --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%2300D094}</style><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>');
    }

    .accordion-button--style2:after {
        --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%23ffffff}</style><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>');
        --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%23000}</style><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>');
    }

    .accordion-button--style3:after {
        --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%23ffffff}</style><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>');
        --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"> <style>svg{fill:%2300D094}</style><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>');
    }



    --brand-color:#{$thm-brand-color-dark};
    --secondary-color:#{$thm-secondary-color-dark};
    --tertiary-color:#{$thm-tertiary-color-dark};
    --tertiary-color2:#{$thm-tertiary-color2-dark};
    --tertiary-color3:#{$thm-tertiary-color3-dark};
    --tertiary-color4:#{$thm-tertiary-color4-dark};
    --body-color:#{$thm-body-color-dark};
    --border-color:#{$thm-border-color-dark};
    --border-color-2: rgba(255, 255, 255, 0.08);
    --border-color-3: #E6EDFB;
    --border-color-4: #1B2D29;
    --border-color-5: rgba(255, 255, 255, 0.08);
    --border-color-6: rgba(255, 255, 255, 0.08);
    --border-color-7: #00533B;
    --border-color-8: rgba(234, 219, 215, 0.06);
    --border-color-9: rgba(184, 182, 193, 0.12);
    --border-color-10: rgba(184, 182, 193, 0.12);



    --header-bg-color: #0e352a;
    --header-bg-color2: #093d47;


    --feature-bg-color: rgba(229, 237, 248, 0.087);

    --social-border-color:#{$thm-social-border-color-dark};
    --social-border-color-2:#{$thm-social-border-color-2-dark};
    --social-border-color-3:rgba(255, 255, 255, 0.1);
    --form-border:#DCDFE833;


    // text colors
    --title-color:#{$thm-title-color-dark};
    --title-color-style2:#BBC2C7;
    --text-color:rgba(255, 255, 255, 0.8);
    --text-color-light:#{$thm-text-color-light-dark};
    --text-color-2: rgba(255, 255, 255, 0.8);
    --text-color-3: rgba(255, 255, 255, 0.70);

    // background colors
    --bg-color: #121A27;
    --bg-color-trans: rgba(237, 242, 248, 0.08);

    --bg-grad-color: #0cd66e;
    --bg-grad-color-trans: rgba(255, 255, 255, 0.00);

    --bg-grad-color-2: rgba(213, 229, 249, 0.16);
    --bg-grad-color-trans-2: rgba(255, 255, 255, 0.04);

    --bg-grad-color-3: rgba(32, 56, 51, 0.50);
    --bg-grad-color-trans-3: rgba(2, 29, 21, 0.00);

    --bg-grad-color-4: rgba(0, 208, 148, 0.24);
    --bg-grad-color-trans-4: rgba(0, 208, 148, 0.00);


    --bg-color-2:#{$thm-bg-color-2-dark};
    --bg-color-3:#{$thm-bg-color-3-dark};
    --bg-color-4:#{$thm-bg-color-4-dark};
    --bg-color-5:#{$thm-bg-color-5-dark};
    --bg-color-6:#{$thm-bg-color-6-dark};
    --bg-color-7:#{$thm-bg-color-7-dark};
    --bg-color-8:#{$thm-bg-color-8-dark};
    --bg-color-9:#{$thm-bg-color-9-dark};
    --bg-color-10:#B4E058;
    --bg-color-11:#141821;
    --bg-color-12: rgba(237, 242, 248, 0.08);
    --bg-color-13: rgb(33, 52, 47);
    --bg-color-14: #455248;
    --bg-color-15: rgba(43, 40, 40, 0.308);
    --bg-color-16: #1B2D29;
    --bg-color-17: rgb(220, 53, 69);

    --bg-white:#{$thm-bg-white-dark};
    --bg-team:#{$thm-bg-team-dark};


    // form colors
    --form-focus-color:#{$thm-form-focus-dark};

    // others colors
    --wh-color:#{$thm-wh-color-dark};
    --black-color:#{$thm-black-color-dark};

    --floating-content-border-color: rgba(0, 0, 0, 0.02);
    --floating-content-bg-color: rgba(63, 81, 75, 0.80);
    --floating-content-bg-color-2: rgba(255, 255, 255, 0.08);


    --service-item-border-color: #091E19;
    --service-item-bg-color: rgba(33, 52, 47, 1);
    --faq-item-btn-border-color: #FFFFFF14;
    --faq-bg-color: #121A27;

    --extra-bg-color: #121A27;
    --tag-bg-color: #002A1E;


    // shape colors
    --bg-shape-color: rgba(213, 229, 249, 0.08);
    --bg-shape-color-trans: #0CD66E;

    --bg-shape-color-2: rgba(213, 229, 249, 0.16);
    --bg-shape-color-trans-2: rgba(255, 255, 255, 0.04);


    --footer-bg-color: #121A27;

    --faq-img-bg-shadow: #16d4668f;
    --pagination-bg-color: #00150F;


    --section-bg-color: rgba(237, 242, 248, 0.02);
    --sec-bg-color-2: #081c17;

    --account-bg-color: #00150F;
    --account-bg-color2: #00150F;

    // arrow color
    --arrow-color1: #2B4B43;
    --arrow-icon-color: #fff;
    --header-border-color: rgba(255, 255, 255, 0.16);
    --header-border-color2: rgba(255, 255, 255, 0.12);
    --banner2-bg-color: #0e241d;
    --about-img-bg-shadow: transparent;
    --cta-img-bg-shadow: #45554b;





    // action colors
    --error-color:#{$thm-error-dark};
    --warning-color:#{$thm-warning-dark};
    --success-color:#{$thm-success-dark};
    --danger-color:#{$thm-danger-dark};
}



//*-----------------------------------
//*** Custom variables for theme end
//*-----------------------------------


//===================== Variables start===================== //

// theme colors
$brand-color: var(--brand-color);
$secondary-color: var(--secondary-color);
$tertiary-color: var(--tertiary-color);
$tertiary-color2: var(--tertiary-color2);
$tertiary-color3: var(--tertiary-color3);
$tertiary-color4: var(--tertiary-color4);


$header-bg-color: var(--header-bg-color);
$header-bg-color2: var(--header-bg-color2);
$header-border-color: var(--header-border-color);
$header-border-color2: var(--header-border-color2);


$body-color: var(--body-color);
$border-color: var(--border-color);
$border-color-2: var(--border-color-2);
$border-color-3: var(--border-color-3);
$border-color-4: var(--border-color-4);
$border-color-5: var(--border-color-5);
$border-color-6: var(--border-color-6);
$border-color-7: var(--border-color-7);
$border-color-8: var(--border-color-8);
$border-color-9: var(--border-color-9);
$border-color-10: var(--border-color-10);


$social-border-color: var(--social-border-color);
$social-border-color-2: var(--social-border-color-2);
$social-border-color-3: var(--social-border-color-3);

$form-border: var(--form-border);

// text colors
$title-color: var(--title-color);
$title-color-style2: var(--title-color-style2);
$text-color: var(--text-color);
$text-color-light: var(--text-color-light);
$text-color-2: var(--text-color-2);
$text-color-3: var(--text-color-3);


$wh-color: var(--wh-color);
$black-color: var(--black-color);

// background colors
$bg-color: var(--bg-color);
$bg-color-1: var(--bg-color-1);
$bg-color-2: var(--bg-color-2);
$bg-color-3: var(--bg-color-3);
$bg-color-4: var(--bg-color-4);
$bg-color-5: var(--bg-color-5);
$bg-color-6: var(--bg-color-6);
$bg-color-7: var(--bg-color-7);
$bg-color-8: var(--bg-color-8);
$bg-color-9: var(--bg-color-9);
$bg-color-10: var(--bg-color-10);
$bg-color-11: var(--bg-color-11);
$bg-color-12: var(--bg-color-12);
$bg-color-13: var(--bg-color-13);
$bg-color-14: var(--bg-color-14);
$bg-color-15: var(--bg-color-15);
$bg-color-16: var(--bg-color-16);
$bg-color-17: var(--bg-color-17);




$bg-white: var(--bg-white);
$section-bg-color: var(--section-bg-color);
$sec-bg-color-2: var(--sec-bg-color-2);
$account-bg-color: var(--account-bg-color);
$account-bg-color2: var(--account-bg-color2);

$arrow-icon-color: var(--arrow-icon-color);

// extra colors
$team-bg-color: var(--bg-team);
$service-item-bg-color: var(--service-item-bg-color);

$footer-bg-color: var(--footer-bg-color);


$floating-content-border-color: var(--floating-content-border-color);
$floating-content-bg-color: var(--floating-content-bg-color);
$floating-content-bg-color-2: var(--floating-content-bg-color-2);

$service-item-border-color: var(--service-item-border-color);

$faq-item-btn-border-color: var(--faq-item-btn-border-color);


$shape-color: var(--bg-shape-color);
$shape-color-trans: var(--bg-shape-color-trans);

$shape-color-2: var(--bg-shape-color-2);
$shape-color-trans-2: var(--bg-shape-color-trans-2);

$faq-img-bg-shadow: var(--faq-img-bg-shadow);
$faq-bg-color: var(--faq-bg-color);
$tag-bg-color: var(--tag-bg-color);

$extra-bg-color: var(--extra-bg-color);
$pagination-bg-color: var(--pagination-bg-color);

$feature-bg-color: var(--feature-bg-color);

$banner2-bg-color: var(--banner2-bg-color);

$about-img-bg-shadow: var(--about-img-bg-shadow);
$cta-img-bg-shadow: var(--cta-img-bg-shadow);


// form
$form-border: var(--form-border);
$form-focus: var(--form-focus);


// arro color
$arro-color1: var(--arrow-color1);
$arro-color2: var(--arrow-color2);

// Action colors
$error: var(--error-color);
$warning: var(--warning-color);
$success: var(--success-color);
$danger: var(--danger-color);





//=====================>>>\ Variables end ===================== //



//Template Font family

$title-font: 'Anek Telugu',
    sans-serif;

$text-font: 'Open Sans',
    sans-serif;

//Template font sizes
$fs-base: 16px !default;
$fs-small: 14px;

$fs-h1: 60px;
$fs-h2: 48px;
$fs-h3: 32px;
$fs-h4: 28px;
$fs-h5: 24px;
$fs-h6: 20px;

//Template transition

$transition: all .3s ease-out;