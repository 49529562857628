.comment {
    &__head {
        @extend %d-flex,
        %align-i-center,
        %justify-between;
    }

    &__author {
        @extend %d-flex,
        %align-i-center;
        gap: 15px;

        &-info {
            h6 {
                @extend %mb-10,
                %text-semibold;
                line-height: 1.3;
            }

            span {
                @include font-size(14px);
                color: $text-color;
            }
        }
        &-thumb{
            img{
                border-radius: 99px;
            }
        }
    }

    &__action {
        a {
            @extend %text-capitalize, %text-medium;
            @include add-prefix(transition, $transition);
            font-family: $title-font;
            color: $text-color;
            @include font-size(16px);

            img {
                @include add-prefix(transition, $transition);
                margin-inline-end: 1px;
                margin-bottom: 2px;
            }

            &:hover {
                color: $brand-color;

                img {
                    transform: translateX(-3px);
                    filter: hue-rotate(3deg);
                }
            }
        }
    }

    &__body{
        &-inner{ 
            @extend %mt-15, %p-15;
            background-color: $wh-color; 
            border-radius: 8px;
            border: 1px solid $border-color;
            @include breakpoint(lg){
                padding-block: 15px;
                padding-inline: 25px;
            }        
            p {
                color: $text-color;
            }
        }
    }
}