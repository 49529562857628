// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------


//Customizing bootstrap container
.container {
  @include breakpoint(xl) {
    max-width: 1170px;
  }
}

.home-4,
.home-5 {
  .container {
    @include breakpoint(xl) {
      max-width: 1320px;
    }
  }
}


//bootstrap offset override
@include breakpoint(md) {
  .offset-md-6 {
    margin-inline-start: 50%;
  }
}


// bootstrap accordian modify
.accordion-item {
  border: none;
  // border-radius: 0px !important;
  border-radius: 0;
  background-color: transparent;
}

.accordion-body {
  padding: 0px;
  background-color: transparent;
}



.gradient-text--brand1 {
  background: -webkit-linear-gradient($brand-color, $secondary-color);
  @include add-prefix(background-clip, text);
  @include add-prefix(text-fill-color, transparent);
}

// Home One backgound colors
// Home One backgound colors
.bg-body-color {
  background-color: $body-color;
}

.bg-color {
  background: linear-gradient(152deg, var(--bg-color-trans) 0%, var(--bg-color) 100%);
}

.bg-color-reverse {
  background: linear-gradient(152deg, var(--bg-color) 0%, var(--bg-color-trans) 100%);

}

.bg-grad-color {
  background: linear-gradient(219deg, var(--bg-grad-color-trans) 0%, var(--bg-grad-color) 100%);
}

.bg-color-1 {
  background-color: $secondary-color;
}

.bg-color-2 {
  background-color: $tertiary-color;
}

.bg-color-3 {
  background-color: $bg-color-3;
}

.bg-color-4 {
  background: $bg-color-4;
}

.bg-color-5 {
  background-image: var(--bg-color-5);
}

.section-bg-color {
  background-color: var(--section-bg-color);
}

.sec-bg-color2 {
  background-color: var(--sec-bg-color-2);
}

.feature-bg-color {
  background-color: $feature-bg-color;
}



.bg-color-7 {
  background-color: $bg-color-7;
}

.bg-color-8 {
  background-color: $bg-color-8;
}

.bg-color-9 {
  background-color: $bg-color-3;
}

.bg-color-10 {
  background-color: $bg-color-9;
}




.mt-minus-5 {
  margin-block-start: -5px;
}

.padding-top {
  padding-block-start: 80px;

  @include breakpoint(lg) {
    padding-block-start: 120px;
  }
}

.padding-top--style2 {
  padding-block-start: 80px;

  @include breakpoint(lg) {
    padding-block-start: 40px;
  }
}

.padding-bottom {
  padding-block-end: 80px;

  @include breakpoint(lg) {
    padding-block-end: 120px;
  }
}

.padding-bottom-style2 {
  padding-block-end: 20px;

  @include breakpoint(lg) {
    padding-block-end: 80px;
  }
}



h1,
h2,
h3,
h4,
h5,
h6 {
  color: $title-color;

  a {
    color: inherit;
  }
}


.subtitle {
  @include font-size(18px);
  @extend.mb-20;
  text-transform: capitalize;
  font-weight: bold;
  color: $title-color;
}

.bg--cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg--full {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.bg--defult {
  background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: center;
}

.d-block {
  width: 100%;
}

.br-16 {
  border-radius: 16px;
}


/*=========<<Margin & padding property Utilites start>>=========*/

$space-stops: (
  '10': 10px,
  '15': 15px,
  '20': 20px,
  '25': 25px,
  '30': 30px,
  '35': 35px,
  '40': 40px,
  '45': 45px,
  '50': 50px,
  '55': 55px,
  '60': 60px,
  '65': 65px,
  '70': 70px,
  '75': 75px,
  '80': 80px,
  '85': 85px,
  '90': 90px,
  '95': 95px,
  '100': 100px,
);

@each $key,
$val in $space-stops {

  /*-------<< margin properties >>-------*/
  .m-#{$key} {
    margin: #{$val} !important;
  }

  .mt-#{$key} {
    margin-block-start: #{$val} !important;
  }

  .mb-#{$key} {
    margin-block-end: #{$val} !important;
  }

  .ms-#{$key} {
    margin-inline-start: #{$val} !important;
  }

  .me-#{$key} {
    margin-inline-end: #{$val} !important;
  }

  .my-#{$key} {
    margin-block: #{$val} !important;
  }

  .mx-#{$key} {
    margin-inline: #{$val} !important;
  }
}


/*------<< Paddings >>---------*/
@each $key,
$val in $space-stops {

  .p-#{$key} {
    padding: #{$val} !important;
  }

  .pt-#{$key} {
    padding-block-start: #{$val} !important;
  }

  .pb-#{$key} {
    padding-block-end: #{$val} !important;
  }

  .ps-#{$key} {
    padding-inline-start: #{$val} !important;
  }

  .pe-#{$key} {
    padding-inline-end: #{$val} !important;
  }

  .py-#{$key} {
    padding-block: #{$val} !important;
  }

  .px-#{$key} {
    padding-inline: #{$val} !important;
  }
}

/*=========<<Margin property Utilities end>>=========*/