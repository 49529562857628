/*===== keyframes for banner img ====*/
@include keyframes(santa-ride) {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@include keyframes(floating) {
    0% {
        transform: translateY(30px);
    }

    100% {
        transform: translateY(0px);
    }
}

@include keyframes(rotate) {

    to {
        transform: rotate(360deg);
    }
}

@include keyframes(rotateX) {

    to {
        transform: rotateX(360deg);
    }
}

@include keyframes(rotateY) {

    to {
        transform: rotateY(360deg);
    }
}

@include keyframes(rotateZ) {

    to {
        transform: rotateZ(360deg);
    }
}

@include keyframes(zoom) {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(.8);
    }
}


@include keyframes(left-right) {
    0% {
        transform: translateX(30px);
    }

    100% {
        transform: translateX(0px);
    }
}


@include keyframes(fade) {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

//glitch animation

@include keyframes(light) {

    10%,
    90% {
        opacity: .5;
    }

    20%,
    80% {
        opacity: .7;
    }

    30%,
    50%,
    70% {
        opacity: .9;
    }

    40%,
    60% {
        // transform: translate3d(4px, 0, 0);
        opacity: 1;
    }
}

//animation fadeInUP
@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

// fadeInDown animation keyframes 
@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}