// ===============       *************      ================ //
// This file contained all styles related header section
// ===============       *************      ================ //

//  --------------  >>>  header style start here <<< ------------- //

.slides-track {
  z-index: 3;
  position: relative;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(0, 171, 85);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(0, 171, 85);
  border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
  background: #00AB55;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: rgb(46, 58, 71);
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background-color: rgb(46, 58, 71);
  border-radius: 3px;
}

*::-webkit-scrollbar-track {
  background: #6e6b6b;
}

.modal-open {
  overflow: hidden;
}

body.modal-open {
  .bg-shape-svg {
    display: none;
  }
}


.header-section {
  @extend %p-rel;
  width: 100%;
  z-index: 99999;
  // background: transparent;

  &--style1 {
    @extend %p-rel;
    width: 100%;
    background-color: $bg-color-3;
    border-bottom: 1px solid $header-border-color;

    &.header-fixed {
      @extend %w-100;
      @include add-prefix(animation, fadeInDown 0.5s);
      position: fixed;
      top: 0;
      left: 0;
      border-bottom: none;
      box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
      z-index: 999;
      background-color: #1b2d29a8;

    }
  }

  &--style2 {
    @extend %p-abs;
    content: "";
    width: 100%;

    &.header-fixed {
      @extend %w-100;
      @include add-prefix(animation, fadeInDown 0.5s);
      position: fixed;
      top: 0;
      left: 0;
      box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
      z-index: 999;
      background-color: rgba(18, 26, 39, 0.659);

    }
  }

  &--style3 {
    @extend %p-abs;
    content: "";
    width: 100%;

    &.header-fixed {
      @extend %w-100;
      @include add-prefix(animation, fadeInDown 0.5s);
      position: fixed;
      top: 0;
      left: 0;
      box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
      z-index: 999;
      background-color: #1b2d29a8;

    }
  }

  &--style4 {
    @extend %p-abs;
    content: "";
    width: 100%;
    border-bottom: 1px solid $header-border-color2;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.12);

    &.header-fixed {
      @extend %w-100;
      @include add-prefix(animation, fadeInDown 0.5s);
      position: fixed;
      top: 0;
      left: 0;
      box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
      z-index: 999;
      background-color: #1b2d29a8;

    }
  }

  &--style5 {
    @extend %p-abs;
    content: "";
    width: 100%;

    &.header-fixed {
      @extend %w-100;
      @include add-prefix(animation, fadeInDown 0.5s);
      position: fixed;
      top: 0;
      left: 0;
      box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
      z-index: 999;
      // background-color: $header-bg-color;
      background-color: $bg-color-13;

    }
  }


  &.header-fixed {
    @extend %w-100;
    @include add-prefix(animation, fadeInDown 0.5s);
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
    z-index: 10001;
    // background-color: $wh-color;

  }
}

.header-wrapper {
  @extend %d-flex,
  %justify-between,
  %align-i-center,
  %py-15;


  @include breakpoint(sm) {
    position: relative;
  }

  @include breakpoint(lg) {
    padding-block: 20px;
  }


  .logo {
    a {
      @extend %d-block;

      img {
        max-width: 100%;
      }
    }

    @include breakpoint(max-sm) {
      max-width: 200px;
    }

    @include breakpoint(max-xl) {
      @include breakpoint(lg) {
        width: 200px;
      }
    }
  }

  .menu-area {
    @extend %d-flex;
    @extend %align-i-center;

    .header-btn {
      @include breakpoint(max-md) {
        display: none;
      }

      @extend %d-flex;
      @extend %align-i-center;
      column-gap: 20px;

      .switch-btn {
        max-width: 30px;
        cursor: pointer;
      }

      // .dark-btn {
      //   max-width: 30px;
      //   cursor: pointer;
      // }

    }
  }


  // style 2
  &--style2 {
    border-bottom: 1px solid $border-color-9;
  }
}



.menu {
  @extend %d-flex,
  %mb-0;
  column-gap: 10px;



  >li {
    >a {
      @include font-size(16px);
      padding: 15px 22px;
      text-transform: capitalize;
      font-family: $text-font;
      color: $title-color;
      font-weight: 500;

      // light dark bedge
      span {
        @include font-size(12px);
        padding: 1px 5px;
        color: $bg-white;
        border-radius: 4px;

        &.style1 {
          background-color: $brand-color;
        }

        &.style2 {
          background-color: $thm-form-focus-light;
        }
      }

      i {
        margin-right: 5px;
      }

      &:after {
        right: 11px;
      }

      &::before {
        right: 15px;
      }
    }
  }

  &--style1 {
    li {
      a {
        color: $title-color;
      }
    }
  }

  &--style2 {
    li {
      a {
        color: $bg-white;
      }
    }
  }



  @include breakpoint(lg) {
    li {
      position: relative;

      .submenu {
        @include add-prefix(transition, $transition);
        @include add-prefix(transform, translateY(35px));
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 9;
        background: $wh-color;
        width: 220px;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
        border-radius: 0.4rem;

        li {
          padding: 0;

          a {
            @include font-size(16px);
            padding: 10px 20px;
            text-transform: capitalize;
            font-family: $text-font;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            color: $title-color;
            border-bottom: 1px solid rgba($wh-color, $alpha: 0.05);
            border-radius: 0.3rem;
            transition: $transition;

            span {
              @include font-size(12px);
              padding: 1px 5px;
              color: $bg-white;
              border-radius: 4px;

              &.style1 {
                background-color: $brand-color;
                color: $thm-title-color;
              }

              &.style2 {
                background-color: $thm-black-color;
              }
            }

            &:hover,
            &.active {
              background: $wh-color;
              padding-left: 22px;
              // color: $black-color;
              color: $brand-color;

              &:after,
              &:before {
                background: $wh-color;
              }
            }
          }

          &:last-child {
            a {
              border-bottom: none;
            }
          }

          .submenu {
            left: 100%;
            top: 0;
          }
        }
      }

      &:hover {
        >.submenu {
          -webkit-transform: translateY(15px);
          -ms-transform: translateY(15px);
          transform: translateY(15px);
          opacity: 1;
          visibility: visible;
        }
      }
    }

    @include breakpoint(max-xl) {
      li {
        a {
          padding: 10px 16px;
        }
      }
    }
  }

  @include breakpoint(max-lg) {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    overflow: auto;
    max-height: 500px;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: top;
    z-index: 999;
    //glassmorphism
    background: rgba($wh-color, $alpha: 0.99);
    -webkit-backdrop-filter: blur(20px);
    overflow-y: hidden;

    li {
      width: 100%;
      padding: 0;

      a {
        display: block;
        padding: 10px 25px;
        font-size: 15px;
        text-transform: capitalize;
        // border-top: 1px solid #f753250f;
        border-top: 1px solid $border-color-8;
        // border-top: 1px solid rgba(189, 189, 189, 0.2); light
        color: $title-color;

        &:hover {
          background: rgba($wh-color, $alpha: 0.6);
        }

        span {
          @include font-size(12px);
          padding: 1px 5px;
          color: $bg-white;
          border-radius: 4px;

          &.style1 {
            background-color: $brand-color;
          }

          &.style2 {
            background-color: $thm-form-focus-light;
          }
        }
      }

      .submenu {
        padding-inline-start: 20px;
        display: none;

        li {
          width: 100%;

          a {
            font-family: $title-font;
            display: flex;
            justify-content: space-between;
            font-weight: 400;
          }
        }
      }
    }

    &.active {
      -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
      transform: scaleY(1);
      // box-shadow: 0 5px 10px -3px rgba($black-color, $alpha: 0.8);
      box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.1);
      background-color: $wh-color;
    }
  }

  &.active {
    &:after {
      content: "";
      display: block;
      background-color: #121A27de;
      position: absolute;
      top: 0px;
      height: 100vh;
      width: 100vw;
      left: 0px;
      z-index: -3;
    }
  }
}

.header-bar {
  @extend %p-rel;
  @extend %c-pointer;
  width: 25px;
  height: 20px;
  @extend %ms-30;

  @include breakpoint (md) {
    width: 30px;
    height: 24px;
  }

  span {
    @extend %p-abs;
    @extend %d-inline-block;
    @extend %w-100;
    @include add-prefix(transition, $transition);
    // background-color: $secondary-color;
    background-color: $black-color;
    border-radius: 5px;
    height: 2px;




    left: 0;

    &:first-child {
      top: 0;
    }

    &:nth-child(2) {
      top: 51%;
      transform: translateY(-65%);
    }

    &:last-child {
      bottom: 0;
    }
  }

  &--style1 {
    span {
      background-color: $black-color;
    }
  }

  &--style2 {
    span {
      background-color: $bg-white;
    }
  }

  &.active {
    span {
      &:first-child {
        @include add-prefix(transform, rotate(45deg) translate(3px, 9px));

        @include breakpoint(md) {
          @include add-prefix(transform, rotate(45deg) translate(3px, 12px));
        }
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        @include add-prefix(transform, rotate(-45deg) translate(3px, -10px));

        @include breakpoint(md) {
          @include add-prefix(transform, rotate(-45deg) translate(3px, -13px));
        }
      }
    }
  }

  @include breakpoint(max-sm) {
    width: 15px;
    height: 16px;
    margin-right: 20px;

    span {
      height: 2px;
      width: 20px;
    }

    &.active {
      span {
        &:first-child {
          @include add-prefix(transform, rotate(45deg) translate(4px, 6px));
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          @include add-prefix(transform, rotate(-45deg) translate(4px, -6px));
        }
      }
    }
  }
}

.menu-item-has-children {
  >a {
    @extend %p-rel;

    &::after {
      @extend %p-abs;
      @include add-prefix(transition, $transition);
      position: absolute;
      content: "";
      right: 2px !important;
      top: 20px;
      border-left: 2px solid whitesmoke;
      border-bottom: 2px solid whitesmoke;
      width: 10px;
      height: 10px;
      transform: rotate(-45deg);

    }

    @include breakpoint(lg) {
      @include breakpoint(max-xl) {
        &::after {
          top: 15px;
        }
      }
    }
  }

  &:hover {
    >a {
      &::after {
        transform: rotate(45deg) !important;
        top: 22px !important;
      }
    }
  }

  &.open {
    >a {
      &::after {
        transform: rotate(45deg) !important;
        top: 22px !important;
      }
    }
  }
}


// mega-menu

.menu-item-has-children {
  &.megamenu {
    position: static;


    .submenu {
      width: 100%;

      li {
        .home-showcase__buttons {
          a {
            span {
              color: $thm-title-color;
              font-weight: 500;
              @include font-size(14px);
            }

            &:hover {
              background-color: $brand-color;
            }
          }
        }
      }
    }
  }
}

.home-showcase {
  background-color: $wh-color;
  padding: 40px;
  // box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;

  max-height: 700px;
  overflow: auto;


  &__buttons {
    @extend %p-abs,
    %justify-center,
    %align-i-center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    transform: scale(1, 0);
    opacity: 1;
    visibility: hidden;
    transform-origin: bottom center;
    transition: 500ms ease;


    &-item {
      padding: 10px 38px;
      background-color: $brand-color;
      // width: 150px;
      text-align: center;
      text-decoration: none;
    }
  }


  &__title {
    a {
      @extend %text-center,
      %text-medium;
      margin: 0;
      @include font-size(18px);
      color: $title-color;
      margin-top: 15px;
    }
  }

  &__image {
    @extend %p-rel,
    %of-hidden;
    // background-color: black;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

    img {
      width: 100%;
      transition: 500ms ease;
      transform: scale(1);

    }

    &::after {
      @extend %p-abs;
      @include add-prefix(transition, $transition);
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $bg-color-15;
      opacity: 0.1;
    }

    &:hover {
      .home-showcase__buttons {
        transform: scale(1, 1);
        opacity: 1;
        visibility: visible;
        z-index: 1;
      }

      .home-showcase__buttons-item {
        background-color: $brand-color;
      }

      .home-showcase__title {
        a {
          background-color: transparent;
          color: $brand-color;
        }
      }

      &::after {
        opacity: 1;
      }

    }
  }


  &__badge {
    @extend %p-abs;
    content: "";
    top: 3%;
    right: 3%;

    span {
      color: $bg-white;
      background-color: $bg-color-17;
      padding: 2px 7px;
      border-radius: 4px;
      @include font-size(12px);
    }

  }
}


//  --------------  >>> section header style start here <<< ------------- //
.section-header {
  @extend %p-rel,
  %mb-40;
  z-index: 2;

  @include breakpoint(lg) {
    margin-bottom: 48px;
  }

  &--style2 {
    @extend %mb-10;

    @include breakpoint(lg) {
      margin-bottom: 0px;
    }

    h2 {
      line-height: 1.1;
    }
  }

  &--style3 {
    h2 {
      color: $bg-white;
    }

    p {
      color: $bg-white;
      opacity: 0.8;
    }

  }

  &--style4 {
    @extend %d-flex, %align-i-center, %justify-between;
    gap: 15px;

    h2 {
      line-height: 1.4;

      span {
        color: $brand-color;
      }
    }
  }

  &__content {


    h2 {
      span {
        color: $secondary-color;

        &.style2 {
          color: $brand-color;
        }
      }
    }

    p {
      max-width: 55ch;
    }

    &--style3 {
      flex: 1;
    }


  }

  h2 {
    @extend %text-capitalize,
    %mb-0;

    span {
      color: $secondary-color;

      &.style2 {
        color: $brand-color;
      }
    }
  }

  p {
    font-weight: 400;

    &.mute {
      color: $text-color;
    }
  }


  &--max18 {
    h2 {
      max-inline-size: 18ch;
    }
  }

  &--max20 {
    h2 {
      max-inline-size: 20ch;
      margin: auto;
    }
  }

  &--max50 {
    @extend %text-center;
    max-inline-size: 50ch;
    margin: 0 auto 48px auto;

    @include breakpoint(max-md) {
      padding-inline: 10px;
    }
  }

  &--max57 {
    @extend %text-center;
    max-inline-size: 57ch;
    margin: 0 auto 48px auto;

    @include breakpoint(max-md) {
      padding-inline: 10px;
    }
  }

  &--max65 {
    @extend %text-center;
    max-inline-size: 65ch;
    margin: 0 auto 48px auto;

    @include breakpoint(max-md) {
      padding-inline: 10px;
    }
  }
}


// trk-rgba 
.subtitle {
  @extend %d-flex,
  %align-i-center;
  gap: 10px;



  p {
    @include font-size(20px);
    font-weight: 600;
    font-family: $title-font;
  }

  &--style2 {
    @extend %justify-center;
    color: $secondary-color;

  }

  &--style3 {
    p {
      @include font-size(18px);
      font-weight: 500;
      font-family: $title-font;
      color: $secondary-color;
    }

  }

  &--style4 {
    p {
      @extend %text-medium;
      @include font-size(18px);
      color: $title-color;
      text-transform: uppercase;
    }

    p::before {
      content: "";
      margin-right: 10px;
      background-color: $black-color;
      border: 2px solid $black-color;
    }
  }

  &--style5 {
    @extend %text-medium;
    @include font-size(18px);
    color: $title-color;
    text-transform: uppercase;
  }
}

//  --------------  >>> page header style start here <<< ------------- //
.page-header {
  @extend %p-rel,
  %of-hidden;
  padding-block: 70px;

  @include breakpoint(lg) {
    padding-block: 100px;
  }

  &__content {
    h2 {
      @extend %text-capitalize,
      %mb-15;
      color: $bg-white;

      @include breakpoint(lg) {
        margin-bottom: 15px;
      }
    }
  }

  .breadcrumb-item {
    @extend %text-semibold;
    @include font-size(18px);
    color: $brand-color;

    a {
      color: $brand-color;
    }


    &:before {
      color: $bg-white;
    }

    &.active,
    &:hover {
      color: $bg-white;

    }

  }


  &__shape {

    &-item {
      @extend %p-abs;

      &--1 {
        display: none;
        top: 0%;
        right: 0%;

        @include breakpoint(sm) {
          top: 0%;
          right: -3%;
        }

        @include breakpoint(md) {
          display: block;
          top: 0%;
          right: -3%;
          width: 500px;
        }

        @include breakpoint(lg) {
          top: 0%;
          right: 0%;
          width: 650px;
        }

        @include breakpoint(xl) {
          width: auto;
          top: 0%;
          right: 0%;
        }

        @include breakpoint(xxl) {
          top: 0%;
          right: 0%;
        }

        @include breakpoint(xxxl) {
          top: 0%;
          right: 15%;
        }
      }
    }
  }
}


.header-bottom {
  backdrop-filter: blur(6px);
}

//  --------------  >>> page header style end here <<< ------------- //