.sidebar {
    @extend %of-hidden;

    &__head {
        @extend %p-rel;

        h3 {
            @extend %pb-15,
            %mb-0;
            border-bottom: 1px solid $border-color-5;
        }

        h6 {
            @extend %text-semibold,
            %pb-15,
            %mb-0;
            border-bottom: 1px solid $border-color-5;
        }

        h4 {
            @extend %mb-0;
        }
    }

    &__search {
        @extend %p-25;
        background-color: $wh-color;
        box-shadow: 0px -16px 48px rgba(6, 35, 63, 0.03), 0px 16px 48px rgba(6, 35, 63, 0.03);
        border-radius: 4px;


        form {
            @extend %p-rel;
            background-color: $wh-color;
            border-radius: 8px;

            input {
                @extend %p-15;
                // background-color: transparent;
                background-color: $bg-color-6;
                border-color: $border-color-2;
                border-radius: 8px;
                cursor: text;


                &:focus {
                    box-shadow: none;
                    // trk-rgba 
                    // border-color: rgba($brand-color, $alpha: 0.50);
                    // border-color: $brand-color;
                    border-color: $brand-color;
                }
            }

            button {
                @extend %p-abs;
                content: "";
                padding: 11px;
                top: 14%;
                right: 10px;
                line-height: 10px;
                border: none;
                outline: none;
                border-radius: 99px;
                background-color: $brand-color;
                color: $wh-color;

                // @include transform(translateY(-50%));
                i,
                svg {
                    @include font-size(18px);
                }

            }
        }
    }

    &__recentpost {
        @extend %p-25;
        background-color: $wh-color;
        box-shadow: 0px -16px 48px rgba(6, 35, 63, 0.03), 0px 16px 48px rgba(6, 35, 63, 0.03);
        border-radius: 4px;

        &-body {
            @extend %pt-25;

            ul {
                li {
                    @extend %mb-30;

                    &:last-child {
                        margin-block-end: 0;
                    }
                }
            }
        }

        &-inner {
            @extend %d-flex,
            %align-i-center;
            gap: 15px;

            @include breakpoint(lg) {
                gap: 10px;
            }

            @include breakpoint(xl) {
                gap: 15px;
            }
        }

        &-thumb {
            @extend %of-hidden;
            max-width: 100px;
            border-radius: 8px;

            @include breakpoint (lg) {
                max-width: 45px;
            }

            @include breakpoint (xl) {
                max-width: 100px;
            }

            img {
                @extend %w-100;
                @include add-prefix(transition, $transition);
                object-fit: cover;

                &:hover {
                    @include add-prefix(transform, scale(1.09));
                }
            }
        }

        &-content {
            // @extend %ps-15;

            p {
                @extend %mb-0,
                %text-medium;
                @include font-size(16px);
                color: $title-color;
                font-family: $title-font;

                a {
                    @extend %text-clamp-3;
                    @include add-prefix(transition, $transition);
                    color: $title-color;
                }
            }

            span {
                @include font-size(14px);
                @include add-prefix(transition, $transition);
                color: $text-color;
            }

            &:hover {
                p {
                    a {
                        color: $brand-color;
                    }
                }

                span {
                    color: $brand-color;
                }
            }
        }

    }

    &__categorie {
        @extend %p-25;
        background-color: $wh-color;
        box-shadow: 0px -16px 48px rgba(6, 35, 63, 0.03), 0px 16px 48px rgba(6, 35, 63, 0.03);
        border-radius: 4px;

        &-body {
            @extend %pt-25;

            ul {
                li {
                    @extend %mb-15,
                    %d-flex,
                    %align-i-center,
                    %justify-between;

                    &:last-child {
                        margin-block-end: 0;
                    }

                    span,
                    a {
                        @extend %of-hidden,
                        %text-regular;
                        @include font-size(16px);
                        @include add-prefix(transition, $transition);
                        color: $title-color;
                    }

                    &.active,
                    &:hover {

                        span,
                        a {
                            @extend %text-medium;
                            color: $brand-color;
                        }
                    }

                    a {
                        i {
                            @extend %me-5;
                            color: $text-color-light;
                        }
                    }
                }
            }
        }
    }

    &__social {
        @extend %p-25;
        background-color: $wh-color;
        // trk-rgba 
        box-shadow: 0px -16px 48px rgba(6, 35, 63, 0.03), 0px 16px 48px rgba(6, 35, 63, 0.03);
        border-radius: 4px;
    }

    &__tags {
        @extend %p-25;
        background-color: $wh-color;
        box-shadow: 0px -16px 48px rgba(6, 35, 63, 0.03), 0px 16px 48px rgba(6, 35, 63, 0.03);
        border-radius: 4px;

        &-body {
            @extend %pt-25;

        }
    }



    &__comment {
        @extend %p-30,
        %mt-40;
        background-color: $tertiary-color3;
        border-radius: 16px;

        @include breakpoint(lg) {
            padding: 40px;
        }

        &-body {
            @extend %pt-25;
        }

        &-item {
            @extend %mb-40;

            &:last-child {
                margin-block-end: 0;
            }

            &--reply {
                @extend %ms-30;

                @include breakpoint(sm) {
                    margin-inline-start: 50px;
                }
            }
        }

    }

}




// tag for global
.tags {
    ul {
        @extend %d-flex;
        gap: 25px 10px;

        li {
            border: none;

            h6 {
                @include font-size(18px);
                font-family: $text-font;
            }

            a {
                @extend %text-regular,
                %text-capitalize;
                @include add-prefix(transition, $transition);
                @include font-size(16px);
                padding: 8px 16px;
                color: $text-color;
                border: 1px solid $border-color-5;

                outline: none;
                border-radius: 99px;

                &.active,
                &:hover {
                    background-color: $tertiary-color;
                    border-color: $border-color-5;
                    color: $title-color;
                }
            }
        }
    }

    &--style2 {
        ul {
            @extend %d-flex;
            gap: 25px 10px;

            li {
                border: none;

                h6 {
                    @include font-size(18px);
                    font-family: $text-font;
                }

                a {
                    @extend %text-regular,
                    %text-capitalize;
                    @include add-prefix(transition, $transition);
                    @include font-size(16px);
                    padding: 8px 16px;
                    color: $text-color;
                    border: 1px solid $border-color-5;

                    outline: none;
                    border-radius: 99px;

                    &.active,
                    &:hover {
                        background-color: $tag-bg-color;
                        border-color: $border-color-6;
                        color: $brand-color;
                    }
                }


            }
        }
    }
}