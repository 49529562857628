/*!
Template Name: Bitrader - Professional Multipurpose React js, Next.js Template for Your Crypto, Forex, and Stocks Trading Business
Template URI: 
Author: thetork
Author URI: https: //themeforest.net/user/thetork/portfolio
Description: Experience the power of Bitrader, the ultimate React js, Next.js Template designed to transform your trading business. With its sleek design and advanced features, Bitrader empowers you to showcase your expertise, engage clients, and dominate the markets. Elevate your online presence and unlock new trading possibilities with Bitrader.
Version: 1.0.0
Text Domain: Bitrader
Tags: Crypto, Forex, and Stocks Trading Business

*/



/*
-----------------Table of content start -----------------

1. Configuration and helpers
    -extends,
    -variables,
    -functions,
    -mixins

2. Vendors
    -normalize,
    -nice-select,
    -rfs

3. Base stuff
    -fonts,
    -typography,
    -animations,
    -common,
    -helpers


4. Layout-related sections
    -header,
    -banner,
    -contact,
    -account,
    -form,
    -footer


5. Components
    -button,
    -preloader,
    -lightdark-switch,
    -social-link,
    -foating-content,
    -partner,
    -feature,
    -service,
    -pricing,
    -team,
    -blog,
    -blog-details,
    -testimonial,
    -cta,
    -story,
    -sidebar,
    -faq,
    -error,
    -servicer-details,
    -nav,
    -roadmap,
    -comment,
    -about

6. Page-specific styles
    -home


7. Themes
    -theme

-----------------Table of content end -----------------

*/


/*=====================================================================*/

@charset "UTF-8";

// 1. Configuration and helpers
@import 'abstracts/extends',
'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

// 2. Vendors
@import 'vendors/normalize',
'vendors/nice-select',
'vendors/rfs';

// 3. Base stuff
@import 'base/fonts',
'base/typography',
'base/animations',
'base/common',
'base/helpers';


// 4. Layout-related sections
@import 'layout/header',
'layout/banner',
'layout/contact',
'layout/account',
'layout/form',
'layout/footer';


// 5. Components
@import 'components/button',
'components/preloader',
'components/lightdark-switch',
'components/social-link',
'components/foating-content',
'components/partner',
'components/feature',
'components/service',
'components/pricing',
'components/team',
'components/blog',
'components/blog-details',
'components/testimonial',
'components/cta',
'components/story',
'components/sidebar',
'components/faq',
'components/error',
'components/servicer-details',
'components/nav',
'components/counter',
'components/comment',
'components/accordion',
'components/roadmap',
'components/hero',
'components/pairs',
'components/how-it-works',
'components/about',
'components/coinpicker';

// 6. Page-specific styles
@import 'pages/home';


// 8. Themes
@import 'themes/theme';