.skeleton-wrapper-cs {
    width: 100%;
}

.skeleton-wrapper-cs span {
    width: 100%;
}

.rm-bf {
    background: unset !important;
}

.rm-bf::before {
    display: none;
}