.pricing {
    @extend %of-hidden,
    %p-rel;

    &__item {
        @extend %p-rel;
        z-index: 2;

        &-inner {
            @extend %py-30,
            %px-25;
            @include add-prefix(transition, $transition);
            background-color: $wh-color;
            // border: 1px solid $border-color;
            border: 2px solid transparent;
            box-shadow: 0px 16px 40px 0px rgba(26, 64, 137, 0.06);
            border-radius: 16px;

            &.active,
            &:hover {
                border: 2px solid $brand-color;
                box-shadow: 0px 32px 80px 0px rgba(26, 64, 137, 0.12);
            }

            @include breakpoint(sm) {
                padding-block: 32px;
                padding-inline: 25px;
            }

            &--style2 {
                @include add-prefix(transition, $transition);

                &.active,
                &:hover {
                    border: 2px solid $secondary-color;
                    box-shadow: 0px 32px 80px 0px rgba(26, 64, 137, 0.12);
                }
            }
        }

        &-top {
            @extend %p-rel;
            // border-bottom: 1px solid rgba($brand-color,.1);
            border-bottom: 1px solid #E6E6E6;

            h6 {
                text-transform: uppercase;
            }

            h3 {
                color: $secondary-color;

                span {
                    @extend %text-semibold;
                    @include font-size(18px);
                    font-family: $text-font;
                    color: #B0B3BF;

                }
            }
        }

        &-middle {
            @extend %py-40;
        }


        &--style2 {
            .pricing__item {
                &-inner {
                    border-radius: 4px;
                    border: 1px solid rgba(229, 237, 248, 0.47);
                    // background: var(--other-white, #FFF);
                    box-shadow: 0px 16px 40px 0px rgba(26, 64, 137, 0.06);


                    &.active {
                        background-color: $bg-color-10;
                    }
                }

                &-top {
                    h3 {
                        color: #8995f3;
                        // @include text-gradient(#2434AB, #B7BEF2);

                        span {
                            //  @include text-gradient($title-color, $title-color);
                            color: $title-color;
                        }
                    }
                }

            }
        }

    }

    &__list {
        @extend %mb-0,
        %ps-0;

        &-item {
            color: $title-color;

            span {
                @extend %me-5;
            }


            &:not(:last-child) {
                @extend %mb-15;
            }
        }
    }

    // shape
    &__shape {

        &-item {
            @extend %p-abs;

            &--1 {
                content: "";
                height: 95%;
                border: 2px solid var(--bg-shape-color-2);
                padding: 40px;
                border-radius: 100px 213px 100px 100px;

                opacity: 0.4;

                transform: rotate(25deg);
                // transform: rotate(-68.867deg);
                display: none;

                @include breakpoint(md) {
                    display: block;
                    width: 1050px;
                    top: 3%;
                    left: -6%;
                }

                @include breakpoint(lg) {
                    top: 8%;
                    left: 12%;
                }

                @include breakpoint(xl) {
                    top: 0%;
                    left: 11%;
                }

                @include breakpoint(xxl) {
                    left: 27%;
                }


                span {
                    position: absolute;
                    content: "";
                    height: 96%;
                    width: 1000px;
                    background: linear-gradient(4deg, var(--bg-shape-color-trans-2) 0%, var(--bg-shape-color-2) 100%);
                    border-radius: 100px 213px 100px 100px;


                    @include breakpoint(md) {
                        top: 2%;
                        left: 2%;
                    }

                    @include breakpoint(xl) {
                        width: 1000px;
                        top: 2%;
                        left: 2%;
                    }
                }


            }

            &--2 {
                width: 40px;
                top: 1%;
                right: 6%;
                opacity: 0.25;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 3%;
                    right: 7%;
                }

                @include breakpoint(md) {
                    width: auto;
                    top: 6%;
                    right: 6%;
                }

                @include breakpoint(lg) {
                    right: 5%;
                    top: 5%;
                }

                @include breakpoint(xl) {
                    top: 6%;
                    right: 6%;
                }

                @include breakpoint(xxl) {
                    top: 2%;
                    right: 6%;
                }
            }

            // home 2
            &--3 {
                content: "";
                height: 100%;
                border: 2px solid var(--bg-shape-color-2);
                padding: 40px;
                border-radius: 100px 213px 100px 100px;

                opacity: 0.4;

                transform: rotate(132deg);
                // transform: rotate(-68.867deg);
                display: none;

                @include breakpoint(md) {
                    display: block;
                    width: 1050px;
                    top: -45%;
                    right: -45%;
                }

                @include breakpoint(lg) {
                    width: 1200px;
                    top: -45%;
                    right: -45%;
                }

                @include breakpoint(xl) {
                    width: 1200px;
                    top: -45%;
                    right: -26%;
                }

                @include breakpoint(xxl) {
                    top: -40%;
                    right: -13%;
                }

                @include breakpoint(xxxl) {
                    top: -45%;
                    right: 0%;
                }


                span {
                    position: absolute;
                    content: "";
                    height: 96%;
                    width: 1000px;
                    background: linear-gradient(4deg, var(--bg-shape-color-trans-2) 0%, var(--bg-shape-color-2) 100%);
                    border-radius: 100px 213px 100px 100px;


                    @include breakpoint(md) {
                        top: 2%;
                        right: 2%;
                    }

                    @include breakpoint(xl) {
                        width: 1000px;
                        top: 2%;
                        right: 2%;
                    }

                    @include breakpoint(xxl) {
                        width: 1200px;
                        top: 2%;
                        right: 2%;
                    }
                }


            }

            &--4 {
                width: 40px;
                top: 1%;
                right: 6%;
                opacity: 0.25;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 3%;
                    right: 7%;
                }

                @include breakpoint(md) {
                    width: auto;
                    top: 6%;
                    right: 6%;
                }

                @include breakpoint(lg) {
                    right: 5%;
                    top: 10%;
                }

                @include breakpoint(xl) {
                    top: 10%;
                    right: 6%;
                }

                @include breakpoint(xxl) {
                    top: 11%;
                    right: 20%;
                }

                @include breakpoint(xxl) {
                    top: 11%;
                    right: 20%;
                }
            }

            // home 3
            &--5 {
                @extend %p-abs;
                width: 40px;
                top: 1%;
                left: 6%;
                opacity: 0.25;
                // @include animation(left-right 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    top: 3%;
                    left: 7%;
                }

                @include breakpoint(md) {
                    width: 70px;
                    top: 6%;
                    left: 4%;
                }

                @include breakpoint(lg) {
                    width: auto;
                    left: 5%;
                    top: 10%;
                }

                @include breakpoint(xl) {
                    top: 10%;
                    left: 9%;
                }

                @include breakpoint(xxl) {
                    top: 11%;
                    left: 17%;
                }
            }

            &--6 {
                @extend %p-abs;
                top: 0.5%;
                right: 2%;
                width: 50px;
                opacity: 0.25;
                // @include animation(floating 3s cubic-bezier(0.41, 0.15, 0.64, 0.86) infinite alternate);

                @include breakpoint(sm) {
                    width: 70px;
                    top: 2%;
                    right: 8%;
                }

                @include breakpoint(md) {
                    width: auto;
                    top: auto;
                    bottom: 18%;
                    right: 15%;
                }

                @include breakpoint(lg) {
                    bottom: 3%;
                    right: 4%;
                }

                @include breakpoint(xl) {
                    bottom: 4%;
                    right: 4%;
                }

                @include breakpoint(xxl) {
                    bottom: 4%;
                    right: 4%;
                }

                @include breakpoint(xxxl) {
                    bottom: 6%;
                    right: 13%;
                }
            }
        }


    }

}